import WindowContainer from "../../../components/layout/WindowContainer";
import {TeamSelect} from "../../../components/selectors/TeamSelect";
import React, {useState} from "react";
import {Team} from "../../../farsight-api";
import Tabs from "@mui/material/Tabs";
import {useReferentialContext} from "../../../Context";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import Tab from "@mui/material/Tab";
import SoloQPlayerSummary from "./14_GenericSoloQScouting/SoloQPlayerSummary";
import {Stack} from "@mui/material";


export default function TeamSoloQSummaries(props: {}){
    const roles = ['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'];
    const players = useReferentialContext().playersReferential;
    const [team, setTeam] = useState(null as null|Team);
    const team_players = players.filter(p => team && p.current_team == team.team_name && p.role).sort((a, b) => a.role && b.role ? roles.indexOf(a.role) - roles.indexOf(b.role) : 1);
    const [tab, setTab] = useState(0);


    return <WindowContainer spacing={1}>
        <Stack direction={'row'} spacing={1}>
            <TeamSelect value={team} updateValue={setTeam} updateContext={false}/>
            <Tabs value={tab}  onChange={(event: any, newValue: number) => setTab(newValue)}>
                {team_players.map((p, i) => <Tab label={p.player.split(' (')[0]} {...a11yProps(i)} />)}
            </Tabs>
        </Stack>
        {team_players.map((p, i) => <TabPanel value={tab} index={i} >
            <SoloQPlayerSummary player={p}/>
        </TabPanel>)}
    </WindowContainer>
}