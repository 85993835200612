/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface TeamHistoryRequest {
    from: number;
    target: string;
    team: string;
    until: number;
    additional_filters?: object;
    side?: string;
}

/**
 *
 */
export class DetailedApi extends runtime.BaseAPI {
    /**
     */
    async teamHistoryRaw(
        requestParameters: TeamHistoryRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.from === null ||
            requestParameters.from === undefined
        ) {
            throw new runtime.RequiredError(
                "from",
                "Required parameter requestParameters.from was null or undefined when calling teamHistory.",
            );
        }

        if (
            requestParameters.target === null ||
            requestParameters.target === undefined
        ) {
            throw new runtime.RequiredError(
                "target",
                "Required parameter requestParameters.target was null or undefined when calling teamHistory.",
            );
        }

        if (
            requestParameters.team === null ||
            requestParameters.team === undefined
        ) {
            throw new runtime.RequiredError(
                "team",
                "Required parameter requestParameters.team was null or undefined when calling teamHistory.",
            );
        }

        if (
            requestParameters.until === null ||
            requestParameters.until === undefined
        ) {
            throw new runtime.RequiredError(
                "until",
                "Required parameter requestParameters.until was null or undefined when calling teamHistory.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.from !== undefined) {
            queryParameters["from"] = requestParameters.from;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.target !== undefined) {
            queryParameters["target"] = requestParameters.target;
        }

        if (requestParameters.team !== undefined) {
            queryParameters["team"] = requestParameters.team;
        }

        if (requestParameters.until !== undefined) {
            queryParameters["until"] = requestParameters.until;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Detailed/TeamHistory`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async team_history(
        requestParameters: TeamHistoryRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.teamHistoryRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
