import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";

const WindowContainer = styled(Stack)(() => ({
    width: "100%",
    height: "100%",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 30,
})) as typeof Stack;
export default WindowContainer;
