/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveParticipantTeamfight,
    CompetitiveParticipantTeamfightAggregate,
} from "../models/index";

export interface CompetitiveParticipantTeamfightAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    having?: object;
    include_in_performance?: boolean;
    index_on?: string;
    league?: string;
    metrics?: Array<string>;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightGetRequest {
    id: string;
}

export interface CompetitiveParticipantTeamfightListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    ordering?: Array<string>;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

/**
 *
 */
export class CompetitiveParticipantTeamfightApi extends runtime.BaseAPI {
    /**
     */
    async competitiveParticipantTeamfightAggregateRaw(
        requestParameters: CompetitiveParticipantTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<CompetitiveParticipantTeamfightAggregate>>
    > {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling competitiveParticipantTeamfightAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling competitiveParticipantTeamfightAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.flash_up !== undefined) {
            queryParameters["flash_up"] = requestParameters.flash_up;
        }

        if (requestParameters.game_summary !== undefined) {
            queryParameters["game_summary"] = requestParameters.game_summary;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.has_ult !== undefined) {
            queryParameters["has_ult"] = requestParameters.has_ult;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.league !== undefined) {
            queryParameters["league"] = requestParameters.league;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.phase !== undefined) {
            queryParameters["phase"] = requestParameters.phase;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.split !== undefined) {
            queryParameters["split"] = requestParameters.split;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.teamfight !== undefined) {
            queryParameters["teamfight"] = requestParameters.teamfight;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveParticipantTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveParticipantTeamfightAggregate>> {
        const response = await this.competitiveParticipantTeamfightAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightFieldValuesRaw(
        requestParameters: CompetitiveParticipantTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling competitiveParticipantTeamfightFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling competitiveParticipantTeamfightFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.flash_up !== undefined) {
            queryParameters["flash_up"] = requestParameters.flash_up;
        }

        if (requestParameters.game_summary !== undefined) {
            queryParameters["game_summary"] = requestParameters.game_summary;
        }

        if (requestParameters.has_ult !== undefined) {
            queryParameters["has_ult"] = requestParameters.has_ult;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.league !== undefined) {
            queryParameters["league"] = requestParameters.league;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.phase !== undefined) {
            queryParameters["phase"] = requestParameters.phase;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.split !== undefined) {
            queryParameters["split"] = requestParameters.split;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.teamfight !== undefined) {
            queryParameters["teamfight"] = requestParameters.teamfight;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveParticipantTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response =
            await this.competitiveParticipantTeamfightFieldValuesRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightGetRaw(
        requestParameters: CompetitiveParticipantTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveParticipantTeamfight>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling competitiveParticipantTeamfightGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveParticipantTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveParticipantTeamfight> {
        const response = await this.competitiveParticipantTeamfightGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightListRaw(
        requestParameters: CompetitiveParticipantTeamfightListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveParticipantTeamfight>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.flash_up !== undefined) {
            queryParameters["flash_up"] = requestParameters.flash_up;
        }

        if (requestParameters.game_summary !== undefined) {
            queryParameters["game_summary"] = requestParameters.game_summary;
        }

        if (requestParameters.has_ult !== undefined) {
            queryParameters["has_ult"] = requestParameters.has_ult;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.league !== undefined) {
            queryParameters["league"] = requestParameters.league;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.phase !== undefined) {
            queryParameters["phase"] = requestParameters.phase;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.split !== undefined) {
            queryParameters["split"] = requestParameters.split;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.teamfight !== undefined) {
            queryParameters["teamfight"] = requestParameters.teamfight;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveParticipantTeamfightListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveParticipantTeamfight>> {
        const response = await this.competitiveParticipantTeamfightListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightVariableDistributionRaw(
        requestParameters: CompetitiveParticipantTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling competitiveParticipantTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling competitiveParticipantTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling competitiveParticipantTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling competitiveParticipantTeamfightVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.flash_up !== undefined) {
            queryParameters["flash_up"] = requestParameters.flash_up;
        }

        if (requestParameters.game_summary !== undefined) {
            queryParameters["game_summary"] = requestParameters.game_summary;
        }

        if (requestParameters.has_ult !== undefined) {
            queryParameters["has_ult"] = requestParameters.has_ult;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.league !== undefined) {
            queryParameters["league"] = requestParameters.league;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.phase !== undefined) {
            queryParameters["phase"] = requestParameters.phase;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.split !== undefined) {
            queryParameters["split"] = requestParameters.split;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.teamfight !== undefined) {
            queryParameters["teamfight"] = requestParameters.teamfight;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveParticipantTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.competitiveParticipantTeamfightVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
