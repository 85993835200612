import { Circle, ImageOverlay, MapContainer, Popup } from "react-leaflet";
import { styled } from "@mui/material";

const StyledPopup = styled(Popup)`
    .leaflet-popup-content-wrapper {
        background-color: #121212 !important; /* Add !important */
        opacity: 0; /* Start with zero opacity */
        animation: fadeIn 0.5s ease-in-out forwards; /* Apply the fade-in animation */
    }

    .leaflet-popup-tip-container {
        visibility: hidden;
        background-color: red !important; /* Add !important */
    }

    @keyframes fadeIn {
        to {
            opacity: 1; /* Fade in to full opacity */
        }
    }
`;

export default StyledPopup;
