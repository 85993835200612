/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface EventMapRequest {
    event_types: Array<string>;
    games: Array<string>;
    roles: Array<string>;
    target: string;
    around_obj?: string;
    ig_from?: number;
    ig_until?: number;
    team?: string;
    ward_types?: Array<string>;
}

export interface MapKillsRequest {
    ig_from: number;
    ig_until: number;
    target: Array<string>;
    team: string;
    additional_filters?: object;
}

/**
 *
 */
export class MapsApi extends runtime.BaseAPI {
    /**
     */
    async eventMapRaw(
        requestParameters: EventMapRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<{ [key: string]: any }>>> {
        if (
            requestParameters.event_types === null ||
            requestParameters.event_types === undefined
        ) {
            throw new runtime.RequiredError(
                "event_types",
                "Required parameter requestParameters.event_types was null or undefined when calling eventMap.",
            );
        }

        if (
            requestParameters.games === null ||
            requestParameters.games === undefined
        ) {
            throw new runtime.RequiredError(
                "games",
                "Required parameter requestParameters.games was null or undefined when calling eventMap.",
            );
        }

        if (
            requestParameters.roles === null ||
            requestParameters.roles === undefined
        ) {
            throw new runtime.RequiredError(
                "roles",
                "Required parameter requestParameters.roles was null or undefined when calling eventMap.",
            );
        }

        if (
            requestParameters.target === null ||
            requestParameters.target === undefined
        ) {
            throw new runtime.RequiredError(
                "target",
                "Required parameter requestParameters.target was null or undefined when calling eventMap.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.around_obj !== undefined) {
            queryParameters["around_obj"] = requestParameters.around_obj;
        }

        if (requestParameters.event_types) {
            queryParameters["event_types"] = requestParameters.event_types;
        }

        if (requestParameters.games) {
            queryParameters["games"] = requestParameters.games;
        }

        if (requestParameters.ig_from !== undefined) {
            queryParameters["ig_from"] = requestParameters.ig_from;
        }

        if (requestParameters.ig_until !== undefined) {
            queryParameters["ig_until"] = requestParameters.ig_until;
        }

        if (requestParameters.roles) {
            queryParameters["roles"] = requestParameters.roles;
        }

        if (requestParameters.target !== undefined) {
            queryParameters["target"] = requestParameters.target;
        }

        if (requestParameters.team !== undefined) {
            queryParameters["team"] = requestParameters.team;
        }

        if (requestParameters.ward_types) {
            queryParameters["ward_types"] = requestParameters.ward_types;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Maps/EventMap`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async events(
        requestParameters: EventMapRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<{ [key: string]: any }>> {
        const response = await this.eventMapRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async mapKillsRaw(
        requestParameters: MapKillsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.ig_from === null ||
            requestParameters.ig_from === undefined
        ) {
            throw new runtime.RequiredError(
                "ig_from",
                "Required parameter requestParameters.ig_from was null or undefined when calling mapKills.",
            );
        }

        if (
            requestParameters.ig_until === null ||
            requestParameters.ig_until === undefined
        ) {
            throw new runtime.RequiredError(
                "ig_until",
                "Required parameter requestParameters.ig_until was null or undefined when calling mapKills.",
            );
        }

        if (
            requestParameters.target === null ||
            requestParameters.target === undefined
        ) {
            throw new runtime.RequiredError(
                "target",
                "Required parameter requestParameters.target was null or undefined when calling mapKills.",
            );
        }

        if (
            requestParameters.team === null ||
            requestParameters.team === undefined
        ) {
            throw new runtime.RequiredError(
                "team",
                "Required parameter requestParameters.team was null or undefined when calling mapKills.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ig_from !== undefined) {
            queryParameters["ig_from"] = requestParameters.ig_from;
        }

        if (requestParameters.ig_until !== undefined) {
            queryParameters["ig_until"] = requestParameters.ig_until;
        }

        if (requestParameters.target) {
            queryParameters["target"] = requestParameters.target;
        }

        if (requestParameters.team !== undefined) {
            queryParameters["team"] = requestParameters.team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Maps/KillMap`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async kills(
        requestParameters: MapKillsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.mapKillsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async mapsFirstPathingRetrieveRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Maps/FirstPathing`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async first_pathing_image(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.mapsFirstPathingRetrieveRaw(initOverrides);
    }

    /**
     */
    async mapsLevel1RetrieveRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Maps/Level1`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async level_1_image(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.mapsLevel1RetrieveRaw(initOverrides);
    }
}
