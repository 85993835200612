/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    PatchedTeam,
    Team,
    TeamAggregate,
} from "../models/index";

export interface TeamAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    bayes_scrim_id?: string;
    bayes_team_id?: string;
    bayes_team_name?: string;
    coaching_staff?: string;
    current_league?: string;
    current_main_players?: string;
    domestic_league?: string;
    grid_id?: number;
    grid_name?: string;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    next_opponent?: string;
    players_list?: string;
    renamed_to?: string;
    team_name?: string;
}

export interface TeamFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    bayes_scrim_id?: string;
    bayes_team_id?: string;
    bayes_team_name?: string;
    coaching_staff?: string;
    current_league?: string;
    current_main_players?: string;
    domestic_league?: string;
    grid_id?: number;
    grid_name?: string;
    next_opponent?: string;
    players_list?: string;
    renamed_to?: string;
    team_name?: string;
}

export interface TeamGetRequest {
    id: string;
}

export interface TeamListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    bayes_scrim_id?: string;
    bayes_team_id?: string;
    bayes_team_name?: string;
    coaching_staff?: string;
    current_league?: string;
    current_main_players?: string;
    domestic_league?: string;
    grid_id?: number;
    grid_name?: string;
    next_opponent?: string;
    ordering?: Array<string>;
    players_list?: string;
    renamed_to?: string;
    team_name?: string;
}

export interface TeamPatchRequest {
    id: string;
    patched_team?: PatchedTeam;
}

export interface TeamVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    bayes_scrim_id?: string;
    bayes_team_id?: string;
    bayes_team_name?: string;
    coaching_staff?: string;
    current_league?: string;
    current_main_players?: string;
    domestic_league?: string;
    grid_id?: number;
    grid_name?: string;
    next_opponent?: string;
    players_list?: string;
    renamed_to?: string;
    team_name?: string;
}

/**
 *
 */
export class TeamApi extends runtime.BaseAPI {
    /**
     */
    async teamAggregateRaw(
        requestParameters: TeamAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<TeamAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling teamAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling teamAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.bayes_scrim_id !== undefined) {
            queryParameters["bayes_scrim_id"] =
                requestParameters.bayes_scrim_id;
        }

        if (requestParameters.bayes_team_id !== undefined) {
            queryParameters["bayes_team_id"] = requestParameters.bayes_team_id;
        }

        if (requestParameters.bayes_team_name !== undefined) {
            queryParameters["bayes_team_name"] =
                requestParameters.bayes_team_name;
        }

        if (requestParameters.coaching_staff !== undefined) {
            queryParameters["coaching_staff"] =
                requestParameters.coaching_staff;
        }

        if (requestParameters.current_league !== undefined) {
            queryParameters["current_league"] =
                requestParameters.current_league;
        }

        if (requestParameters.current_main_players !== undefined) {
            queryParameters["current_main_players"] =
                requestParameters.current_main_players;
        }

        if (requestParameters.domestic_league !== undefined) {
            queryParameters["domestic_league"] =
                requestParameters.domestic_league;
        }

        if (requestParameters.grid_id !== undefined) {
            queryParameters["grid_id"] = requestParameters.grid_id;
        }

        if (requestParameters.grid_name !== undefined) {
            queryParameters["grid_name"] = requestParameters.grid_name;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.next_opponent !== undefined) {
            queryParameters["next_opponent"] = requestParameters.next_opponent;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.players_list !== undefined) {
            queryParameters["players_list"] = requestParameters.players_list;
        }

        if (requestParameters.renamed_to !== undefined) {
            queryParameters["renamed_to"] = requestParameters.renamed_to;
        }

        if (requestParameters.team_name !== undefined) {
            queryParameters["team_name"] = requestParameters.team_name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: TeamAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<TeamAggregate>> {
        const response = await this.teamAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async teamFieldValuesRaw(
        requestParameters: TeamFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling teamFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling teamFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bayes_scrim_id !== undefined) {
            queryParameters["bayes_scrim_id"] =
                requestParameters.bayes_scrim_id;
        }

        if (requestParameters.bayes_team_id !== undefined) {
            queryParameters["bayes_team_id"] = requestParameters.bayes_team_id;
        }

        if (requestParameters.bayes_team_name !== undefined) {
            queryParameters["bayes_team_name"] =
                requestParameters.bayes_team_name;
        }

        if (requestParameters.coaching_staff !== undefined) {
            queryParameters["coaching_staff"] =
                requestParameters.coaching_staff;
        }

        if (requestParameters.current_league !== undefined) {
            queryParameters["current_league"] =
                requestParameters.current_league;
        }

        if (requestParameters.current_main_players !== undefined) {
            queryParameters["current_main_players"] =
                requestParameters.current_main_players;
        }

        if (requestParameters.domestic_league !== undefined) {
            queryParameters["domestic_league"] =
                requestParameters.domestic_league;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.grid_id !== undefined) {
            queryParameters["grid_id"] = requestParameters.grid_id;
        }

        if (requestParameters.grid_name !== undefined) {
            queryParameters["grid_name"] = requestParameters.grid_name;
        }

        if (requestParameters.next_opponent !== undefined) {
            queryParameters["next_opponent"] = requestParameters.next_opponent;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.players_list !== undefined) {
            queryParameters["players_list"] = requestParameters.players_list;
        }

        if (requestParameters.renamed_to !== undefined) {
            queryParameters["renamed_to"] = requestParameters.renamed_to;
        }

        if (requestParameters.team_name !== undefined) {
            queryParameters["team_name"] = requestParameters.team_name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: TeamFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.teamFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async teamGetRaw(
        requestParameters: TeamGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Team>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling teamGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: TeamGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Team> {
        const response = await this.teamGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async teamListRaw(
        requestParameters: TeamListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Team>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.bayes_scrim_id !== undefined) {
            queryParameters["bayes_scrim_id"] =
                requestParameters.bayes_scrim_id;
        }

        if (requestParameters.bayes_team_id !== undefined) {
            queryParameters["bayes_team_id"] = requestParameters.bayes_team_id;
        }

        if (requestParameters.bayes_team_name !== undefined) {
            queryParameters["bayes_team_name"] =
                requestParameters.bayes_team_name;
        }

        if (requestParameters.coaching_staff !== undefined) {
            queryParameters["coaching_staff"] =
                requestParameters.coaching_staff;
        }

        if (requestParameters.current_league !== undefined) {
            queryParameters["current_league"] =
                requestParameters.current_league;
        }

        if (requestParameters.current_main_players !== undefined) {
            queryParameters["current_main_players"] =
                requestParameters.current_main_players;
        }

        if (requestParameters.domestic_league !== undefined) {
            queryParameters["domestic_league"] =
                requestParameters.domestic_league;
        }

        if (requestParameters.grid_id !== undefined) {
            queryParameters["grid_id"] = requestParameters.grid_id;
        }

        if (requestParameters.grid_name !== undefined) {
            queryParameters["grid_name"] = requestParameters.grid_name;
        }

        if (requestParameters.next_opponent !== undefined) {
            queryParameters["next_opponent"] = requestParameters.next_opponent;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.players_list !== undefined) {
            queryParameters["players_list"] = requestParameters.players_list;
        }

        if (requestParameters.renamed_to !== undefined) {
            queryParameters["renamed_to"] = requestParameters.renamed_to;
        }

        if (requestParameters.team_name !== undefined) {
            queryParameters["team_name"] = requestParameters.team_name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: TeamListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Team>> {
        const response = await this.teamListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async teamPatchRaw(
        requestParameters: TeamPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Team>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling teamPatch.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.patched_team,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: TeamPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Team> {
        const response = await this.teamPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async teamVariableDistributionRaw(
        requestParameters: TeamVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling teamVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling teamVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling teamVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling teamVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bayes_scrim_id !== undefined) {
            queryParameters["bayes_scrim_id"] =
                requestParameters.bayes_scrim_id;
        }

        if (requestParameters.bayes_team_id !== undefined) {
            queryParameters["bayes_team_id"] = requestParameters.bayes_team_id;
        }

        if (requestParameters.bayes_team_name !== undefined) {
            queryParameters["bayes_team_name"] =
                requestParameters.bayes_team_name;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.coaching_staff !== undefined) {
            queryParameters["coaching_staff"] =
                requestParameters.coaching_staff;
        }

        if (requestParameters.current_league !== undefined) {
            queryParameters["current_league"] =
                requestParameters.current_league;
        }

        if (requestParameters.current_main_players !== undefined) {
            queryParameters["current_main_players"] =
                requestParameters.current_main_players;
        }

        if (requestParameters.domestic_league !== undefined) {
            queryParameters["domestic_league"] =
                requestParameters.domestic_league;
        }

        if (requestParameters.grid_id !== undefined) {
            queryParameters["grid_id"] = requestParameters.grid_id;
        }

        if (requestParameters.grid_name !== undefined) {
            queryParameters["grid_name"] = requestParameters.grid_name;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.next_opponent !== undefined) {
            queryParameters["next_opponent"] = requestParameters.next_opponent;
        }

        if (requestParameters.players_list !== undefined) {
            queryParameters["players_list"] = requestParameters.players_list;
        }

        if (requestParameters.renamed_to !== undefined) {
            queryParameters["renamed_to"] = requestParameters.renamed_to;
        }

        if (requestParameters.team_name !== undefined) {
            queryParameters["team_name"] = requestParameters.team_name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Team/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: TeamVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.teamVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
