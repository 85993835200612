import {targets, useReferentialContext} from "../../Context";
import Box from "@mui/material/Box";
import {GenericRadarChartNivo} from "../../components/Charts/GenericRadar";
import {useEffect, useState} from "react";


export default function PerformanceScoresRadar(props: {paramObject: any, target: targets}){
    const ctx = useReferentialContext();
    const api = ctx.farsightApi.getSummariesApi(props.target);
    const [data, setData] = useState([] as any[]);
    const [r, setR] = useState({} as any);

    const metrics = ctx.metricsReferential.filter(m => m.group === 'P-Scores' && m.code != 'relativeMinionsKilled_score')

    useEffect(() => {
        api.aggregate({
            ...props.paramObject,
            groupby: ['player'],
            metrics: ['performance,relative_performance,winrate,' + metrics.map(m => m.code).join(',')],
            aggregates: [metrics.map(m => `champion_role_patch__${m.code.split('_')[0]}_corr__avg`).join(',')],
            ordering: ['player']
        }).then((r: any) => {
            setR(r[0]);
            setData(
                metrics.map(m => { console.log(r);
                    let rp_value = ( r[0][m.code] - ((m.expected_avg_in_wins as number * r[0].winrate) + ( m.expected_avg_in_loss as number * (1-r[0].winrate))));
                    if(m.code === 'relativeDeaths_score'){
                        r[0][m.code] = r[0][m.code] * -1;
                        rp_value = ( r[0][m.code] - ((m.expected_avg_in_wins as number * r[0].winrate * -1) + ( m.expected_avg_in_loss as number * (1-r[0].winrate) * -1)));
                    }

                    return {
                    label: `${m.label}\n${r[0][m.code].toFixed(1)}(${rp_value > 0 ? '+' : ''}${rp_value.toFixed(1)})(Corr:${r[0][`champion_role_patch__${m.code.split('_')[0]}_corr__avg`].toFixed(2)})`,
                    // (${r[0][m.code] > 0 ? '+' : ''}${r[0][m.code].toFixed(1)})`,
                    score: ( r[0][m.code] + (m.expected_std ? m.expected_std : 100) * 2) / (m.expected_std ? m.expected_std : 100) ,
                    winrate_adjusted_score: (( rp_value + ((m.expected_std ? m.expected_std : 100) * 2) ) / 100)
                    }
                }
            ));
        })
    }, [props.paramObject.watermark]);

    const labels = [
        {id: `score`, label: `Score (Perf:${r.performance?.toFixed(1)})`},
        {id: `winrate_adjusted_score`, label: `Winrate Adjusted Score (RPerf:${r.relative_performance?.toFixed(1)})`},
    ]

    return <Box sx={{width:'100%', height:'100%'}}>
        {data.length > 0 ? <GenericRadarChartNivo data={data} labels={labels} valueKey={'score'}
                                mainLabel={'Score'} comparisonLabel={'Winrate Adjusted Score'} comparisonValueKey={'winrate_adjusted_score'}
                                valueType={'normalized_rating'}/> : <></>}
    </Box>
}

export function LanePerformanceScoresRadar(props: {paramObject: any, target: targets}){
    const ctx = useReferentialContext();
    const api = ctx.farsightApi.getSummariesApi(props.target);
    const [data, setData] = useState([] as any[]);
    const [r, setR] = useState({} as any);

    const metrics = ctx.metricsReferential.filter(m => m.group === 'Lane P-Scores')

    useEffect(() => {
        api.aggregate({
            ...props.paramObject,
            groupby: ['player'],
            metrics: ['performance_lane,relative_performance_lane,winrate,' + metrics.map(m => m.code).join(',')],
            aggregates: [metrics.map(m => `champion_role_patch__${m.code.split('_')[0]}_corr__avg`).join(',') + ',performance_lane__avg,relative_performance_lane__avg'],
            ordering: ['player']
        }).then((r: any) => {
            setR(r[0]);
            setData(
                metrics.map(m => { console.log(r);
                    let rp_value = ( r[0][m.code] - ((m.expected_avg_in_wins as number * r[0].winrate) + ( m.expected_avg_in_loss as number * (1-r[0].winrate))));
                    if(m.code === 'relativeDeaths_lane_score'){
                        r[0][m.code] = r[0][m.code] * -1;
                        rp_value = ( r[0][m.code] - ((m.expected_avg_in_wins as number * r[0].winrate * -1) + ( m.expected_avg_in_loss as number * (1-r[0].winrate) * -1)));
                    }

                    return {
                    label: `${m.label}\n${r[0][m.code].toFixed(1)}(${rp_value > 0 ? '+' : ''}${rp_value.toFixed(1)})(Corr:${r[0][`champion_role_patch__${m.code.split('_')[0]}_corr__avg`].toFixed(2)})`,
                    // (${r[0][m.code] > 0 ? '+' : ''}${r[0][m.code].toFixed(1)})`,
                    score: ( r[0][m.code] + (m.expected_std ? m.expected_std : 100) * 2) / (m.expected_std ? m.expected_std : 100) ,
                    winrate_adjusted_score: (( rp_value + ((m.expected_std ? m.expected_std : 100) * 2) ) / 100)
                    }
                }
            ));
        })
    }, [props.paramObject.watermark]);

    const labels = [
        {id: `score`, label: `Score (Perf : ${r.performance_lane__avg?.toFixed(1)})`},
        {id: `winrate_adjusted_score`, label: `Winrate Adjusted Score (RPerf : ${r.relative_performance_lane__avg?.toFixed(1)})`},
    ]

    return <Box sx={{width:'100%', height:'90%'}}>
        {data.length > 0 ? <GenericRadarChartNivo data={data} labels={labels} valueKey={'score'}
                                mainLabel={'Score'} comparisonLabel={'Winrate Adjusted Score'} comparisonValueKey={'winrate_adjusted_score'}
                                valueType={'normalized_rating'}/> : <></>}
    </Box>
}