/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    SoloqGameSummaries,
    SoloqGameSummariesAggregate,
} from "../models/index";

export interface SoloqGameSummariesAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    account?: string;
    account_name?: string;
    additional_filters?: object;
    aggregates?: Array<string>;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    gameidplayer?: string;
    having?: object;
    include_in_performance?: boolean;
    index_on?: string;
    is_main_role?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    metrics?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    account?: string;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    gameidplayer?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesGetRequest {
    id: string;
}

export interface SoloqGameSummariesListRequest {
    account?: string;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    annotations?: Array<string>;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    gameidplayer?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    ordering?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    account?: string;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    gameidplayer?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

/**
 *
 */
export class SoloqGameSummariesApi extends runtime.BaseAPI {
    /**
     */
    async soloqGameSummariesAggregateRaw(
        requestParameters: SoloqGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloqGameSummariesAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling soloqGameSummariesAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling soloqGameSummariesAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters["account"] = requestParameters.account;
        }

        if (requestParameters.account_name !== undefined) {
            queryParameters["accountName"] = requestParameters.account_name;
        }

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.competitive_player !== undefined) {
            queryParameters["competitive_player"] =
                requestParameters.competitive_player;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.elo_as_total_lps_during_dame !== undefined) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters.elo_as_total_lps_during_dame;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.gameidplayer !== undefined) {
            queryParameters["gameidplayer"] = requestParameters.gameidplayer;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.is_main_role !== undefined) {
            queryParameters["is_main_role"] = requestParameters.is_main_role;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.server !== undefined) {
            queryParameters["server"] = requestParameters.server;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: SoloqGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloqGameSummariesAggregate>> {
        const response = await this.soloqGameSummariesAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesFieldValuesRaw(
        requestParameters: SoloqGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling soloqGameSummariesFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling soloqGameSummariesFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters["account"] = requestParameters.account;
        }

        if (requestParameters.account_name !== undefined) {
            queryParameters["accountName"] = requestParameters.account_name;
        }

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.competitive_player !== undefined) {
            queryParameters["competitive_player"] =
                requestParameters.competitive_player;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.elo_as_total_lps_during_dame !== undefined) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters.elo_as_total_lps_during_dame;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.gameidplayer !== undefined) {
            queryParameters["gameidplayer"] = requestParameters.gameidplayer;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_main_role !== undefined) {
            queryParameters["is_main_role"] = requestParameters.is_main_role;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.server !== undefined) {
            queryParameters["server"] = requestParameters.server;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: SoloqGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.soloqGameSummariesFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesGetRaw(
        requestParameters: SoloqGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<SoloqGameSummaries>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling soloqGameSummariesGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: SoloqGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<SoloqGameSummaries> {
        const response = await this.soloqGameSummariesGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesListRaw(
        requestParameters: SoloqGameSummariesListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloqGameSummaries>>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters["account"] = requestParameters.account;
        }

        if (requestParameters.account_name !== undefined) {
            queryParameters["accountName"] = requestParameters.account_name;
        }

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.competitive_player !== undefined) {
            queryParameters["competitive_player"] =
                requestParameters.competitive_player;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.elo_as_total_lps_during_dame !== undefined) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters.elo_as_total_lps_during_dame;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.gameidplayer !== undefined) {
            queryParameters["gameidplayer"] = requestParameters.gameidplayer;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_main_role !== undefined) {
            queryParameters["is_main_role"] = requestParameters.is_main_role;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.server !== undefined) {
            queryParameters["server"] = requestParameters.server;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: SoloqGameSummariesListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloqGameSummaries>> {
        const response = await this.soloqGameSummariesListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesVariableDistributionRaw(
        requestParameters: SoloqGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling soloqGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling soloqGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling soloqGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling soloqGameSummariesVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters["account"] = requestParameters.account;
        }

        if (requestParameters.account_name !== undefined) {
            queryParameters["accountName"] = requestParameters.account_name;
        }

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.competitive_player !== undefined) {
            queryParameters["competitive_player"] =
                requestParameters.competitive_player;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.elo_as_total_lps_during_dame !== undefined) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters.elo_as_total_lps_during_dame;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.gameidplayer !== undefined) {
            queryParameters["gameidplayer"] = requestParameters.gameidplayer;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_main_role !== undefined) {
            queryParameters["is_main_role"] = requestParameters.is_main_role;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.server !== undefined) {
            queryParameters["server"] = requestParameters.server;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: SoloqGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.soloqGameSummariesVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
