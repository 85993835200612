import React, { useEffect, useState, useRef } from "react";
import { DataGrid, GridColDef, DataGridProps } from "@mui/x-data-grid";
import StyledDatagrid from "./styled_datagrid";

type AdaptablePageSizeGridProps = Omit<DataGridProps, "pageSize"> & {
    rows: any[];
    columns: GridColDef[];
    rowHeight: number;
    initialSortModel?: any; // Define your sort model type
};

const AdaptablePageSizeGrid: React.FC<AdaptablePageSizeGridProps> = ({ rows, columns, rowHeight, initialSortModel, ...otherProps }) => {
    const [pageSize, setPageSize] = useState(10); // Initial pageSize
    const parentContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calculatePageSize = () => {
            if (parentContainerRef.current) {
                const parentContainerHeight = parentContainerRef.current.clientHeight - 110;
                const rowsFitInAvailableHeight = Math.floor(parentContainerHeight / rowHeight);
                const calculatedPageSize = Math.max(10, rowsFitInAvailableHeight);
                setPageSize(calculatedPageSize);
            }
        };

        // Calculate the pageSize when the component mounts
        calculatePageSize();
        console.log(pageSize)

        // Calculate the pageSize when the parent container's size changes
        window.addEventListener("resize", calculatePageSize);

        return () => {
            window.removeEventListener("resize", calculatePageSize);
        };
    }, [rowHeight]);

    return (
        <div ref={parentContainerRef} style={{ width: "100%", height: "100%" }}>
            <StyledDatagrid rows={rows} columns={columns} rowHeight={rowHeight} sortModel={initialSortModel} {...otherProps} />
        </div>
    );
};

export default AdaptablePageSizeGrid;
