import Select from "@mui/material/Select";
import React, { useContext, useState } from "react";
import { Checkbox, Chip, FormControl, IconButton, InputLabel, ListSubheader, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./Styles/listbox.css";
import Box from "@mui/material/Box";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useReferentialContext } from "../../Context";
import { MultipleSelectorGrouped } from "../Abstract/Multiple";

export default function PatchSelect(props: { value: any; onChange: any }) {
    const [userTyping, setUserTyping] = useState(false);
    const [openedGroups, setOpenedGroups] = useState([] as any[]);
    const handleInputChange = (event: any, value: string) => {
        setUserTyping(value !== "");
    };
    const loadPatchGroups = (event: any, option: string) => {
        event.stopPropagation();
        if (!openedGroups.includes(option)) {
            setOpenedGroups([...openedGroups, option]);
        } else {
            setOpenedGroups(openedGroups.filter((value: string) => value !== option));
        }
    };

    const wrapper = (event: any, newValue: any) => {
        setUserTyping(false);
        // context.state.current_champions = newValue
        props.onChange(event, newValue);
    };

    return (
        <FormControl sx={{ width: "150px"}}>
            <Autocomplete
                value={props.value}
                onChange={wrapper}
                id="combo-box-demo"
                getOptionLabel={(option: any) => {
                    return option;
                }}
                renderGroup={(params) => {
                    return (
                        <Box key={params.group}>
                            <ListSubheader sx={{ cursor: "pointer" }} onClick={(event) => loadPatchGroups(event, params.group)}>
                                {`Season ${params.group}`}
                                <IconButton size="small" onClick={(event) => loadPatchGroups(event, params.group)} sx={{ marginLeft: "auto" }}>
                                    {openedGroups.includes(params.group) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </ListSubheader>
                            {openedGroups.includes(params.group) || userTyping ? params.children : <></>}
                        </Box>
                    );
                }}
                onInputChange={handleInputChange}
                groupBy={(option) => option.split(".")[0]}
                options={useReferentialContext().patchesReferential}
                renderInput={(params) => <TextField {...params} label="Patch" />}
                limitTags={3}
                ListboxProps={{ className: "scrollable" }}
                renderOption={(props, option, { selected }) => {
                    return <li {...props}>{option}</li>;
                }}
            />
        </FormControl>
    );
}

export function MultiplePatchSelect(props: { values: string[]; updateValue: (newValue: string[]) => void; updateContext?: boolean | undefined }) {
    const { patchesReferential, selectedPatches, setSelectedPatches } = useReferentialContext();

    const [localPatches, setLocalPatches] = useState(props.values);

    const updateSelection = (newSelection: string[]) => {
        console.log(props.updateContext);
        if (props.updateContext) {
            setSelectedPatches(newSelection);
        } else {
            setLocalPatches(newSelection);
        }
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "250px" }}>
            <MultipleSelectorGrouped
                getGroup={(option: string) => `Season ${option.split(".")[0]}`}
                getOptionLabel={(option: string) => option}
                getOptionShort={(option: string) => option}
                limitTags={1}
                options={patchesReferential}
                selected={props.updateContext ? selectedPatches : localPatches}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                label={"Patches"}
            />
        </Box>
    );
}
