import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    Grid,
    Grow,
    Link,
    Paper,
    Slider,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FarsightApi from "../../../ApiWrapper";
import Box from "@mui/material/Box";
import global_context, { loss_color, scrollable, win_color } from "../../../static_vars";
import dayjs, { Dayjs } from "dayjs";
import { DraftBlockCompetitive, DraftBlockScrim } from "../../../components/visu_blocks/Drafts";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TeamSelect } from "../../../components/selectors/TeamSelect";
import DatePicker, { TimePicker } from "../../../components/selectors/Shared/DateSelect";
import {targets, useReferentialContext} from "../../../Context";
import {GamesData, ScrimsData, ScrimsDataApi, ScrimSession} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";

export default function ScrimCalendar() {
    const [data, setData] = useState([] as ScrimSession[]);
    const [selected, setSelected] = useState(0);
    const api = useReferentialContext().farsightApi.scrimSession;


    useEffect(() => {
        api.list({ordering:['-date']}).then(result => setData(result))
        }, []
    )

    return (
        <WindowContainer direction={'row'} spacing={2}>
            <Planning data={data} selected={selected} setSelected={setSelected} />
            <SessionViewer session={data[selected]} />
        </WindowContainer>
    );
}

function Planning(props: {data: ScrimSession[], selected: number, setSelected: any}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const user_team = useReferentialContext().user.team;

    const { data, selected, setSelected } = props;
    return (
        <Stack
            direction={"column"}
            sx={{
                height: "99%",
                minWidth: "550px",
                maxWidth: '25%',
                outline: "2px solid white",
                borderRadius: 2,
                alignItems: "center",
            }}
        >
            <Paper
                sx={{
                    width: "98%",
                    height: "50px",
                    justifyContent: "space-evenly",
                    display: "flex",
                    mt: "3px",
                    alignItems: "center",
                }}
            >
                Calendar
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${user_team}.png`} />
                <Button variant={"outlined"} onClick={() => setDialogOpen(!dialogOpen)}>
                    New +
                </Button>
            </Paper>
            <PlanSession open={dialogOpen} close={() => setDialogOpen(false)} />
            <Stack
                direction={"column"}
                sx={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                    mt: "2px",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 1,
                    ...scrollable,
                    padding: "5px",
                }}
                spacing={1}
            >
                {/*<legend>Calendar</legend>*/}
                {data.map((session: any, index: number) => (
                    <CalendarRow session={session} selected={index === selected} onClick={() => setSelected(index)} index={index} />
                ))}
            </Stack>
        </Stack>
    );
}

function CalendarRow(props: { session: any; selected: boolean; onClick: any; index: number }) {
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: Math.floor(200 * (props.index + 1)) }}>
            <Stack
                direction={"row"}
                sx={{
                    height: "250px",
                    width: "100%",
                    backgroundColor: props.session.games_won >= props.session.games_lost ? win_color : loss_color,
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    outline: props.selected ? "2px solid white" : `2px solid ${props.session.games_won >= props.session.games_lost ? win_color : loss_color}`,
                    borderRadius: 2,
                    "&:hover": {
                        cursor: "pointer", // Change the cursor to a pointer on hover
                    },
                }}
                spacing={1}
                onClick={props.onClick}
            >
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${props.session.vs}.png`} />
                {/*<Grow in={true} style={{ transformOrigin: '0 -50 0' }} {...({timeout: Math.floor(200 * (props.index + 1))})}>*/}
                {/*    <Typography>{`${props.session.vs}`}</Typography>*/}
                {/*</Grow>*/}
                <Typography>
                    <pre
                        style={{
                            fontFamily: "inherit",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {dayjs(props.session.date).format("MMM DD\nHH:mm")}
                    </pre>
                </Typography>
                <Typography>{`${props.session.games_won} - ${props.session.games_lost}`}</Typography>
            </Stack>
        </Grow>
    );
}

function SessionViewer(props: { session: ScrimSession }) {
    const [data, setData] = useState(undefined as any);
    const api = useReferentialContext().farsightApi.scrimsData

    useEffect(() => {
        setData(undefined);
        if (props.session)
            api.list(
                {session_1: props.session.id, ordering:['-date']}
            ).then(r => setData(r.filter((game) => game.winner !== '' )));
    }, [props.session]);

    return (
        <Stack direction={"column"} spacing={2} sx={{ width: "100%", height:'99%' }}>
            <GamesOverview games={data} session={props.session} />
            <DraftsGrid games={data} />
        </Stack>
    );
}

function GamesOverview(props: { games: any[]; session: any }) {
    const data = props.games;
    return (
        <Stack
            direction={"column"}
            sx={{
                height: `${props.session ? 5 * 53 + 60 : 500}px`,
                width: "80%",
                maxWidth: '1000px',
                outline: "2px solid white",
                borderRadius: 2,
                alignItems: "center",
                padding: "5px",
                ...scrollable,
                overflow: "auto",
            }}
            spacing={"3px"}
        >
            <Fade
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...{
                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                }}
            >
                <Paper
                    sx={{
                        width: "98%",
                        minHeight: "50px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 2,
                    }}
                >
                    Games
                </Paper>
            </Fade>
            {data ? data.map((game: any, index: number) => <GameRow game={game} index={data.length - index - 1} />) : <Typography></Typography>}
        </Stack>
    );
}

function GameRow(props: { game: GamesData; index: number }) {
    const user = useReferentialContext().user
    const api = useReferentialContext().farsightApi;
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: Math.floor(200 * (props.index + 1)) }}>
            <Stack
                direction={"row"}
                sx={{
                    width: "98%",
                    minHeight: "50px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    display: "flex",
                    backgroundColor: props.game.winner === user.team ? win_color : loss_color,
                    borderRadius: 2,
                }}
                spacing={1}
            >
                <Tooltip title={"Click to download replay"} followCursor={true}>
                    <Button
                        variant={"outlined"}
                        onClick={() => api.download_file('Downloads/GameReplay',
                            {game_id:props.game.game_id, target: targets.scrims},
                            `${dayjs(props.game.date).format('MMM DD')} - ${props.game.team1} vs ${props.game.team2} - game ${props.index +1}.rofl`)}>
                        {'Replay'}
                    </Button>
                </Tooltip>
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,
                    }}
                >
                    {props.game.patch}
                </Typography>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${props.game.team1}.png`} />
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,

                    }}
                >
                    {props.game.drafting_sim_team1_pred ? `${(props.game.drafting_sim_team1_pred*100).toFixed(2)}%` : ''}
                </Typography>
                <Stack direction={"row"}>
                    {props.game.team1champions.split(",").map((champion: string) => (
                        <Fade
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{
                                timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                            }}
                        >
                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${champion}.png`} />
                        </Fade>
                    ))}
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Typography
                            sx={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                                mr: 1,
                            }}
                        >
                            {props.game.duration}
                        </Typography>
                    </Fade>
                    {props.game.team2champions.split(",").map((champion: string) => (
                        <Fade
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{
                                timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                            }}
                        >
                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${champion}.png`} />
                        </Fade>
                    ))}
                </Stack>
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,
                    }}
                >
                    {props.game.drafting_sim_team2_pred ? `${(props.game.drafting_sim_team2_pred*100).toFixed(2)}%` : ''}
                </Typography>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${props.game.team2}.png`} />
                <Tooltip title={props.game.winner === props.game.drafting_sim_predicted_winner ? 'Draft prediction was correct' : 'Draft prediction was incorrect'}>
                    <Checkbox checked={props.game.winner === props.game.drafting_sim_predicted_winner}/>
                </Tooltip>
                {/*<Typography>{`${props.game.team1_id}`}</Typography>*/}
            </Stack>
        </Grow>
    );
}

function DraftsGrid(props: { games: ScrimsData[] }) {
    const user = useReferentialContext().user
    return (
        <Stack
            direction={"row"}
            sx={{
                maxWidth: `${window.innerWidth - 700}px`,
                minHeight: '550px',
                flex: 1,
                alignItems: "center",
                overflow: "auto",
                outline: "2px solid white",
                ...scrollable,
                padding: "15px",
                borderRadius: 2,
            }}
            spacing={1}
        >
            {props.games ? props.games.filter(g => g.draft_mode === 'Blind' || g.draft_mode === 'Draft').map((row: any, index: number) => <DraftBlockScrim draft={row} team_target={user.team} index={index} />) : <></>}
        </Stack>
    );
}

function PlanSession(props: { open: boolean; close: any }) {
    const [dateTime, setDateTime] = useState(dayjs() as Dayjs);
    const [time, setTime] = useState(dayjs() as Dayjs);
    const [team, chooseTeam] = useState(undefined as string | undefined);
    const [gamesPlanned, setGamesPlanned] = useState(5);
    function handleClose() {
        props.close();
        return true;
    }

    // @ts-ignore
    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Add Session</DialogTitle>
            <DialogContent sx={{ height: "400px" }}>
                <Stack sx={{ mt: "20px" }} spacing={2}>
                    {/*<TeamSelect getValue={team} onChange={(event:any, newValue:string) => chooseTeam(newValue)}/>*/}
                    <DatePicker value={dateTime} onChange={setDateTime} label={"Date"} />
                    <TimePicker value={time} onChange={setTime} label={"Time"} />
                    <Typography>{`${gamesPlanned} games planned`}</Typography>
                    <Slider value={gamesPlanned} step={1} marks min={1} max={10} onChange={(event: any, newValue: any) => setGamesPlanned(newValue)} aria-label="Year" valueLabelDisplay="auto" />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close}>Cancel</Button>
                <Button onClick={handleClose}>Add Session</Button>
            </DialogActions>
        </Dialog>
    );
}
