import {Item, targets, useReferentialContext} from "../../../Context";
import React, {useState} from "react";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import ChampionsStats from "../../../components/visu_blocks/ChampionsStats";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import ItemSelect from "../../../components/selectors/Shared/ItemSelect";
import {GameMetrics} from "../../../farsight-api";


export default function FirstItemsEfficiency() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches);
    const [role, setRole] = useState("MID_LANE");
    const [servers, setServers] = useState(['EUW1','KR'])
    const [item, setItem] = useState(undefined as Item|undefined)
    const [paramObject, setParamObject] = useState({ordering: '-performance'} as any);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().selectedMetrics);

    const request_parameters = {
        ...paramObject,
        groupby: ['item1'],
        ordering: "-games",
        format: "json",
    }
    const api = context.farsightApi.soloqGameSummaries;
    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.soloq}
                role={role}
                setRole={setRole}
                patches={patches}
                setPatches={setPatches}
                servers={servers}
                setServers={setServers}
                metrics={metrics}
                setMetrics={setMetrics}
            />
            <Stack direction={'row'} sx={{width:'100%', height:'100%'}} spacing={1}>
                <Box sx={{width:'50%', height:'100%'}}>
                    <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate(request_parameters)}
                        rowStyle={rowStyle.none}
                        disp_columns={['item1']}
                        sortBy={"games"}
                        paramObject={request_parameters}
                        metrics={metrics}
                    />
                </Box>
                <Stack direction={'column'} sx={{width:'50%', height:'100%'}} spacing={1}>
                    <ItemSelect value={item} onChange={setItem} minCost={2000}/>
                    {item ? <ChampionsStats paramObject={{
                        ...paramObject,
                        item1: item.name,
                        watermark: item.name
                    }} target={targets.soloq}/> : <></>}
                </Stack>
            </Stack>
        </WindowContainer>
    );
}