import {targets, useReferentialContext} from "../../../../Context";
import React, {useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {Team} from "../../../../farsight-api";
import {Stack} from "@mui/material";
import JunglePathingMap from "../../../../components/maps/JunglePathingMap";
import Level1Map from "../../../../components/maps/Level1Map";
import {SideSelect} from "../../../../components/selectors/YesNoSelector";


export default function Level1(props: {target: targets}) {
    const [team, setTeam] = useState(useReferentialContext().selectedTeam);
    const [side, setSide] = useState(null as string | null);
    const [from, setFrom] = useState(dayjs().subtract(90, "day"));

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} team={team} setTeam={setTeam} side={side} setSide={setSide} date__gte={from} setDateGte={setFrom} />
            {team ? <Level1Maps from={from} team={team} target={props.target} /> : <></>}
        </WindowContainer>
    );
}

export function Level1Maps(props: { team: Team; from: Dayjs, target: targets }) {
    const [games, setGames] = useState([] as any[]);
    const api = useReferentialContext().farsightApi.detailed;
    const [side, setSide] = useState(null as string | null);

    useEffect(() => {
        setGames([]);
        api.team_history({
            team: props.team.team_name,
            target: props.target,
            from: props.from.unix(),
            until: dayjs().unix(),
        }).then((data) => {
            setGames(data as any[]);
        });
    }, [props.team, props.from]);

    return (
        <Stack>
            <SideSelect value={side} onChange={setSide}/>
        <Stack
            direction="row"
            sx={{
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
            }}
            alignItems={"flex-start"}
            justifyContent={"space-evenly"}
            alignSelf={"space-evenly"}
        >
            {games
                .filter((game: any, index: number) => game.bayes_parser_version = 1 && index < 30 && (side ? game.side === side : true))
                .map((game: any, index: number) => (
                    <Level1Map team={props.team.team_name} match={game} index={index} target={props.target} />
                ))}
        </Stack>
        </Stack>
    );
}