import { css, keyframes, styled } from "@mui/material";
import { Circle } from "react-leaflet";

export function getRandomDuration(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

function generateFadeInAnimation(duration: number) {
    return keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;
}

// Create a styled Circle component with a random fade-in duration
interface AnimatedCircleProps {
    duration: number;
}

const AnimatedCircle = styled(Circle)<AnimatedCircleProps>`
    ${(props) => css`
        opacity: 0;
        animation: ${generateFadeInAnimation(props.duration)} ${props.duration}s ease-in-out forwards;
    `}
`;

export default AnimatedCircle;
