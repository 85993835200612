import React, { useContext, useState } from "react";
import global_context from "../../../static_vars";
import { Checkbox, FormControl, IconButton, ListSubheader, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Item, useReferentialContext} from "../../../Context";

export default function ItemSelect(props: { value: Item|undefined; onChange: any; label?: string; minCost: number }) {
    const context = useReferentialContext();
    const [value, setValue] = useState(props.value);
    const [userTyping, setUserTyping] = useState(false);
    const [selectedGroups, setSelected] = useState([] as any[]);
    console.log(context.itemsReferential)
    let items = context.itemsReferential.filter(i => i.gold.total >= props.minCost);


    const wrapper = (event: any, newValue: any) => {
        setUserTyping(false);
        setValue(newValue);
        props.onChange(newValue ? newValue : undefined);
    };
    const loadChampionGroups = (event: any, option: string) => {
        event.stopPropagation();
        if (!selectedGroups.includes(option)) {
            setSelected([...selectedGroups, option]);
        } else {
            setSelected(selectedGroups.filter((value: string) => value !== option));
        }
    };

    const handleInputChange = (event: any, value: string) => {
        setUserTyping(value !== "");
    };

    return (
        <FormControl sx={{ width: "300px", zIndex: 0 }}>
            <Autocomplete
                disableCloseOnSelect
                options={items.sort((a: any, b: any) => a.tags[0].localeCompare(b.tags[0]))}
                getOptionLabel={(option: any) => {
                    return option.name;
                }}
                value={value}
                groupBy={(option) => option.tags[0]}
                renderGroup={(params) => {
                    return (
                        <Box key={params.group}>
                            <ListSubheader sx={{ cursor: "pointer" }} onClick={(event) => loadChampionGroups(event, params.group)}>
                                {params.group}
                                <IconButton size="small" onClick={(event) => loadChampionGroups(event, params.group)} sx={{ marginLeft: "auto" }}>
                                    {selectedGroups.includes(params.group) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </ListSubheader>
                            {selectedGroups.includes(params.group) || userTyping ? params.children : <></>}
                        </Box>
                    );
                }}
                onChange={wrapper}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label ? props.label : "Item"}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: value ? (
                                <>
                                    <img src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${value.name}.png`} alt={value.name} width="38" height="38" style={{ marginRight: 8 }} />
                                    {params.InputProps.startAdornment}
                                </>
                            ) : (
                                params.InputProps.startAdornment
                            ),
                        }}
                    />
                )}
                ListboxProps={{ className: "scrollable" }}
                renderOption={(props, option, { selected }) => {
                    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
                    const checkedIcon = <CheckBoxIcon fontSize="small" />;
                    return (
                        <Tooltip
                            title={
                                <Box
                                    dangerouslySetInnerHTML={{
                                        __html: option.description,
                                    }}
                                />
                            }
                            placement={"left"}
                        >
                            <li {...props} style={{ height: "50px" }}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${option.name}.png`} width={"40px"} style={{ marginRight: 8 }} />
                                {option.name}
                            </li>
                        </Tooltip>
                    );
                }}
            />
        </FormControl>
    );
}
