import React, {useState} from "react";
import {Grow, Stack} from "@mui/material";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import ItemSelect from "../selectors/Shared/ItemSelect";
import {Depth} from "../selectors/TimestampRangeSelect";
import {Item, targets, useReferentialContext} from "../../Context";
import {GameMetrics} from "../../farsight-api";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";
import TableDisplayGrid, {rowStyle} from "../datagrids/shared/TableDisplayGrid";
import DamageComposition from "../Charts/DamageComposition";

export default function ChampionItems(props: {paramObject: any, target: targets}) {
    const [item1, setItem1] = useState(undefined as Item|undefined);
    const [item2, setItem2] = useState(undefined as Item|undefined);
    const [item3, setItem3] = useState(undefined as Item|undefined);
    const [item4, setItem4] = useState(undefined as Item|undefined);
    const [item5, setItem5] = useState(undefined as Item|undefined);
    const [item6, setItem6] = useState(undefined as Item|undefined);
    const [boots, setBoots] = useState(undefined as Item|undefined);
    const [depth, setDepth] = useState([1, 2]);

    const handleDepthChange = (event: any, newValue: number[]) => {
        setDepth(newValue);
    };

    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().selectedMetrics);

    const initialState = {
        sorting: {
            sortModel: [{ field: "performance", sort: "desc" }],
        },
    } as GridInitialStateCommunity;

    const items = ["item1", "item2", "item3", "item4", "item5", "item6"].slice(depth[0] - 1, depth[1]);
    // items.push('boots')

    const request_parameters = {
        ...props.paramObject,
        groupby: items.toString(),
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "performance",
        format: "json",
        having: {games__gte: props.target === targets.soloq ? 10 : 1}
    };
    request_parameters.watermark = JSON.stringify(request_parameters);
    if (item1) request_parameters.item1 = item1.name;
    if (item2) request_parameters.item2 = item2.name;
    if (item3) request_parameters.item3 = item3.name;
    if (item4) request_parameters.item4 = item4.name;
    if (item5) request_parameters.item5 = item5.name;
    if (item6) request_parameters.item6 = item6.name;

    return (
        <Stack sx={{ width: "100%", height: "100%" }} spacing={3}>
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target} updateContext={false} />
                    <Depth value={depth} onChange={handleDepthChange} />
                </Stack>
            </Grow>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <ItemSelect value={item1} onChange={setItem1} label={"1st Item"} minCost={2400} />
                    <ItemSelect value={item2} onChange={setItem2} label={"2nd Item"} minCost={2400} />
                    <ItemSelect value={item3} onChange={setItem3} label={"3rd Item"} minCost={2400} />
                    <ItemSelect value={item4} onChange={setItem4} label={"4th Item"} minCost={2400} />
                    <ItemSelect value={item5} onChange={setItem5} label={"5th Item"} minCost={2400} />
                    <ItemSelect value={item6} onChange={setItem6} label={"6th Item"} minCost={2400} />
                </Stack>
            </Grow>
            <Stack direction={"row"} sx={{ height: "100%", alignItems: "center" }} spacing={2}>
                <TableDisplayGrid
                    display_all={false}
                    request={() => api.aggregate(request_parameters)}
                    rowStyle={rowStyle.none}
                    disp_columns={items}
                    sortBy={"performance"}
                    paramObject={request_parameters} metrics={metrics}
                />
                <DamageComposition  paramObject={props.paramObject} target={props.target}></DamageComposition>
            </Stack>
        </Stack>
    );
}
