import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import {targets} from "../../../../Context";
import FilterSet from "../../../../components/selectors/Filterset";
import {useReferentialContext} from "../../../../Context";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import {SoloqGameSummariesAggregateRequest} from "../../../../farsight-api";
import WindowContainer from "../../../../components/layout/WindowContainer";

export default function SoloQPlayerRanking() {
    const [from, setFrom] = useState(dayjs('2024-01-01'));
    const [until, setUntil] = useState(dayjs());
    const [servers, setServers] = useState([]);
    const [role, setRole] = useState('BOT_LANE');
    const [lpThreshold, setLpThreshold] = useState(4100);
    const [paramObject, setParamObject] = useState({} as any);
    const [metrics, setMetrics] = useState(useReferentialContext().metricsReferential.filter(m => ['performance','relative_performance','games','winrate'].includes(m.code)));

    const api = useReferentialContext().farsightApi.soloqGameSummaries;
    const headers = ['account__accountName', 'account__player','account__elo','account__player__birthdate','account__player__nationality', 'account__player__role','server','account__player__current_team'];
    // , account__most_recent_game__gte:dayjs('2023-08-01').toISOString()
    const request_params = {
        ...paramObject,
        additional_filters: {...paramObject.additional_filters},
        groupby: [headers.toString()],
        ordering: ['-performance'],
        having: {games__gte:100, date__max__gte:until.subtract(4,"month").toISOString()},
        include_in_performance:true,
        aggregates: ['date__max']
    } as SoloqGameSummariesAggregateRequest;

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq}
                        date__gte={from} setDateGte={setFrom}
                       date__lt={until} setDateLt={setUntil} role={role} setRole={setRole} servers={servers} setServers={setServers} metrics={metrics} setMetrics={setMetrics}
            lpThreshold={lpThreshold} setLPThreshold={setLpThreshold}/>
            {paramObject.role && paramObject.additional_filters.server__in ? <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate(request_params)
                }
                rowStyle={rowStyle.none}
                disp_columns={['account__accountName', 'account__player','account__elo', 'account__player__role','account__player__birthdate','account__player__current_team','account__player__nationality']}
                sortBy={"performance"}
                paramObject={paramObject}
                metrics={metrics}
            /> : <></>}

        </WindowContainer>
    );
}
