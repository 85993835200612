import {targets, useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import {CompetitiveTeamfight, Team} from "../../../farsight-api";
import {Checkbox, FormControl, FormGroup, Grow, Paper, Stack, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import {SideSelect} from "../YesNoSelector";
import FormControlLabel from "@mui/material/FormControlLabel";
import {loss_color, win_color} from "../../../static_vars";
import TeamIcon from "../../Icons/TeamIcon";
import dayjs from "dayjs";


export default function TeamfightSelect(props: {target: targets, paramObject: any, perspective?: Team|null}){
    const api = useReferentialContext().farsightApi.competitiveTeamfights;

    const [teamfights, setTeamfights] = useState([] as CompetitiveTeamfight[]);

    useEffect(() => {
        api.list(props.paramObject).then(r => {
            setTeamfights(r);
            console.log(r);
        });
    }, [props.paramObject]);

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
            <Stack direction={'column'}
                sx={{
                    display: "flex",
                    height: "100%",
                    overflow: "auto",
                    width: "550px",
                    border: "2px solid white",
                    padding: 1,
                    borderRadius: 3,
                    "&::-webkit-scrollbar": {
                        width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#e1e1e1e",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                        zIndex: 0,
                    },
                }}
            >
                <FormControl
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <FormGroup sx={{width:'70%', alignItems: "center",
                        display: "flex"}}>
                        <Stack direction={"row"} sx={{
                            alignItems: "center",
                            display: "flex",
                            backgroundColor: '#181818',
                            color:'white',
                            width:'100%',
                            height:'40px'
                        }}>
                            <Box sx={{width:'43px'}}/>
                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'Start'}</Typography>
                            <Box sx={{width:'40px',ml:1, mr:1}}/>
                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{`Kills`}</Typography>
                            <Box sx={{width:'40px', ml:1, mr:1}}/>
                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'Gold'}</Typography>
                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'XP'}</Typography>
                        </Stack>
                        {teamfights.map((tf: CompetitiveTeamfight, index: number) => (
                                <FormControlLabel
                                    sx={{
                                        width: "100%",
                                        m:0,
                                        backgroundColor: tf.winner_team === props.perspective?.team_name ? win_color : loss_color
                                    }}
                                    control={
                                        <Checkbox
                                            checked={true}
                                            // onChange={(event) => handleChange(index)}
                                            name={index.toString()}
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: "white",
                                                    fill: "white",
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack
                                            direction={"row"}
                                            sx={{
                                                alignItems: "center",
                                                width: "100%",
                                                display: "flex",
                                                backgroundColor: tf.winner_team === props.perspective?.team_name ? win_color : loss_color,
                                                color:'white',
                                                height:'100%',
                                                m:0,
                                                padding:0,
                                            }}
                                        >
                                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex', height:'100%'}}>{dayjs.duration(tf.started_at, "seconds").format("m:ss")}</Typography>
                                            <TeamIcon team_name={tf.team1} sx={{ml:1, mr:1}}/>
                                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{`${tf.team1_kills} - ${tf.team2_kills}`}</Typography>
                                            <TeamIcon team_name={tf.team2} sx={{ml:1, mr:1}}/>
                                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{props.perspective?.team_name === tf.team1 ? tf.gold_delta_created : -tf.gold_delta_created}</Typography>
                                            <Typography sx={{width:'50px', alignItems:'center', justifyContent:'center', display:'flex'}}>{props.perspective?.team_name === tf.team1 ? tf.xp_delta_created : -tf.xp_delta_created}</Typography>
                                        </Stack>
                                    }
                                />
                        ))}
                    </FormGroup>
                </FormControl>
            </Stack>
        </Grow>
    );
}