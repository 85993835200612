import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import duration, {Duration} from "dayjs/plugin/duration";

function valuetext(value: number) {
    return `${new Date(value * 1000).toISOString().substring(14, 19)}`;
}

export default function TimestampRangeSelect(props: { value: any; onChange: any }) {
    const [value, setValue] = useState(props.value);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const marks = [
        {
            value: 20 * 60,
            label: valuetext(20 * 60),
        },
        {
            value: 15 * 60,
            label: valuetext(15 * 60),
        },
        {
            value: 10 * 60,
            label: valuetext(10 * 60),
        },
        {
            value: 5 * 60,
            label: valuetext(5 * 60),
        },
        {
            value: 25 * 60,
            label: valuetext(25 * 60),
        },
    ];

    return (
        <Box sx={{ height: "66px", zIndex: 0, width: "300px" }} component="fieldset" style={{ marginTop: "-10px" }}>
            <legend>Timestamp</legend>
            {/*<Typography>*/}
            {/*    Time Range : from {valuetext(props.getValue[0])} to {valuetext(props.getValue[1])}*/}
            {/*</Typography>*/}
            <Slider
                getAriaLabel={() => "Timestamp range"}
                min={0}
                max={60 * 30}
                step={30}
                value={value}
                onChange={handleChange}
                onChangeCommitted={(event, value) => props.onChange(value)}
                valueLabelDisplay="auto"
                valueLabelFormat={valuetext}
                getAriaValueText={valuetext}
                marks={marks}
                style={{ marginTop: "-15px" }}
            />
        </Box>
    );
}

export function Depth(props: { value: any; onChange: any; label?: string }) {
    const [value, setValue] = useState(props.value);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    return (
        <Box sx={{ height: "66px", zIndex: 0, width: "300px", borderRadius: 3 }} component="fieldset" style={{ marginTop: "-10px" }}>
            <legend>{props.label ? props.label : "Depth"}</legend>
            {/*<Typography>*/}
            {/*    Time Range : from {valuetext(props.getValue[0])} to {valuetext(props.getValue[1])}*/}
            {/*</Typography>*/}
            <Slider getAriaLabel={() => "Timestamp range"} min={1} max={6} step={1} value={value} onChange={handleChange} onChangeCommitted={props.onChange} valueLabelDisplay="auto" />
        </Box>
    );
}

export function TimestampSelect(props: { value: number; onChange: any; label?: string, max: number }) {

    return (
        <Box sx={{ height: "66px", zIndex: 0, width: "300px", borderRadius: 3 }} component="fieldset" style={{ marginTop: "-10px" }}>
            <legend>{props.label ? props.label : "Timestamp"}</legend>
            <Slider getAriaLabel={() => "Timestamp"} min={0} max={props.max}
                    step={1} value={props.value} onChange={(event: Event, newValue: number | number[]) => props.onChange(newValue as number)} valueLabelDisplay="auto"
                    valueLabelFormat={valuetext}
                    getAriaValueText={valuetext}/>
        </Box>
    );
}
