import React, {useEffect, useState} from "react";
import FarsightApi from "../../../ApiWrapper";
import StyledDatagrid, {ColumnTypes} from "../styled_datagrid";
import {Stack} from "@mui/material";
import {GridFooterContainer, GridToolbarExport} from "@mui/x-data-grid";
import {GameMetrics, OffSeasonShortlist} from "../../../farsight-api";
import {targets, useReferentialContext} from "../../../Context";
import {FooterExporter} from "../shared/TableDisplayGrid";

export default function OffseasonShortlistStats(props: { shortlist: OffSeasonShortlist; paramObject: any; target: targets; metrics: GameMetrics[] }) {
    const [rows, setRows] = useState([] as any[]);
    const [loading, setLoading] = useState(false);

    const context = useReferentialContext();
    console.log(props.shortlist);
    console.log(props.paramObject)
    useEffect(() => {
        console.log(props.shortlist)
        if (props.shortlist) {
            setLoading(true);
            const api = context.farsightApi.getSummariesApi(props.target);
            context.farsightApi.offSeasonShortlistItem.list(
                {shortlist: props.shortlist.id}
            ).then( (player_list) => {
                const request_parameters = {
                    ...props.paramObject,
                    groupby: props.target === targets.competitive ? ['player'] : ['account__player'],
                    ordering: ['performance'],
                    additional_filters: {...props.paramObject.additional_filters}
                }
                console.log(request_parameters)
                console.log(props.target)
                if(props.target === targets.soloq) {
                    console.log('Target is soloq')
                    request_parameters.additional_filters.account__player__in = player_list.map(p => p.player).join(',')
                }
                if(props.target === targets.competitive) {
                    console.log('Target is competitive')
                    request_parameters.additional_filters.player__in = player_list.map(p => p.player).join(',')
                }
                console.log(request_parameters);
                api.aggregate(
                    request_parameters
                ).then(res => setRows(res)).then(() => setLoading(false))
            })
        }}, [props.shortlist, props.paramObject.watermark, props.target]);

    const grid_columns = [ColumnTypes.player(props.target === targets.competitive ? "player" : "account__player", "Player")];

    // @ts-ignore
    const nc = props.metrics.map((metric: any) =>
        ColumnTypes[metric.type](metric.code, metric.short_name, {
            description: metric.desc,
        }),
    );
    grid_columns.push(...nc);
    console.log(grid_columns)

    return (
        <StyledDatagrid
            rowHeight={60}
            sx={{
                width: "100%",
                "& .header": {
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                },
                "& .border-left": {
                    borderLeft: "solid grey",
                    borderWidth: "2px",
                },
            }}
            getRowId={(row: any) => rows.indexOf(row)}
            initialState={{
                sorting: {
                    sortModel: [{ field: "date", sort: "desc" }],
                },
            }}
            columns={grid_columns}
            rows={rows}
            hideFooter={false}
            loading={loading}
            slots={{
                footer: FooterExporter as any,
            }}
            slotProps={{
                footer: {
                    data: rows,
                    columns: grid_columns
                } as any,
            }}
        />
    );
}


