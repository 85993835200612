import { useContext, useEffect, useState } from "react";
import global_context, { api_url } from "../../../static_vars";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import FarsightApi from "../../../ApiWrapper";

function formatRating(rating: any) {
    if (rating.value != "-1000") {
        return Math.round(rating.value * 100) / 100;
    } else {
        return "";
    }
}

export function BotlaneMatchupsGrid(props: any) {
    const context = useContext(global_context);
    const { version } = context.state;
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        FarsightApi.get_request("BotlaneMatchups", props)
            .then((data) => setRows(data))
            .then(() => setLoading(false));
    }, [props]);

    return (
        <StyledDatagrid
            rowHeight={60}
            getRowId={(row: any) => rows.indexOf(row)}
            initialState={{
                sorting: {
                    sortModel: [{ field: "games", sort: "desc" }],
                },
            }}
            columns={[
                ColumnTypes.champion(version, "ad", "AD."),
                ColumnTypes.rating("ad_performance", "AD P."),
                ColumnTypes.rating("ad_relative_performance", "AD RP."),
                ColumnTypes.rating("ad_gd10", "AD GD10"),
                ColumnTypes.champion(version, "support", "Sup"),
                ColumnTypes.rating("support_performance", "Sup P"),
                ColumnTypes.rating("support_relative_performance", "Sup RP"),
                ColumnTypes.rating("support_gd10", "Sup GD10"),
                ColumnTypes.champion(version, "matchup_ad", "AD."),
                ColumnTypes.rating("matchup_ad_performance", "MU AD P."),
                ColumnTypes.rating("matchup_ad_relative_performance", "MU AD RP."),
                ColumnTypes.rating("matchup_ad_gd10", "MU AD GD10"),
                ColumnTypes.champion(version, "matchup_support", "Sup"),
                ColumnTypes.rating("matchup_support_performance", "MU Sup P"),
                ColumnTypes.rating("matchup_support_relative_performance", "MU Sup RP"),
                ColumnTypes.rating("matchup_support_gd10", "Sup GD10"),
                ColumnTypes.short_int("games", "#"),
                ColumnTypes.percentage("winrate", props.bottom + "/" + props.support + " WR"),
            ]}
            rows={rows}
            hideFooter={true}
            loading={loading}
        />
    );
}
