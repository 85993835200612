import {targets, useReferentialContext} from "../../../Context";
import {useState} from "react";
import {Stack} from "@mui/material";
import TableDisplayGrid, {rowStyle} from "./TableDisplayGrid";


export default function CounterPickProfiling(props: {paramObject: any, target: targets}){
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches);
    const [metrics, setMetrics] = useState(context.metricsReferential.filter(m => ['games','performance','relative_performance','winrate','gd15','csd15','champion_pool'].includes(m.code)))
    return <Stack direction={'column'} sx={{height:'100%'}}>
        <TableDisplayGrid request={() => {
            return context.farsightApi.getSummariesApi(props.target).aggregate(
                {...props.paramObject, groupby:['direct_matchup'], is_cp:true, ordering:'-games', metrics: ['games,performance,relative_performance,winrate,gd15,csd15,champion_pool']}
            )
        }} display_all={false} rowStyle={rowStyle.none} sortBy={'-games'} paramObject={props.paramObject} metrics={metrics} disp_columns={['direct_matchup']}/>
    </Stack>
}