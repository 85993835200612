import React, {useContext, useState} from "react";
import global_context from "../../../static_vars";
import dayjs, {Dayjs} from "dayjs";
import {Stack} from "@mui/material";
import DatePicker from "../../../components/selectors/Shared/DateSelect";
import OffseasonShortlistSelect from "../../../components/selectors/Offseason/OffseasonShortlistSelect";
import Box from "@mui/material/Box";
import OffseasonShortlistStats from "../../../components/datagrids/Offseason/OffseasonShortlistStats";
import {CompetitiveSoloQ} from "../../../components/selectors/YesNoSelector";
import {Champion, targets, useReferentialContext} from "../../../Context";
import {GameMetrics, OffSeasonShortlist} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";
import {Filter} from "@mui/icons-material";
import FilterSet from "../../../components/selectors/Filterset";

export default function ShortlistStats() {
    const context = useReferentialContext();
    const [state, setState] = useState({
        from: dayjs("2024-01-01"),
        until: dayjs(),
        shortList: undefined,
    });
    const [paramObject, setParamObject] = useState({} as any);
    const [from, setFrom] = useState(dayjs("2024-01-01"));
    const [until, setUntil] = useState(dayjs());
    const [shortList, setShortlist] = useState<OffSeasonShortlist | null>(null);

    const [champions, setChampions] = useState([] as Champion[])
    const [metrics, setMetrics] = useState<GameMetrics[]>(context.selectedMetrics);
    const [scope, setScope] = useState<targets>(targets.competitive);

    return (
        <WindowContainer direction={'column'} spacing={1}>
            <OffseasonShortlistSelect value={shortList} onChange={(event: any, newValue: any) => setShortlist(newValue)}/>
            <CompetitiveSoloQ value={scope} onChange={setScope}/>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={scope} date__gte={from} setDateGte={setFrom} date__lt={until}
            setDateLt={setUntil} metrics={metrics} setMetrics={setMetrics} champions={champions} setChampions={setChampions}/>
            {shortList ? <Box sx={{width: "100%", height: "100%"}}>
                <OffseasonShortlistStats shortlist={shortList} paramObject={paramObject} target={scope} metrics={metrics}/>
            </Box> : <></>}
        </WindowContainer>
    );
}
