import { useContext, useEffect, useState } from "react";
import global_context, { api_url, loss_color, win_color } from "../../../static_vars";
import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AdaptablePageSizeGrid from "../AdaptablePageSizeGrid";

export default function PickturnGrid(props: { data: any[]; label: string }) {
    const rows = props.data ? props.data : [];
    return (
        <StyledDatagrid
            rowHeight={40}
            getRowId={(row) => rows.indexOf(row)}
            initialState={{
                sorting: {
                    sortModel: [{ field: "games", sort: "desc" }],
                },
            }}
            columns={[
                ColumnTypes.champion("champion", props.label, {
                    maxWidth: 150,
                }),
                ColumnTypes.short_int("games", "#"),
                ColumnTypes.percentage("winrate", "WR"),
                ColumnTypes.percentage("cp_frequency", "CP%", {
                    hide: props.label.includes("Ban"),
                }),
            ]}
            rows={rows}
            hideFooter={true}
            sx={{
                "& .MuiDataGrid-virtualScroller": {
                    overflowX: "hidden",
                },
            }}
        />
    );
}

export function RolePickturnGrid(props: { data: any[]; label: string }) {
    return (
        <StyledDatagrid
            rowHeight={40}
            getRowId={(row: any) => props.data.indexOf(row)}
            initialState={{
                sorting: {
                    sortModel: [{ field: "games", sort: "desc" }],
                },
            }}
            columns={[
                ColumnTypes.role("role", props.label),
                ColumnTypes.short_int("games", "#"),
                ColumnTypes.percentage("frequency", "%"),
                ColumnTypes.percentage("winrate", "WR"),
                ColumnTypes.percentage("cp_frequency", "CP%", {
                    hide: props.label.includes("Ban"),
                }),
            ]}
            rows={props.data}
            hideFooter={true}
        />
    );
}

function DataGridTitle() {
    return (
        <Box
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography variant="h5">Role CP Efficiency</Typography>
        </Box>
    );
}

export function RoleEfficiencyGrid(props: { data: any[]; side: string | undefined }) {
    return (
        <Box
            sx={{
                height: "258px",
                width: "100%",
                "& .farsight--header": {
                    backgroundColor: props.side ? (props.side === "blue" ? win_color : loss_color) : "inherit",
                },
            }}
        >
            <DataGrid
                rowHeight={40}
                getRowId={(row) => props.data.indexOf(row)}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "games", sort: "desc" }],
                    },
                }}
                columns={[
                    ColumnTypes.role("role", "Role", {
                        headerClassName: "farsight--header",
                    }),
                    // ColumnTypes.short_int('games','CP #'),
                    ColumnTypes.percentage("winrate", "CP WR", {
                        headerClassName: "farsight--header",
                    }),
                    ColumnTypes.rating("csd10", "CSD@10", {
                        headerClassName: "farsight--header",
                    }),
                    ColumnTypes.rating("gd10", "GD@10", {
                        headerClassName: "farsight--header",
                    }),
                    ColumnTypes.rating("xpd10", "XPD@10", {
                        headerClassName: "farsight--header",
                    }),
                ]}
                rows={props.data}
                hideFooter={true}
                // components={{Toolbar: DataGridTitle}}
            />
        </Box>
    );
}
