import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {Avatar, Divider, Grid, IconButton, ListItemIcon, Paper, Stack, Switch, Typography} from "@mui/material";
import ProfileButton from "./ProfileButton";
import MenuItem from "@mui/material/MenuItem";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import GameReviewer from "../../page/Scrims/4_Gameplay/ReviewGame";
import DarkModeToggle from "../selectors/DarkModeSwitch";
import {useThemeContext} from "../../themeContext";

export default function Header(props: { changePage: any; current: number; logout: () => void}) {
    const [menuOpen, setMenuOpen] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClickCompetitive = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "competitive");
    };
    const handleClickSoloQ = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "soloq");
    };
    const handleClickChampionsQ = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "championsq");
    };

    const handleClickOffseason = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "offseason");
    };
    const handleClickScrim = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "scrims");
    };
    const handleClose: any = () => {
        setAnchorEl(null);
        setMenuOpen(null);
    };

    const wrapChangePage = (newValue: number) => {
        props.changePage(undefined, newValue);
        handleClose();
    };

    // onClick={event => props.changePage(event, 0)}
    return (
        <AppBar position="relative" sx={{ width: "100%", height: "10%", backgroundColor:'#1d1d1d' }}>
            <Toolbar sx={{ width: "100%" }}>
                <Stack
                    direction={"row"}
                    sx={{
                        position: "left",
                        width: "100%",
                        justifyContent: "flex-start",
                    }}
                >
                    <ProfileButton logout={props.logout}/>
                    <Button  onClick={(event) => handleClickCompetitive(event)} aria-controls={menuOpen === "competitive" ? "competitive-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "competitive" ? "true" : undefined}>
                        Competitive
                    </Button>
                    <Button onClick={(event) => handleClickSoloQ(event)} aria-controls={menuOpen === "soloq" ? "soloq-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "soloq" ? "true" : undefined}>
                        SoloQ
                    </Button>
                    <Button onClick={(event) => handleClickScrim(event)} aria-controls={menuOpen === "scrims" ? "scrims-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "scrims" ? "true" : undefined}>
                        Scrims
                    </Button>
                    <Button onClick={(event) => handleClickChampionsQ(event)} aria-controls={menuOpen === "championsq" ? "championsq-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "championsq" ? "true" : undefined}>
                        ChampionsQ
                    </Button>
                    <Button onClick={(event) => handleClickOffseason(event)} aria-controls={menuOpen === "offseason" ? "offseason-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "offseason" ? "true" : undefined}>
                        Offseason
                    </Button>
                </Stack>
                <Stack direction={"row"} sx={{ position: "right", justifyContent: "flex-end" }}>
                    <DarkModeToggle />
                </Stack>
                <Menu anchorEl={anchorEl} id="competitive-menu" open={menuOpen === "competitive"} onClick={handleClose}>
                    <Paper
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Stack direction={"row"} sx={{ width: "100%" }}>
                            <Stack direction={"column"} sx={{ width: "100%" }}>
                                <Typography
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    Metagame
                                </Typography>
                                <Button onClick={() => wrapChangePage(0)}>Champions Tierlists</Button>
                                <Button onClick={() => wrapChangePage(1)}>Champions Details</Button>
                                <Button onClick={() => wrapChangePage(2)}>Drafting</Button>
                                <Button onClick={() => wrapChangePage(3)}>Botlane Matchups</Button>
                                <Button onClick={() => wrapChangePage(4)}>Meta Breakers</Button>
                                <Button onClick={() => wrapChangePage(8)}>Best Blinds</Button>
                                <Button onClick={() => wrapChangePage(16)}>Drafting Sim</Button>
                                <Button onClick={() => wrapChangePage(45)}>Sim Perfs</Button>
                                <Button onClick={() => wrapChangePage(46)}>Find Champion Situations</Button>
                                <Button onClick={() => wrapChangePage(47)}>Find Draft Situations</Button>
                            </Stack>
                            <Stack direction={"column"} sx={{ width: "100%" }}>
                                <Typography
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    Scouting
                                </Typography>
                                <Button onClick={() => wrapChangePage(10)}>Team History</Button>
                                <Button onClick={() => wrapChangePage(15)}>Drafts</Button>
                                <Button onClick={() => wrapChangePage(11)}>Ward Maps</Button>
                                <Button onClick={() => wrapChangePage(12)}>Jungle Pathings</Button>
                                <Button onClick={() => wrapChangePage(13)}>Free Maps</Button>
                                <Button onClick={() => wrapChangePage(14)}>Scouting Summary</Button>
                                <Button onClick={() => wrapChangePage(41)}>Gold Graphs</Button>
                                <Button onClick={() => wrapChangePage(48)}>Draft Preparation</Button>
                                <Button onClick={() => wrapChangePage(52)}>Gameplay Elements</Button>
                                <Button onClick={() => wrapChangePage(54)}>Position Heatmap</Button>
                                <Button onClick={() => wrapChangePage(55)}>Teamfights</Button>
                            </Stack>
                            <Stack direction={"column"} sx={{ width: "100%" }}>
                                <Typography
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    Players
                                </Typography>
                                <Button onClick={() => wrapChangePage(6)}>Players Tierlists</Button>
                                <Button onClick={() => wrapChangePage(5)}>Player Details</Button>
                                <Button onClick={() => wrapChangePage(7)}>Player Evolution</Button>
                            </Stack>
                            <Stack direction={"column"} sx={{ width: "100%" }}>
                                <Typography
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    Storytelling
                                </Typography>
                                <Button onClick={() => wrapChangePage(30)}>League History</Button>
                                <Button onClick={() => wrapChangePage(9)}>Kills Hitmap</Button>
                                <Button onClick={() => wrapChangePage(23)}>Stats Vs</Button>
                                <Button onClick={() => wrapChangePage(39)}>Stats H2H</Button>
                                <Button onClick={() => wrapChangePage(40)}>Team stats</Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Menu>
                <Menu anchorEl={anchorEl} id="soloq-menu" open={menuOpen === "soloq"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(17)}>Champions Tierlists</Button>
                                    <Button onClick={() => wrapChangePage(18)}>Champions Details</Button>
                                    <Button onClick={() => wrapChangePage(37)}>League Champions</Button>
                                    <Button onClick={() => wrapChangePage(38)}>1st Items</Button>
                                    <Button onClick={() => wrapChangePage(49)}>Ratings Evolution</Button>
                                    {/*<Button onClick={() => wrapChangePage(2)}>Drafting</Button>*/}
                                    {/*<Button onClick={() => wrapChangePage(3)}>Botlane Matchups</Button>*/}
                                    {/*<Button onClick={() => wrapChangePage(4)}>Meta Breakers</Button>*/}
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Scouting
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(19)}>Player Summary</Button>
                                    <Button onClick={() => wrapChangePage(7)}>Player Details</Button>
                                    <Button onClick={() => wrapChangePage(20)}>Player Monitoring</Button>
                                    <Button onClick={() => wrapChangePage(50)}>League Players</Button>
                                    <Button onClick={() => wrapChangePage(53)}>Team Summaries</Button>
                                </Stack>

                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Players
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(21)}>Players Tierlists</Button>
                                    {/*<Button onClick={() => wrapChangePage(5)}>Player Details</Button>*/}
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="offseason-menu" open={menuOpen === "offseason"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Specific Analysis
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(22)}>Specific player</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Suggestions
                                    </Paper>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Shortlists
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(26)}>Saved Shortlists</Button>
                                    <Button onClick={() => wrapChangePage(28)}>Shortlist Stats</Button>
                                </Stack>
                                {/*<Stack direction={'column'} sx={{width:'100%'}}>*/}
                                {/*    <Paper sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>Monitoring</Paper>*/}
                                {/*</Stack>*/}
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="scrims-menu" open={menuOpen === "scrims"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Planning
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(24)}>Calendar</Button>
                                    <Button onClick={() => wrapChangePage(27)}>Opponents</Button>
                                    <Button onClick={() => wrapChangePage(32)}>History</Button>
                                    <Button onClick={() => wrapChangePage(43)}>Dashboard</Button>
                                    <Button onClick={() => wrapChangePage(51)}>Drafts</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(29)}>Champions Ratings</Button>
                                    <Button onClick={() => wrapChangePage(25)}>Champions Details</Button>
                                    <Button onClick={() => wrapChangePage(34)}>Team Champions</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(31)}>Player Details</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Gameplay
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(33)}>Review Game</Button>
                                    <Button onClick={() => wrapChangePage(36)}>Hitmap Kills</Button>
                                    <Button onClick={() => wrapChangePage(42)}>Jungle Pathings</Button>
                                    <Button onClick={() => wrapChangePage(44)}>Ward Maps</Button>

                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="championsq-menu" open={menuOpen === "championsq"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(35)}>Champions Details</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Players
                                    </Paper>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Gameplay
                                    </Paper>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
