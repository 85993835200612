import { Autocomplete, Box, Checkbox, FormControl, TextField, Tooltip, Chip, IconButton, ListSubheader, CircularProgress, Stack, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState } from "react";

export function MultipleSelectorGrouped<Option>(props: {
    options: Option[];
    selected: Option[];
    updateSelection: (new_selection: Option[]) => void;
    getOptionLabel: (option: Option) => string;
    getOptionShort: (option: Option) => string;
    getGroup: (option: Option) => string;
    limitTags: number;
    label?: string | undefined;
    optionDisabled?: (option: Option) => boolean;
    optionDescription?: (option: Option) => string | undefined | null;
    optionDecoration?: (option: Option) => any;
}) {
    const [selectedGroups, setSelectedGroups] = useState([] as string[]);
    const [openedGroups, setOpenedGroups] = useState([] as string[]);
    const [userTyping, setUserTyping] = useState(false as boolean);
    const [selectedItems, setSelectedItems] = useState(props.selected);

    if (props.options.length === 0) {
        return (
            <Stack
                direction={"row"}
                sx={{
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    display: "flex",
                    height:'57px'
                }}
            >
                <CircularProgress />
                <Typography>{`Loading ${props.label}...`}</Typography>
            </Stack>
        );
    }

    const getGroupItems = (group: string) => props.options.filter((option: Option) => props.getGroup(option) === group);
    const unselectItem = (item: Option) => {
        const new_selection = selectedItems.filter((selectedItem) => item !== selectedItem);
        setSelectedItems(new_selection);
        if (props.selected !== new_selection) props.updateSelection(new_selection);
    };

    const setGroupSelection = (event: any, new_group: string) => {
        const group_items = getGroupItems(new_group);
        if (selectedGroups.includes(new_group)) {
            const new_groups = selectedGroups.filter((group) => group !== new_group);
            setSelectedGroups(new_groups);

            const new_selected_items = selectedItems.filter((option) => !group_items.includes(option));
            setSelectedItems(new_selected_items);
        } else {
            let ng = [...selectedGroups, new_group];
            setSelectedGroups(ng);
            const new_selected_items = [...selectedItems, ...group_items.filter((option) => !selectedItems.includes(option))];
            setSelectedItems(new_selected_items);
        }
    };

    const setGroupDisplay = (event: any, group: string) => {
        if (!openedGroups.includes(group)) {
            setOpenedGroups([...openedGroups, group]);
        } else {
            setOpenedGroups(openedGroups.filter((value: string) => value !== group));
        }
    };

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            sx={{height: '56px', maxHeight:'56px'}}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            value={selectedItems}
            groupBy={props.getGroup}
            onClose={() => {
                setUserTyping(false);
                props.updateSelection(selectedItems);
            }}
            renderGroup={(params) => {
                const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
                const checkedIcon = <CheckBoxIcon fontSize="small" />;
                return (
                    <Box key={params.group}>
                        <ListSubheader sx={{ cursor: "pointer" }}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ mr: 1 }} checked={selectedGroups.includes(params.group)} onClick={(event) => setGroupSelection(event, params.group)} />
                            {params.group}
                            <IconButton size="small" onClick={(event) => setGroupDisplay(event, params.group)} sx={{ marginLeft: "auto" }}>
                                {openedGroups.includes(params.group) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ListSubheader>
                        {openedGroups.includes(params.group) || userTyping ? params.children : <></>}
                    </Box>
                );
            }}
            onChange={(event: any, new_value) => {
                setUserTyping(false);
                setSelectedItems(new_value);
            }}
            renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
            renderOption={(renderOptionProps: any, option: Option, { selected }) => {
                const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
                const checkedIcon = <CheckBoxIcon fontSize="small" />;
                return (
                    <Tooltip title={props.optionDescription ? props.optionDescription(option) : undefined} placement="left">
                        <li {...renderOptionProps}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {props.optionDecoration ? props.optionDecoration(option) : <></>}
                            {props.getOptionLabel(option)}
                        </li>
                    </Tooltip>
                );
            }}
            onInputChange={() => setUserTyping(true)}
            getOptionDisabled={(option) => (props.optionDisabled ? props.optionDisabled(option) : false)}
            renderTags={(value, getTagProps) => {
                const numTags = value.length;
                return (
                    <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        {value.slice(0, props.limitTags).map((option: Option, index) => {
                            return <Chip label={props.getOptionShort(option)} onDelete={() => unselectItem(option)} onClick={() => unselectItem(option)} />;
                            // if(!props.optionDecoration){
                            //     return <Chip label={props.getOptionShort(option)} onDelete={() => unselectItem(option)} onClick={() => unselectItem(option)} />;
                            // }else{
                            //     return props.optionDecoration(option);
                            // }
                        })}
                        {numTags > props.limitTags && ` +${numTags - props.limitTags}`}
                    </Stack>
                );
            }}
            ListboxProps={{ className: "scrollable" }}
        />
    );
}
