import {Select, Stack} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {Bar, Line, Pie} from "react-chartjs-2";
import React, {useState} from "react";


export default function RatingsExplanationChart(props: {championRating: any}) {
    let ratingColumns = ['relativeKills', 'relativeDeaths', 'relativeDamages', 'relativeDamageTaken', 'relativeGolds', 'relativeDmgHealed', 'relativeDmgMitigated', 'relativeWardsPlaced', 'relativeWardsKilled', 'relativeLevel', 'relativeCCtime'];
    // ratingColumns = ratingColumns.sort((a: string, b: string ) => Math.abs(props.championRating[`${b}_corr`]) - Math.abs(props.championRating[`${a}_corr`]));
    const [chartType, setCharType] = useState('Pie');
    const data = {
        labels: ratingColumns.map((col: string) => `${col}`),
        datasets: [
            {
                label: 'Correlations',
                data: ratingColumns.map((col: string) => chartType === 'Pie' ?  Math.abs(props.championRating[`${col}_corr`]) : props.championRating[`${col}_corr`]),
                backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
                borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        lineTension: 0.3,
    };

    return (
        <Stack direction={'row'}
               component={"fieldset"}
               sx={{
                   borderRadius: 3,
                   height: "100%",
                   width: "100%",
                   alignItems: "center",
                   justifyContent: "center",
                   display: "flex",
                   padding: 3
               }}
               spacing={3}
        >
            <legend>{`${props.championRating.champion} Rating Details`}</legend>
            <Select value={chartType} onChange={(event) => setCharType(event.target.value)}>
                <MenuItem value={'Pie'}>Pie</MenuItem>
                <MenuItem value={'Bar'}>Bar</MenuItem>
            </Select>
            {chartType === 'Bar' ?
            <Box sx={{height: "100%", width: "100%"}}>
                <Bar options={options} data={data} width={"100%"} height={"100%"}/>
            </Box>
            :
            <Box sx={{height: "100%", width: "100%" }}>
                <Pie options={options} data={data} width={"100%"} height={"100%"}/>
            </Box>}
        </Stack>
    );

}