import {Grow} from "@mui/material";
import Box from "@mui/material/Box";
import {Bar} from "react-chartjs-2";
import React from "react";

function calculateRollingAverage(values: number[], window: number) {
    const rollingAverages = [];

    for (let i = 0; i < values.length; i++) {
        let sum = 0;
        let count = 0;

        for (let j = i - window; j <= i + window; j++) {
            if (j >= 0 && j < values.length) {
                sum += values[j];
                count++;
            }
        }

        const average = count > 0 ? sum / count : 0;
        rollingAverages.push(average);
    }

    return rollingAverages;
}


export default function GenericBarChart(props:{
    data: any[],
    mainLabel: string;
    getValue: (item: any) => number,
    getComparisonValue?: (item: any) => number,
    comparisonLabel?: string,
    labels: (item: any) => string | number,
    rollingAverageWindow? : number,
    vertical?: boolean
}){

    const options = {
        responsive: true,
        lineTension: 0.3,
        scales: {
            y: {},
            x: {
                barPercentage: 0.4
            }
        },
        indexAxis: props.vertical ? 'y' : 'x',
    };

    let datasets = [
        {
            label: props.mainLabel,
            data: props.data.map(props.getValue),
            backgroundColor: "cyan",
        },
    ]

    if(props.getComparisonValue && props.comparisonLabel){
        const comparison_dataset = {
            label: props.comparisonLabel,
            data: props.data.map(props.getComparisonValue),
            backgroundColor: "rgba(190, 82, 42, 0.8)",
        }
        datasets.push(comparison_dataset);
    }


    if(props.rollingAverageWindow){
        const rolling_average = calculateRollingAverage(props.data.map(props.getValue), props.rollingAverageWindow);
        datasets.push({
            label: "Rolling average",
            data: rolling_average,
            backgroundColor: "rgba(42,114,190,0.8)",
            borderColor: "rgba(42,114,190,0.8)",
            type: 'line'
        } as any)
    }

    const data = {
        labels:  props.data.map(props.labels),
        datasets: datasets
    };

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {/* @ts-ignore*/}
                <Bar options={options} data={data} />
            </Box>
        </Grow>
    );

}