import { Circle, Marker, Popup } from "react-leaflet";
import { Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { Icon } from "leaflet";
import dayjs from "dayjs";
import StyledPopup from "../StyledPopup";
import global_context from "../../../static_vars";
import AnimatedCircle, { getRandomDuration } from "../Markers";

const iconRed = new Icon({
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
});

const iconBlue = new Icon({
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
});

function getIcon(event: any) {
    console.log(event.side);
    if (event.side === "Blue") {
        return iconBlue;
    }
    return iconRed;
}

export default function WardPlacedMarker(event: any) {

    if (event["wardType"] !== "unknown")
        return (
            <AnimatedCircle center={[event["position"][1] * 750, event["position"][0] * 750]} radius={10} color={event["side"]} duration={getRandomDuration(0.5, 1.5)}>
                <StyledPopup autoPan={false}>
                    <Paper
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            flexDirection: "column",
                            display: "flex",
                            height: 40,
                            zIndex: 1000,
                            m: "-5px",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            component={"div"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                display: "flex",
                            }}
                            spacing={1}
                        >
                            <img src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${event["champion"]}.png`} />
                            <img src={`https://res.cloudinary.com/xenesis/image/upload/v1692116694/leagueAssets/${event["wardType"]}.png`} width="40" height="40" />
                            <Typography
                                align="center"
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                {event["player"].split(" (")[0] + "\n" + dayjs.duration(event["gameTime"], "seconds").format("m:ss")}
                            </Typography>
                            <Box
                                component={"img"}
                                src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/` + event["versus"] + ".png"}
                                sx={{
                                    filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                                }}
                            />
                        </Stack>
                    </Paper>
                </StyledPopup>
            </AnimatedCircle>
        );
    else return <></>;

}
