import React, {useContext, useState} from "react";
import {Stack} from "@mui/material";
import {targets} from "../../../../Context";
import {useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";

export default function SoloQChampionsRatings() {
    const context = useReferentialContext();
    const [servers, setServers] = useState(['EUW1','KR'] as string[]);
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [paramObject, setParamObject] = useState({} as any);
    const [detailed, setDetailed] = useState(false);

    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} servers={servers} setServers={setServers} patches={patches} setPatches={setPatches}/>
            {!detailed ? <OverviewTierlists paramObject={paramObject} /> : <DetailedTierlist paramObject={paramObject} />}
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const cols = ["champion", "games", "rating", "winrate", "banrate"];

    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "TOP_LANE",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "JUNGLE",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "MID_LANE",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "BOT_LANE",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}


function DetailedTierlist(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const [role, setRole] = useState("BOT_LANE");
    const [metrics, setMetrics] = useState<any[]>(useReferentialContext().selectedMetrics);
    const [paramObject, setParamObject] = useState<any>(props.paramObject as any);

    const cols = ["champion", "games", "rating", "winrate"];

    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "center",
                width: "100%",
                display: "flex",
                alignItems: "top",
            }}
            spacing={3}
        >
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} updateContext={false} role={role} setRole={setRole} metrics={metrics} setMetrics={setMetrics} />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}
