import React, { useState } from "react";
import {targets} from "../../../Context";
import Box from "@mui/material/Box";
import "../Styles/listbox.css";
import { League } from "../../../farsight-api";
import { useReferentialContext } from "../../../Context";
import { MultipleSelectorGrouped } from "../../Abstract/Multiple";
import { SingleSelectorGrouped } from "../../Abstract/Single";

export function SingleLeagueSelect(props: { value: League | null; updateValue: (newValue: League | null) => void }) {
    const { leaguesReferential } = useReferentialContext();

    const updateSelection = (newSelection: League | null) => {
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "250px" }}>
            <SingleSelectorGrouped
                getGroup={(option: League) => (option.group ? option.group : "Other")}
                getOptionLabel={(option: League) => option.league_name}
                getOptionShort={(option: League) => option.league_name}
                options={leaguesReferential}
                selected={props.value}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                label={"League"}
                optionDecoration={(option) => (option.logo ? <img src={option.logo} width="40" height="40" style={{ cursor: "pointer", marginRight: "10px" }} /> : <></>)}
                inputDecoration={(option) => (option.logo ? <img src={option.logo} width="40" height="40" style={{ cursor: "pointer", marginRight: "10px" }} /> : <></>)}
            />
        </Box>
    );
}

export default function LeaguesSelect(props: { values: League[]; updateValue: (newValue: League[]) => void; updateContext?: boolean | undefined; target: targets }) {
    const { leaguesReferential, selectedLeagues, setSelectedLeagues } = useReferentialContext();

    const [localLeagues, setLocalLeagues] = useState(props.values);
    // console.log(props.updateContext)
    const updateSelection = (newSelection: League[]) => {
        if (props.updateContext) {
            setSelectedLeagues(newSelection);
            console.log('Updated Context')
        } else {
            setLocalLeagues(newSelection);
        }
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "250px" }}>
            <MultipleSelectorGrouped
                getGroup={(option: League) => (option.group ? option.group : "Other")}
                getOptionLabel={(option: League) => option.league_name}
                getOptionShort={(option: League) => option.league_name}
                limitTags={1}
                options={leaguesReferential}
                selected={props.updateContext ? selectedLeagues : localLeagues}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                optionDescription={(option) => option.full_name}
                optionDecoration={(option) => (option.logo ? <img src={option.logo} width="40" height="40" style={{ cursor: "pointer", marginRight: "10px" }} /> : <></>)}
                label={"Leagues"}
            />
        </Box>
    );
}
