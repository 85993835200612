import { ArcElement, Chart } from "chart.js";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grow, Paper, PaperProps} from "@mui/material";
import dayjs from "dayjs";
import {targets, useReferentialContext} from "../../Context";
import {SoloQAccounts} from "../../farsight-api";
import {ChartsReferenceLine, LineChart} from "@mui/x-charts";
import Draggable from "react-draggable";
import PerformanceScoresRadar from "../../page/Shared/ScoreRadar";
import Button from "@mui/material/Button";

export default function SoloQPlayerEvolution(props: {accounts: SoloQAccounts[], year?: number|undefined}) {
    const [rows, setRows] = useState([] as any[]);
    const [radarsOpen, setRadarsOpen] = useState([] as any[]);
    const api = useReferentialContext().farsightApi.soloqGameSummaries;


    useEffect(() => {
        api.aggregate(
            {
                groupby: ['patch'],
                ordering: ['date__max'],
                additional_filters:{
                    date__year: props.year,
                    account_id__in: [props.accounts.map(acc => acc.account).join(',')]
                },
                metrics:['performance,relative_performance,games,winrate,elo'],
                aggregates:['date__max'],
                having: {games__gte: 2}
            }
        ).then(result => {
            setRadarsOpen(result.map(() => false))
            setRows(result.map((row: any, i: number) => {
                return {
                    ...row,
                    label: `${row.patch}\n${row.games} games\n${(row.winrate === true ? 100 : row.winrate * 100).toFixed(1)}% WR`,
                    i: i,
                    lps: row.elo - 3100
                }
            }
        ))});
    }, [props.accounts, props.year]);

    const clickHandler = (
        event: any, params: any
    ) => {
        const n_array = radarsOpen.map(i => i);
        n_array[params.dataIndex] = true;
        setRadarsOpen(n_array)
    }

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box sx={{ width: "100%", height: "100%" }}>
                {/*{rows.map((r, i) => <DraggableSplitRadar label={r.label} open={radarsOpen[i]} paramObject={{*/}
                {/*    additional_filters: {date__year:props.year},*/}
                {/*    player: props.player.player, phase:r.phase, split:r.split, league:r.league, team1:r.team*/}
                {/*}}*/}
                {/*                                         handleClose={() => {*/}
                {/*    const n_array = radarsOpen.map(i => i);*/}
                {/*    n_array[i] = false;*/}
                {/*    setRadarsOpen(n_array);*/}
                {/*}}/>)}*/}
                <LineChart  xAxis={[{ data: rows.map((r: any, i:number) => r.label), scaleType:'band'}]} dataset={rows} rightAxis={'elo'}
                            yAxis={[{max: 115, min:-60, id:'perf', label:'Performance', position:'left'}, {max: 2000, min:-200, id:'elo', label:'Elo', position:'right'}]} grid={{horizontal: true, vertical: true}}
                              // onAxisClick={(event, d) => clickHandler(event,d)}
                           series={[{
                              label: 'Performance',
                              dataKey: 'performance',
                           },
                           {
                              label: 'Relative Performance',
                              dataKey: 'relative_performance',
                           },
                           {
                              label: 'Elo',
                              dataKey: 'lps',
                              yAxisKey:'elo'
                           },
                ]}>
                    <ChartsReferenceLine y={"0"} label="Performance Average" labelAlign="start" />
                    <ChartsReferenceLine y={"0"} label="Master tier" labelAlign="end" axisId={'elo'} lineStyle={{color:'purple'}}/>
                    {rows.filter(r => r.patch === '14.10').length != 0 ? <ChartsReferenceLine x={rows.filter(r => r.patch === '14.10')[0].label} label="Split start" labelAlign="end"/> : <></>}
                    {rows.filter(r => r.patch === '14.1').length != 0 ? <ChartsReferenceLine x={rows.filter(r => r.patch === '14.1')[0].label} label="Split start" labelAlign="end"/> : <></>}
                </LineChart>
            </Box>
        </Grow>
    );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DraggableSplitRadar(props: {label: string, paramObject: any, open: boolean, handleClose: () => void}){
    return (
         <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="draggable-dialog-title"
            PaperComponent={PaperComponent}
            // disablePortal={true}
            // disableEnforceFocus
            // disableEscapeKeyDown
            // hideBackdrop
            fullWidth={false}
            maxWidth={'xl'}
            // style={{
            //     position: 'initial',
            //   }}
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {props.label}
            </DialogTitle>
        <DialogContent sx={{ width:'1000px', height:'700px'}}>
            <Box sx={{width:'100%', height:'100%'}}>
                <PerformanceScoresRadar target={targets.competitive} paramObject={props.paramObject}/>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
  );
}
