import React, { useContext, useEffect, useState } from "react";
import FarsightApi from "../../ApiWrapper";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grow,
    Link,
    Paper,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import { PhoneAndroid } from "@mui/icons-material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../tabs_switch/tabs_panel";
import AgencySelect from "../selectors/Offseason/AgencySelect";
import EditableField from "../Inputs/EditableField";
import global_context from "../../static_vars";
import BadgeIcon from "@mui/icons-material/Badge";
import OffseasonShortlistSelect from "../selectors/Offseason/OffseasonShortlistSelect";
import {Agencies, OffSeasonShortlist, Player} from "../../farsight-api";
import {useReferentialContext} from "../../Context";

export default function PlayerPresentation(props: { player: Player }) {
    const [flagUrl, setFlag] = useState("" as string);
    const [editAgencyOpen, openEditAgency] = useState(false);
    const [addToShortlist, openAddToShortlist] = useState(false);
    const [agency, setAgency] = useState(undefined as Agencies|undefined);
    const agency_api = useReferentialContext().farsightApi.agencies;
    useEffect(() => {
        const apiUrl = `https://restcountries.com/v3.1/name/${props.player.nationality}?fullText=true`;
        fetch(apiUrl).then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data) && data.length > 0) {
                    const flagUrl = data[0].flags.svg;
                    setFlag(flagUrl);
                } else {
                    console.log(`Country ${props.player.nationality} not found.`);
                }
            })
            .catch((error) => {
                console.error("Error fetching country data:", error);
            });
        if(props.player.agency) agency_api.get({id: props.player.agency}).then(result => setAgency(result));
    }, [props.player]);

    return <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 1000 }}>
            <Stack
                direction={"column"}
                sx={{
                    width: "350px",
                    height: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderRadius: 3,
                }}
            >
                {/*<EditAgency*/}
                {/*    close={(newAgency: any) => {*/}
                {/*        openEditAgency(!editAgencyOpen);*/}
                {/*        props.player.agency = newAgency;*/}
                {/*    }}*/}
                {/*    open={editAgencyOpen}*/}
                {/*    player={props.player}*/}
                {/*/>*/}
                <AddToShortlist open={addToShortlist} close={() => openAddToShortlist(!addToShortlist)} player={props.player} />
                <Stack direction={"row"} spacing={1}>
                    {props.player.current_team || props.player.last_team ? (
                        <Tooltip title={props.player.current_team} followCursor={true}>
                            <Link href={`https://lol.fandom.com/wiki/${props.player.current_team ? props.player.current_team : props.player.last_team}`} target="_blank">
                                <Box
                                    component={"img"}
                                    src={`https://res.cloudinary.com/xenesis/image/upload/v1/teamsLogo/${props.player.current_team ? props.player.current_team : props.player.last_team}.png`}
                                    sx={{ width: "80px", height: "80px" }}
                                />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    {props.player.role ? <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${props.player.role}.png`} sx={{ width: "80px", height: "80px" }} /> : <></>}
                    {}
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    {props.player.lolpros_id ? (
                        <Tooltip title={"Lolpros.gg"} followCursor={true}>
                            <Link href={`https://lolpros.gg/player/${props.player.lolpros_id}`} target="_blank">
                                <Box component={"img"} src="../icons/lolpros.ico" alt="Lolpros" sx={{ width: "40px", height: "40px" }} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    {props.player.twitter ? (
                        <Tooltip title={"Twitter (not X)"} followCursor={true}>
                            <Link href={`https://twitter.com/${props.player.twitter}`} target="_blank">
                                <Box component={"img"} src="../icons/twitter.png" alt="Twitter" sx={{ width: "40px", height: "40px" }} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    {props.player.source === "Leaguepedia" ? (
                        <Tooltip title={"Leaguepedia"} followCursor={true}>
                            <Link href={`https://lol.fandom.com/wiki/${props.player.player}`} target="_blank">
                                <Box component={"img"} src="../icons/leaguepedia.webp" alt="Leaguepedia" sx={{ width: "40px", height: "40px" }} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    {props.player.stream ? (
                        <Tooltip title={"Stream"} followCursor={true}>
                            <Link href={props.player.stream} target="_blank">
                                <Box component={"img"} src="../icons/twitch.png" alt="Twitch" sx={{ width: "40px", height: "40px" }} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </Stack>
                {
                    <Tooltip title={`${props.player.player} being really handsome`} followCursor={true}>
                        <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 2000 } : {})}>
                            <Box component={"img"} src={props.player.image ? props.player.image.split("/revision/latest")[0] : "https://static.wikia.nocookie.net/lolesports_gamepedia_en/images/1/1d/Unknown_Infobox_Image_-_Player.png"} width={"50%"} />
                        </Fade>
                    </Tooltip>
                }
                {props.player.real_name ? <Box>{props.player.real_name}</Box> : <Box>{"Anonymous"}</Box>}
                {props.player.nationality ? (
                    <Tooltip title={props.player.nationality} followCursor={true}>
                        <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 2000 } : {})}>
                            <Box component={"img"} src={flagUrl} alt={props.player.nationality} sx={{ width: "80px" }} />
                        </Fade>
                    </Tooltip>
                ) : (
                    <Box sx={{ width: "80px", height: "48px" }} />
                )}
                {
                    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 1000 } : {})}>
                        <Box>{`${dayjs(props.player.birthdate).format("MMM DD YYYY")} - ${Math.trunc(dayjs().diff(dayjs(props.player.birthdate)) / (3600 * 24 * 365 * 1000))} years old`}</Box>
                    </Grow>
                }
                {props.player.contract_expires ? <Box>{`Contract expiring on ${dayjs(props.player.contract_expires).format("MMMM DD YYYY")}`}</Box> : <Box>{"Free Agent"}</Box>}
                {agency ? (
                    <Stack direction={"column"} sx={{ width: "100%", alignItems: "center" }} spacing={1}>
                        <Typography>{agency.name}</Typography>
                        <Stack direction={"row"} sx={{ width: "100%", justifyContent: "center" }}>
                            {agency.email ? (
                                <Tooltip title={`Copy Email : ${agency.email}`} followCursor={true}>
                                    <Button onClick={() => navigator.clipboard.writeText(agency.email ? agency.email : '')}>
                                        <EmailIcon
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {agency.phone ? (
                                <Tooltip title={`Copy Phone : ${agency.phone}`} followCursor={true}>
                                    <Button onClick={() => navigator.clipboard.writeText(agency.phone ? agency.phone : '')}>
                                        <PhoneAndroid
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {agency.twitter ? (
                                <Tooltip title={"Twitter (not X)"} followCursor={true}>
                                    <Link href={`https://twitter.com/${agency.twitter ? agency.twitter : ''}`} target="_blank">
                                        <Button>
                                            <TwitterIcon
                                                sx={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            />
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {agency.website ? (
                                <Tooltip title={`Website : ${agency.website}`} followCursor={true}>
                                    <Link href={`${agency.website}`} target="_blank">
                                        <Button>
                                            <LanguageIcon
                                                sx={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            />
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            <Typography>{agency.discord}</Typography>
                        </Stack>
                        <Button onClick={() => openEditAgency(true)} variant={"outlined"}>
                            Edit Agency
                        </Button>
                    </Stack>
                ) : (
                    <Button onClick={() => openEditAgency(true)} variant={"outlined"}>
                        Add Agency
                    </Button>
                )}
                <Button onClick={() => openAddToShortlist(true)} variant={"outlined"}>
                    Add to Shortlist
                </Button>
            </Stack>
        </Grow>
}

function EditAgency(props: { player: any; open: boolean; close: any }) {
    const { player } = props;
    const context = useContext(global_context);
    const [agency, setAgency] = useState(player.agency && player.agency.name ? context.state.agencies.filter((ag: any) => ag.name === player.agency.name)[0] : context.state.agencies[0]);
    const [newAgency, setNewAgency] = useState({ name: "New Agency" } as any);
    const [tab, setTab] = useState(0);

    function handleClose() {
        if (tab === 0) {
            FarsightApi.get_request("EditObject", {
                target: "player",
                field: "agency_id",
                value: agency.name,
                id: player.player,
            });
            props.close(agency);
        } else {
            FarsightApi.post_request("CreateObject", { target: "agency" }, newAgency).then((data: any) => {
                FarsightApi.get_request("EditObject", {
                    target: "player",
                    field: "agency_id",
                    value: newAgency.name,
                    id: player.player,
                });
                context.fetchAgencies();
            });
            props.close(newAgency);
        }
    }

    const refresh_view = (newValue: any) => setAgency(newValue);

    return (
        <Stack direction={"column"}>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>{`${player.player} agency`}</DialogTitle>
                <DialogContent sx={{ height: "500px" }}>
                    <Tabs value={tab} aria-label="basic tabs example" onChange={(event: any, newValue: number) => setTab(newValue)}>
                        <Tab label="Existing" {...a11yProps(0)} />
                        <Tab label="New" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Stack direction={"column"} sx={{ padding: "20px", width: "500px", mt: 1 }} spacing={2}>
                            <AgencySelect value={agency} onChange={(event: any, newValue: any) => setAgency(newValue)} />
                            <EditableField field={"email"} icon={<EmailIcon />} value={agency.email} onChange={refresh_view} target={"agency"} id={agency.name} edit={true} />
                            <EditableField field={"phone"} icon={<PhoneAndroid />} value={agency.phone} onChange={refresh_view} target={"agency"} id={agency.name} edit={true} />
                            <EditableField field={"website"} icon={<LanguageIcon />} value={agency.website} onChange={refresh_view} target={"agency"} id={agency.name} edit={true} />
                            <EditableField field={"twitter"} icon={<TwitterIcon />} value={agency.twitter} onChange={refresh_view} target={"agency"} id={agency.name} edit={true} />
                            <Stack direction={"row"} spacing={2}>
                                <Typography>{agency.discord}</Typography>
                            </Stack>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Stack direction={"column"} sx={{ padding: "20px", width: "500px", mt: 1 }} spacing={2}>
                            <EditableField field={"name"} icon={<BadgeIcon />} value={newAgency.name} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                            <EditableField field={"email"} icon={<EmailIcon />} value={newAgency.email} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                            <EditableField field={"phone"} icon={<PhoneAndroid />} value={newAgency.phone} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                            <EditableField field={"website"} icon={<LanguageIcon />} value={newAgency.website} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                            <EditableField field={"twitter"} icon={<TwitterIcon />} value={newAgency.twitter} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                            <EditableField field={"discord"} icon={<Typography>Discord</Typography>} value={newAgency.discord} onChange={refresh_view} target={"agency"} id={undefined} edit={false} />
                        </Stack>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close}>Cancel</Button>
                    <Button onClick={handleClose}>Set Agency</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

function AddToShortlist(props: { player: any; open: boolean; close: any }) {
    const context = useReferentialContext();
    const [shortlist, setShortlist] = useState(undefined as any);
    const [current_shortlists, setCurrentShortlists] = useState([] as OffSeasonShortlist[]);

    useEffect(( ) => {
        context.farsightApi.offSeasonShortlistItem.list({
            player: props.player.player
        }).then(items => {
            const ids = items.map(i => i.shortlist);
            if(ids.length>0){
                context.farsightApi.offSeasonShortlist.list(
                {additional_filters: {
                        id__in: ids.toString()
                    }}
            ).then(r => setCurrentShortlists(r))
            }
        })
    }, [])
    function handleClose() {
        if (shortlist) context.farsightApi.offSeasonShortlistItem.create({
            id: '0',
            off_season_shortlist_item: {
                shortlist: shortlist.id,
                player: props.player.player
            } as any
        });
        props.close();
    }
    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>{`Add ${props.player.player} to shortlist`}</DialogTitle>
            <DialogContent sx={{ height: "500px" }}>
                <OffseasonShortlistSelect value={shortlist} onChange={(event: any, newValue: any) => setShortlist(newValue)} />
                <Stack direction={'column'} sx={{mt: 2}}>
                    <Typography>Player is already in the following shortlists</Typography>
                    {
                        current_shortlists.map(s => <Typography>{s.name}</Typography>)
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close}>Cancel</Button>
                <Button onClick={handleClose}>Add to shortlist</Button>
            </DialogActions>
        </Dialog>
    );
}
