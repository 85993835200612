import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import Box from "@mui/material/Box";
import { api_url } from "../../static_vars";
import { Dayjs } from "dayjs";
import { QrCode } from "@mui/icons-material";
import FarsightApi from "../../ApiWrapper";
import { Grow } from "@mui/material";
import {Player} from "../../farsight-api";
import {useReferentialContext} from "../../Context";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function SoloQMonitoringChart(props: { paramObject: any, player: Player }) {
    const [rows, setRows] = useState([] as any[]);
    const [compareRows, setCompareRows] = useState([]);

    const api = useReferentialContext().farsightApi.soloqGameSummaries

    useEffect(() => {

        const request_parameters = {
            ...props.paramObject,
            groupby: 'date__hour',
            metrics: ['games','winrate','performance','relative_performance'].toString(),
            ordering: "date__hour",
            format: "json",
            index_on: 'date__hour'
        }
        console.log(request_parameters);
        api.aggregate(request_parameters).then(result => {
            console.log(result);
            setRows(result);
        });
    }, [props.paramObject.watermark]);

    // useEffect(() => {
    //     fetch(api_url + "GetLecSoloQTimeslots/?&player=" + player + "&until=" + until + "&from=" + from + "&format=json")
    //         .then((response) => response.json())
    //         .then((data) => setCompareRows(data));
    // }, [from, until, player]);

    const options = {
        scales: {
            x: {},
            x2: { display: false },
            x3: { display: false },
            x4: { display: false },
            y: {
                min: 0,
                stepSize: 0.5,
            },
            y2: {
                min:-150,
                max:150
            }
        },
        indexAxis: "x" as const,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right" as const,
            },
        },
    };

    const labels = [
        "00 AM - 01 AM",
        "01 AM - 02 AM",
        "02 AM - 03 AM",
        "03 AM - 04 AM",
        "04 AM - 05 AM",
        "05 AM - 06 AM",
        "06 AM - 07 AM",
        "07 AM - 08 AM",
        "08 AM - 09 AM",
        "09 AM - 10 AM",
        "10 AM - 11 AM",
        "11 AM - 12 PM",
        "12 PM - 13 PM",
        "13 PM - 14 PM",
        "14 PM - 15 PM",
        "15 PM - 16 PM",
        "16 PM - 17 PM",
        "17 PM - 18 PM",
        "18 PM - 19 PM",
        "19 PM - 20 PM",
        "20 PM - 21 PM",
        "21 PM - 22 PM",
        "22 PM - 23 PM",
        "23 PM - 00AM",
    ];

    console.log(rows)
    const data = {
        labels,
        datasets: [
            {
                label: `${props.player.player} wins`,
                data: labels.map((label, index) => rows[index] ? rows[index][0].games * rows[index][0].winrate : 0),
                borderColor: "rgb(139, 42, 82)",
                backgroundColor: "rgba(139, 42, 82, 1)",
                xAxisID: "x2",
            },
            {
                label: `${props.player.player} games`,
                data: labels.map((label, index) => rows[index] ? rows[index][0].games : 0),
                borderColor: "rgb(42, 82, 190)",
                backgroundColor: "rgba(42, 82, 190, 0.5)",
            },
            {
                label: `${props.player.player} Performance`,
                data: labels.map((label, index) => rows[index] ? rows[index][0].performance : undefined),
                borderColor: "rgb(42, 82, 190)",
                backgroundColor: "rgba(42, 82, 190, 0.5)",
                type: 'line',
                yAxisID: "y2"
            },
            {
                label: `${props.player.player} Relative Performance`,
                data: labels.map((label, index) => rows[index] ? rows[index][0].relative_performance : undefined),
                borderColor: "rgb(139, 42, 82)",
                backgroundColor: "rgba(139, 42, 82, 1)",
                type: 'line',
                yAxisID: "y2"
            },
            // {
            //     label: compare + " wins",
            //     data: compareRows.map((row: any) => row.wins),
            //     borderColor: "rgb(190, 82, 42)",
            //     backgroundColor: "rgba(190, 82, 42, 1)",
            //     xAxisID: "x2",
            // },
            // {
            //     label: compare + " games count",
            //     data: compareRows.map((row: any) => row.games_count),
            //     borderColor: "rgb(0, 0, 0)",
            //     backgroundColor: "rgba(0, 0, 0, 0.9)",
            // },
        ],
    };

    // @ts-ignore
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {/* @ts-ignore*/}
                <Bar options={options} data={data} />
            </Box>
        </Grow>
    );
}
