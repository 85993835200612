import {useReferentialContext} from "../../../Context";
import {League} from "../../../farsight-api";
import {useState} from "react";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import WindowContainer from "../../../components/layout/WindowContainer";
import {SingleLeagueSelect} from "../../../components/selectors/Competitive/LeagueSelect";
import dayjs from "dayjs";


export default function LeaguePlayerElo() {
    const [league, setLeague] = useState<League|null>(null);
    const api = useReferentialContext().farsightApi.soloqAccount;
    const request_parameters = {
        additional_filters: {
            most_recent_game__gte: dayjs().subtract(2, 'weeks').toISOString(),
            player__current_team__current_league: league?.league_name
        },
        ordering: ['-elo_as_total_lps']
    }

    return <WindowContainer spacing={1}>
        <SingleLeagueSelect value={league} updateValue={setLeague}/>
        <TableDisplayGrid request={() => api.list(request_parameters)} display_all={true} rowStyle={rowStyle.none} sortBy={'elo_as_total_lps'} paramObject={{watermark: league}}/>
    </WindowContainer>

}