import {Stack, Theme, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {targets, useReferentialContext} from "../Context";
import dayjs, {Dayjs} from "dayjs";
import Proximities from "../components/Charts/Proximities";
import WindowContainer from "../components/layout/WindowContainer";
import ForwardPercentages from "../components/Charts/ForwardPercentage";
import DatePicker from "../components/selectors/Shared/DateSelect";

export default function DashboardMain(){
    const user_team = useReferentialContext().user.team;
    const farsight = useReferentialContext().farsightApi;

    const [competitive, setCompetitive] = useState({} as any);
    const [scrims, setScrims] = useState({} as any);

    const [competitiveDone, setCompetitiveDone] = useState(false);
    const [scrimsDone, setScrimsDone] = useState(false);

    const [competitive_date_from, set_competitive_date_from] = useState(dayjs().subtract(2, 'months'));
    const [scrims_date_from, set_scrims_date_from] = useState(dayjs().subtract(2, 'weeks'));

    const aggregates = [
            'kills__sum__per_minute',
            'deaths__sum__per_minute' ,
            'participant_id__avg' ,
            'dpm__avg' ,
            'gd15__sum__per_game' ,
            'gd25__sum__per_game' ,
            'wards_placed__sum__per_minute' ,
            'vision_wards_bought__sum__per_minute' ,
            'duration__avg__in_wins' ,
            'duration__avg__in_losses' ,
            'solo_kills__sum__per_game',
            'solo_deaths__sum__per_game'
        ]
    const paramObject = {
            team1:user_team,
            additional_filters : {date__gte:competitive_date_from.toISOString()},
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate'],
        }

    useEffect(() => {
        farsight.getSummariesApi(targets.competitive).aggregate({
            team1:user_team,
            additional_filters : {date__gte:scrims_date_from.toISOString()},
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate'],
        }).then(r => {
            setCompetitive(r[0] ? r[0] : {});
            setCompetitiveDone(true);
        })
        farsight.getSummariesApi(targets.scrims).aggregate({
            team1:user_team,
            additional_filters : {date__gte:competitive_date_from.toISOString()},
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate'],
        }).then(r => {
            setScrims(r[0]);
            setScrimsDone(true);
        })
    }, [scrims_date_from, competitive_date_from])



    return <WindowContainer direction={'column'} spacing={5}>
        <DatePicker value={competitive_date_from} onChange={set_competitive_date_from} label={"Competitive Date From"} />
        <DatePicker value={scrims_date_from} onChange={set_scrims_date_from} label={"Scrims Date From"} />
        <SectionHeader text={user_team} vertical={false}/>
        {competitiveDone && scrimsDone ? <Stack direction={'column'} sx={{width: '100%'}}>
            <Stack direction={'row'} sx={{display: 'flex'}}>
                <Typography sx={{width: '33%'}}>{'Stat'}</Typography>
                <Typography sx={{width: '33%'}}>{'Scrims'}</Typography>
                <Typography sx={{width: '33%'}}>{'Competitive'}</Typography>
            </Stack>
            <StatComparison stat={'Winrate'} scrims={scrims.winrate} competitive={competitive.winrate}
                            format={(r: number) => (r * 100).toFixed(2) + '%'}></StatComparison>
            <StatComparison stat={'Kills per minute'} scrims={scrims.kills__sum__per_minute}
                            competitive={competitive.kills__sum__per_minute}
                            format={(r: number) => (r * 5).toFixed(4)}></StatComparison>
            <StatComparison stat={'Deaths per minute'} scrims={scrims.deaths__sum__per_minute}
                            competitive={competitive.deaths__sum__per_minute}
                            format={(r: number) => (r * 5).toFixed(4)}></StatComparison>
            <StatComparison stat={'Damage per minute'} scrims={scrims.dpm__avg}
                            competitive={competitive.dpm__avg}
                            format={(r: number) => (r * 5).toFixed(0)}></StatComparison>
            <StatComparison stat={'Wards per minute'} scrims={scrims.wards_placed__sum__per_minute}
                            competitive={competitive.wards_placed__sum__per_minute}
                            format={(r: number) => (r * 5).toFixed(1)}></StatComparison>
            <StatComparison stat={'Control wards bought per minute'} scrims={scrims.vision_wards_bought__sum__per_minute}
                            competitive={competitive.vision_wards_bought__sum__per_minute}
                            format={(r: number) => (r * 5).toFixed(1)}></StatComparison>
            <StatComparison stat={'GD@15'} scrims={scrims.gd15__sum__per_game}
                            competitive={competitive.gd15__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'GD@25'} scrims={scrims.gd25__sum__per_game}
                            competitive={competitive.gd25__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'Solo Kills'} scrims={scrims.solo_kills__sum__per_game}
                            competitive={competitive.solo_kills__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Solo Deaths'} scrims={scrims.solo_deaths__sum__per_game}
                            competitive={competitive.solo_deaths__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Durée des victoires'} scrims={scrims.duration__avg__in_wins}
                            competitive={competitive.duration__avg__in_wins}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
            <StatComparison stat={'Durée des défaites'} scrims={scrims.duration__avg__in_losses}
                            competitive={competitive.duration__avg__in_losses}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
        </Stack> : <></>}
        <PlayerMetricsComparison role={'TOP_LANE'} paramObject={paramObject} scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>
        <PlayerMetricsComparison role={'JUNGLE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>
        <PlayerMetricsComparison role={'MID_LANE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>
        <PlayerMetricsComparison role={'BOT_LANE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>
        <PlayerMetricsComparison role={'UTILITY'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>
        <SectionHeader text={'Jungle Proximities Early Game (Competitive)'} vertical={false}/>
        <Stack direction={'row'} sx={{minHeight:'350px'}} spacing={2}>
            <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>
                <SectionHeader text={user_team} vertical={false}/>
                <Proximities target={targets.competitive} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:competitive_date_from.toISOString()}}} timer={'pre15'}/>
            </Stack>
            <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>
                <SectionHeader text={`Ennemies of ${user_team}`} vertical={false}/>
                <Proximities target={targets.competitive} paramObject={{team2:user_team, role: 'JUNGLE', additional_filters : {date__gte:competitive_date_from.toISOString()}}} timer={'pre15'}/>
            </Stack>
        </Stack>

        <SectionHeader text={'Jungle Proximities Early Game (Scrims)'} vertical={false}/>
        <Stack direction={'row'} sx={{minHeight:'350px'}} spacing={2}>
            <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>
                <SectionHeader text={user_team} vertical={false}/>
                <Proximities target={targets.scrims} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'pre15'}/>
            </Stack>
            <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>
                <SectionHeader text={`Ennemies of ${user_team}`} vertical={false}/>
                <Proximities target={targets.scrims} paramObject={{team2:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'pre15'}/>
            </Stack>
        </Stack>

        <SectionHeader text={'Forward Percentages'} vertical={false}/>
        <Stack direction={'row'} sx={{minHeight:'650px', height:'650px', width:'100%'}} spacing={2}>
            <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}, watermark: 'undefined'}}
                                 paramObjectReference={{team2:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}}}
                                 target={targets.competitive} metric={'forward_percentage_pre_15'}/>
            <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}, watermark: 'undefined'}}
                                 paramObjectReference={{team2:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}}}
                                 target={targets.competitive} metric={'forward_percentage_post_15'}/>
        </Stack>

        <SectionHeader text={'Forward Percentages (Scrims)'} vertical={false}/>
        <Stack direction={'row'} sx={{minHeight:'650px', height:'650px', width:'100%'}} spacing={2}>
            <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}, watermark: 'undefined'}}
                                 paramObjectReference={{team2:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}}}
                                 target={targets.scrims} metric={'forward_percentage_pre_15'}/>
            <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}, watermark: 'undefined'}}
                                 paramObjectReference={{team2:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}}}
                                 target={targets.scrims} metric={'forward_percentage_post_15'}/>
        </Stack>
    </WindowContainer>

}

const sectionHeaderStyle = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    "& svg": {
        marginRight: theme.spacing(1),
    },
    justifyContent: "center",
    boderRadius: 2
});

function SectionHeader(props: { text: string, vertical: boolean }) {
    return (
        <Box component={"fieldset"} sx={{ ...sectionHeaderStyle, ...props.vertical ? {textOrientation: 'sideways', writingMode: 'vertical-lr'} : {}}} >
            <Typography variant="h5" align={"center"} aria-orientation={'vertical'}>
                {props.text}
            </Typography>
        </Box>
    );
}

function StatComparison(props: {scrims: number, competitive: number|undefined, format: any, stat: string}){
    return <Stack direction={'row'} sx={{display:'flex'}}>
        <Typography sx={{width:'33%'}}>{props.stat}</Typography>
        <Typography sx={{width:'33%'}}>{props.format(props.scrims)}</Typography>
        <Typography sx={{width:'33%'}}>{props.competitive !== undefined ? props.format(props.competitive) : undefined}</Typography>
    </Stack>

}

function PlayerMetricsComparison(props: {paramObject: any, role: string, scrims_date_from: Dayjs, competitive_date_from: Dayjs}){
    const user_team = useReferentialContext().user.team;
    const farsight = useReferentialContext().farsightApi;

    const [competitive, setCompetitive] = useState({} as any);
    const [scrims, setScrims] = useState({} as any);

    const [competitiveDone, setCompetitiveDone] = useState(false);
    const [scrimsDone, setScrimsDone] = useState(false);

    useEffect(() => {
        const aggregates = [
            'kills__sum__per_minute',
            'deaths__sum__per_minute' ,
            'participant_id__avg' ,
            'dpm__avg' ,
            'gd15__sum__per_game' ,
            'gd25__sum__per_game' ,
            'wards_placed__sum__per_minute' ,
            'vision_wards_bought__sum__per_minute' ,
            'duration__avg__in_wins' ,
            'duration__avg__in_losses' ,
            'solo_kills__sum__per_game',
            'solo_deaths__sum__per_game'
        ]
        farsight.getSummariesApi(targets.competitive).aggregate({
            ...props.paramObject,
            role: props.role,
            additional_filters: {...props.paramObject.additional_filters, date__gte:props.competitive_date_from.toISOString()}
        }).then(r => {
            setCompetitive(r[0] ? r[0] : {});
            setCompetitiveDone(true);
        })
        farsight.getSummariesApi(targets.scrims).aggregate({
            ...props.paramObject,
            role: props.role,
            additional_filters: {...props.paramObject.additional_filters, date__gte:props.scrims_date_from.toISOString()}
        }).then(r => {
            setScrims(r[0]);
            setScrimsDone(true);
        })
    }, [props.scrims_date_from, props.competitive_date_from])

    return <Stack direction={'column'}><SectionHeader text={`${user_team} ${props.role}`} vertical={false}/>
        {competitiveDone && scrimsDone ? <Stack direction={'column'} sx={{width: '100%'}}>
            <Stack direction={'row'} sx={{display: 'flex'}}>
                <Typography sx={{width: '33%'}}>{'Stat'}</Typography>
                <Typography sx={{width: '33%'}}>{'Scrims'}</Typography>
                <Typography sx={{width: '33%'}}>{'Competitive'}</Typography>
            </Stack>
            <StatComparison stat={'Winrate'} scrims={scrims.winrate} competitive={competitive.winrate}
                            format={(r: number) => (r * 100).toFixed(2) + '%'}></StatComparison>
            <StatComparison stat={'Kills per minute'} scrims={scrims.kills__sum__per_minute}
                            competitive={competitive.kills__sum__per_minute}
                            format={(r: number) => (r).toFixed(4)}></StatComparison>
            <StatComparison stat={'Deaths per minute'} scrims={scrims.deaths__sum__per_minute}
                            competitive={competitive.deaths__sum__per_minute}
                            format={(r: number) => (r).toFixed(4)}></StatComparison>
            <StatComparison stat={'Damage per minute'} scrims={scrims.dpm__avg}
                            competitive={competitive.dpm__avg}
                            format={(r: number) => (r).toFixed(0)}></StatComparison>
            <StatComparison stat={'Wards per minute'} scrims={scrims.wards_placed__sum__per_minute}
                            competitive={competitive.wards_placed__sum__per_minute}
                            format={(r: number) => (r).toFixed(1)}></StatComparison>
            <StatComparison stat={'Control wards bought per minute'} scrims={scrims.vision_wards_bought__sum__per_minute}
                            competitive={competitive.vision_wards_bought__sum__per_minute}
                            format={(r: number) => (r).toFixed(1)}></StatComparison>
            <StatComparison stat={'GD@15'} scrims={scrims.gd15__sum__per_game}
                            competitive={competitive.gd15__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'GD@25'} scrims={scrims.gd25__sum__per_game}
                            competitive={competitive.gd25__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'Solo Kills'} scrims={scrims.solo_kills__sum__per_game}
                            competitive={competitive.solo_kills__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Solo Deaths'} scrims={scrims.solo_deaths__sum__per_game}
                            competitive={competitive.solo_deaths__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Durée des victoires'} scrims={scrims.duration__avg__in_wins}
                            competitive={competitive.duration__avg__in_wins}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
            <StatComparison stat={'Durée des défaites'} scrims={scrims.duration__avg__in_losses}
                            competitive={competitive.duration__avg__in_losses}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
        </Stack> : <></>}</Stack>
}