import { api_url } from "./static_vars";

class ApiWrapper {
    public url: string;
    isLoggedIn: boolean;
    public userInfos: any;

    constructor(base_url: string) {
        this.url = base_url;
        this.isLoggedIn = false;
    }
    login(credentials: any) {
        return fetch(`${this.url}/Login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
            redirect: "follow",
        })
            .then((response) => response.json())
            .then((data) => {
                this.set_user_info(data);
                console.log(this.userInfos);
                return this.userInfos;
            })
            .catch((error) => {
                throw error;
            });
    }
    format_parameters(props: any) {
        let params_to_string = "format=json";
        Object.entries(props).forEach(([key, value]) => {
            if (value !== null && value !== undefined) params_to_string += `&${key}=${value.toString()}`;
        });
        return params_to_string;
    }
    get_request(endpoint: string, params: any) {
        const params_string = this.format_parameters(params);
        const url = `${this.url}/${endpoint}?${params_string}`;
        // console.log(this.get_user_infos());
        return fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.get_user_infos().token}`,
            },
            redirect: "follow",
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return [];
            }
        });
    }

    post_request(endpoint: string, params: any, content: any) {
        const params_string = this.format_parameters(params);
        const url = `${this.url}/${endpoint}?${params_string}`;
        // console.log(this.get_user_infos());
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.get_user_infos().token}`,
            },
            redirect: "follow",
            body: JSON.stringify(content),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return [];
            }
        });
    }

    download(endpoint: string, params: any, file_name: string) {
        const params_string = this.format_parameters(params);
        const url = `${this.url}/${endpoint}?${params_string}`;
        return fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.get_user_infos().token}`,
            },
            redirect: "follow",
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(`HTTP status code: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a URL for the blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Create a temporary <a> element to trigger the download
                const a = document.createElement("a");
                a.href = blobUrl;
                a.download = file_name; // Replace with the desired file name and extension
                a.style.display = "none";

                // Append the <a> element to the document and click it
                document.body.appendChild(a);
                a.click();

                // Remove the <a> element
                document.body.removeChild(a);

                // Clean up by revoking the blob URL
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error("Error:", error);
                // Handle errors
            });
    }
    set_user_info(user_info: any) {
        sessionStorage.setItem("user", JSON.stringify(user_info));
        this.userInfos = user_info;
    }
    get_user_infos() {
        if (!this.userInfos) {
            this.userInfos = JSON.parse(sessionStorage.getItem("user") as string);
        }
        return this.userInfos;
    }
}

// const FarsightApi = new ApiWrapper("https://staging.farsight-alteration.com");
const FarsightApi = new ApiWrapper("http://localhost:8000");
// const FarsightApi = new ApiWrapper("https://api.farsight-alteration.com");
export default FarsightApi;
