import {useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import {DraftPreps, OffSeasonShortlist, Team} from "../../../farsight-api";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {TeamSelect} from "../TeamSelect";


export default function PreparationSelector(props: { value: DraftPreps|null; onChange: any }){
    const context = useReferentialContext();
    const [options, setOptions] = useState<DraftPreps[]>([]);
    const [addPrep, setAddPrep] = useState(false);

    useEffect(() => {
        context.farsightApi.draftPreps.list({}).then(data => setOptions(data));
    }, []);

    return (
        <Stack
            direction={"row"}
            sx={{
                alignItems: "left",
                display: "flex",
            }}
            spacing={2}
        >
            <FormControl sx={{ width: "300px" }}>
                <Autocomplete
                    value={props.value}
                    onChange={props.onChange}
                    getOptionLabel={(option: any) => {
                        return option.name;
                    }}
                    options={options}
                    renderInput={(params) => <TextField {...params} label="Shortlist" />}
                    ListboxProps={{ className: "scrollable" }}
                    renderOption={(props, option, { selected }) => {
                        return <li {...props}>{option.name}</li>;
                    }}
                />
            </FormControl>
            <CreatePrep
                open={addPrep}
                close={() => {
                    setAddPrep(false);
                    context.farsightApi.draftPreps.list({}).then((data) => setOptions(data));
                }}
            />
            <Button onClick={() => setAddPrep(true)} variant={"outlined"}>
                Create Prep
            </Button>
        </Stack>
    );
}

function CreatePrep(props: { open: boolean; close: any }) {
    const context = useReferentialContext();
    const [vs, setVs] = useState<Team|null>(null);
    const [newPrep, setNewPrep] = useState({
        name: "New Shortlist",
        team: null
    } as any);

    function handleClose() {
        context.farsightApi.draftPreps.create({
            id: '0',
            draft_preps: newPrep
        }).then(new_object => {
            props.close(new_object);
        });
    }

    return (
        <Stack direction={"column"}>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>{`Create new shortlist`}</DialogTitle>
                <DialogContent sx={{ height: "500px" }}>
                    <Stack direction={"column"} sx={{ padding: "20px", width: "500px", mt: 1 }} spacing={2}>
                        <TextField
                            label="Name"
                            variant="filled"
                            fullWidth
                            value={newPrep.name}
                            onChange={(event: any) => {
                                setNewPrep({
                                    ...newPrep,
                                    name: event.target.value,
                                });
                            }}
                        />
                        <TeamSelect
                            value={vs}
                            updateValue={(team: Team|null) => {
                                setVs(team);
                                setNewPrep({
                                    ...newPrep,
                                    vs: team?.team_name
                                });
                            }}
                            updateContext={false}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close}>Cancel</Button>
                    <Button onClick={handleClose}>Create Prep</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}