import PreparationSelector from "../../../components/selectors/DraftPrep/PreparationSelector";
import {useState} from "react";
import {DraftPreps} from "../../../farsight-api";


export default function DraftPrep(){
    const [currentPrep, setCurrentPrep] = useState<null|DraftPreps>(null);


    return <PreparationSelector value={currentPrep} onChange={setCurrentPrep}/>
}