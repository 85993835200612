import {useEffect, useState} from "react";
import {Champion} from "../../../Context";
import {Stack} from "@mui/material";
import {MultipleChampionSelect} from "./ChampionSelect";

export interface DraftPatternParams{
    'b1__in': Champion[] | undefined,
    'r12__in': Champion[] | undefined,
    'b23__in': Champion[] | undefined,
    'r3__in': Champion[] | undefined,
    'r4__in': Champion[] | undefined,
    'r5__in': Champion[] | undefined,
    'b45__in': Champion[] | undefined,
    'firstBanRotation_in': Champion[] | undefined,
    'secondBanRotation_in': Champion[] | undefined
}
export default function DraftPatternSelect(props: {paramObject: DraftPatternParams, setParamObject: any}){
    const [firstBanRotation_in, setFirstBanRotation_in] = useState([] as Champion[]);
    const [secondBanRotation_in, setSecondBanRotation_in] = useState([] as Champion[]);
    const [b1__in, setB1__in] = useState([] as Champion[]);
    const [r12__in, setR12__in] = useState([] as Champion[]);
    const [b23__in, setB23__in] = useState([] as Champion[]);
    const [r3__in, setR3__in] = useState([] as Champion[]);
    const [r4__in, setR4__in] = useState([] as Champion[]);
    const [r5__in, setR5__in] = useState([] as Champion[]);
    const [b45__in, setB45__in] = useState([] as Champion[]);

    useEffect(() => {
        const no = {
            'b1__in': b1__in.length > 0 ? b1__in : undefined,
            'r12__in': r12__in.length > 0 ? r12__in : undefined,
            'b23__in': b23__in.length > 0 ? b23__in : undefined,
            'r3__in': r3__in.length > 0 ? r3__in : undefined,
            'r4__in': r4__in.length > 0 ? r4__in : undefined,
            'r5__in': r5__in.length > 0 ? r5__in : undefined,
            'b45__in': b45__in.length > 0 ? b45__in : undefined,
            'firstBanRotation_in': firstBanRotation_in,
            'secondBanRotation_in': secondBanRotation_in
        } as DraftPatternParams;
        props.setParamObject(no);

    }, [firstBanRotation_in, secondBanRotation_in, b1__in, r12__in, b23__in, r3__in, r4__in, r5__in, b45__in])

    return <Stack direction={'column'} sx={{alignItems:'center', width:'600px'}} spacing={1}>
        <MultipleChampionSelect values={firstBanRotation_in} updateValue={setFirstBanRotation_in} label={'Bans 1-3'}/>
        <Stack direction={'row'} sx={{alignItems:'space-between', justifyContent:'space-evenly', display:'flex'}}>
            <Stack direction={'column'} sx={{justifyContent:'space-evenly', display:'flex'}} spacing={1}>
                <MultipleChampionSelect values={b1__in} updateValue={setB1__in} label={'B1'}/>
                <MultipleChampionSelect values={b23__in} updateValue={setB23__in} label={'B2-3'}/>
            </Stack>
            <Stack direction={'column'} sx={{justifyContent:'space-evenly', display:'flex'}} spacing={1}>
                <MultipleChampionSelect values={r12__in} updateValue={setR12__in} label={'R1-2'}/>
                <MultipleChampionSelect values={r3__in} updateValue={setR3__in} label={'R3'}/>
            </Stack>
        </Stack>
        <MultipleChampionSelect values={secondBanRotation_in} updateValue={setSecondBanRotation_in} label={'Bans 4-5'}/>
        <Stack direction={'row'}>
            <Stack direction={'column'} sx={{justifyContent:'space-evenly', display:'flex'}} spacing={1}>
                <MultipleChampionSelect values={b45__in} updateValue={setB45__in} label={'B4-5'}/>
            </Stack>
            <Stack direction={'column'} sx={{justifyContent:'space-evenly', display:'flex'}} spacing={1}>
                <MultipleChampionSelect values={r4__in} updateValue={setR4__in}  label={'R4'}/>
                <MultipleChampionSelect values={r5__in} updateValue={setR5__in}  label={'R5'}/>
            </Stack>
    </Stack>
    </Stack>
}