import {targets, useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import ChampionsStats from "../../../components/visu_blocks/ChampionsStats";
import {Stack} from "@mui/material";
import PlayersList from "../../../components/visu_blocks/PlayersList";
import Box from "@mui/material/Box";
import {Player} from "../../../farsight-api";


export default function LeaguePlayersChampions() {
    const context = useReferentialContext();

    const [patches, setPatches] = useState(context.selectedPatches);
    const [leagues, setLeagues] = useState(context.selectedLeagues);
    const [role, setRole] = useState("BOT_LANE");
    const [players, setPlayers] = useState([] as Player[]);

    const [paramObject, setParamObject] = useState({
        ordering: "-performance",
        additional_filters : {}
    } as any);

    const setParamObjectWrap = (paramObject: any) => {
        setParamObject({
            additional_filters : {
                account__player__current_team__current_league__in : [leagues.map(l => l.league_name).toString()],
                patch__in : [patches.toString()]
            },
            role : role,
            watermark : JSON.stringify({
                additional_filters : {
                    account__player__current_team__current_league__in : [leagues.map(l => l.league_name).toString()],
                    patch__in : [patches.toString()]
                },
                role : role,
            })
        })
    }

    useEffect(() => {


        context.farsightApi.player.list(
            {additional_filters : {current_team__current_league__in : [leagues.map(l => l.league_name).toString()]}}
        ).then((result: Player[]) => setPlayers(result));
    }, [leagues]);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObjectWrap}
                target={targets.soloq}
                role={role}
                setRole={setRole}
                patches={patches}
                setPatches={setPatches}
                leagues={leagues}
                setLeagues={setLeagues}
            />
            <Stack direction={'row'} sx={{width:'100%', height:'100%'}} spacing={1}>
                <Box sx={{width:'20%', height:'100%'}}>
                    <PlayersList players={players.filter(p => p.role === role)}/>
                </Box>
                {paramObject.additional_filters.account__player__current_team__current_league__in && leagues.length > 0 ? <ChampionsStats paramObject={paramObject} target={targets.soloq}/> : <></>}
            </Stack>
        </WindowContainer>
    );
}