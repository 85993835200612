import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { createTheme } from "@mui/material";
import FarsightApi from "./ApiWrapper";

export let leagues = [
    "CBLOL",
    "CBLOL Academy",
    "Elite Series",
    "Esports Balkan League",
    "European Masters",
    "GLWL",
    "Greek Legends League",
    "LCK",
    "LCK Academy Series",
    "LCK CL",
    "LCL",
    "LCO",
    "LCS",
    "LCS Proving Grounds",
    "LDL",
    "LEC",
    "LFL",
    "LFL Division 2",
    "LJL",
    "LJL Academy League",
    "LLA",
    "LPL",
    "LPLOL",
    "LVP SuperLiga",
    "LVP SuperLiga 2nd Division",
    "Mastercard Nexus Tour 2022",
    "MSI",
    "NA Academy League",
    "NLC",
    "PCS",
    "PG Nationals",
    "PG Proving Grounds",
    "Prime League 1st Division",
    "Prime League 2nd Division",
    "TCL",
    "Turkey Academy League",
    "Ultraliga",
    "Ultraliga 2nd Division",
    "VCS",
    "Worlds Main Event",
    "Worlds Play In",
    "Demacia Cup",
];

export let api_url = "https://api.farsight-alteration.com/";
//export let api_url = "http://localhost:8000/";

export let patches = [
    "12.1",
    "12.2",
    "12.3",
    "12.4",
    "12.5",
    "12.6",
    "12.7",
    "12.8",
    "12.9",
    "12.10",
    "12.11",
    "12.12",
    "12.13",
    "12.14",
    "12.15",
    "12.16",
    "12.17",
    "12.18",
    "12.19",
    "12.20",
    "12.21",
    "12.22",
    "12.23",
    "13.1",
    "13.1b",
    "13.3",
    "13.4",
    "13.5",
    "13.6",
];

export class GlobalContext {
    state: any;
    constructor() {
        this.state = {
            patches: patches,
            leagues: [],
            ddragon_champions: [],
            ddragon_items: [],
            competitive_players: [],
            tracked_players: [],
            scrim_players: [],
            leaguepedia_teams: [],
            api_url: "https://api.farsight-alteration.com/",
            version: "13.1.1",
            current_champion: "Aphelios",
            current_champions: [],
            current_leagues: [],
            current_player: {
                player: "Ragner",
                current_team_id: "Team GO",
                role: "TOP_LANE",
            },
            current_account: "",
            current_patch: "13.1",
            current_season: 13,
            current_soloQ_from: dayjs().subtract(14, "day"),
            current_soloQ_until: dayjs().add(1, "day"),
            current_event_from: "",
            current_event_until: "",
            current_match: "",
            current_role: "BOT_LANE",
            current_scrim: "",
            current_server: ["EUW", "KR"],
            current_blue_side: "T1",
            current_red_side: "DRX",
            current_competitive_from: dayjs(new Date(2023, 1, 1)),
            current_competitive_until: dayjs(),
            current_competitive_scout: "Karmine Corp",
            token: {},
            metrics: [],
            current_metrics: [],
            loaded: false,
            current_year: 2023,
            current_split: "Summer",
            current_phase: ["Regular Season", "Playoffs", "Tiebreakers", "Knockout", "Group", "Group Stage"],
            agencies: [],
            shortlists: [],
        };
    }
    fetchContext() {
        this.fetchTrackedPlayers();
        this.fetchLeagues();
        this.fetchLeaguepediaTeams();
        this.fetchCompetitivePlayers();
        this.fetchVersion();
        this.fetchPatches();
        this.fetchMetrics();
        this.fetchAgencies();
        this.fetchShortlists();
        this.state.loaded = true;
    }
    fetchVersion() {
        fetch("https://ddragon.leagueoflegends.com/api/versions.json")
            .then((response) => response.json())
            .then((data) => (this.state.version = data[0]))
            .then(() => {
                this.fetchDdragonChampions();
                return true;
            })
            .then(() => {
                this.fetchDdragonItems();
                return true;
            });
    }
    fetchTrackedPlayers() {
        FarsightApi.get_request("TrackedPlayers", []).then((data) => (this.state.tracked_players = data));
    }
    fetchLeaguepediaTeams() {
        FarsightApi.get_request("Teams", []).then((data) => (this.state.leaguepedia_teams = data));
    }
    fetchLeagues() {
        FarsightApi.get_request("Leagues", []).then((data) => (this.state.leagues = data));
    }
    fetchCompetitivePlayers() {
        FarsightApi.get_request("Players", []).then((data) => (this.state.competitive_players = data));
    }
    fetchAgencies() {
        FarsightApi.get_request("Agencies", []).then((data) => (this.state.agencies = data));
    }
    fetchDdragonChampions() {
        fetch("https://ddragon.leagueoflegends.com/cdn/" + this.state.version + "/data/en_US/champion.json")
            .then((response) => response.json())
            .then((data) => {
                this.state.ddragon_champions = data.data;
            });
    }

    fetchDdragonItems() {
        fetch("https://ddragon.leagueoflegends.com/cdn/" + this.state.version + "/data/en_US/item.json")
            .then((response) => response.json())
            .then((data) => {
                this.state.ddragon_items = data.data;
            });
    }
    fetchPatches() {
        FarsightApi.get_request("Patches", [])
            .then((data) => (this.state.patches = data))
            .then(() => (this.state.current_patch = this.state.patches[1]));
    }
    fetchMetrics() {
        FarsightApi.get_request("Metrics", []).then((data) => {
            this.state.metrics = data;
            this.state.current_metrics = data.filter((metric: any) => metric.group === "Generic");
        });
    }
    fetchShortlists() {
        return FarsightApi.get_request("Shortlists", []).then((data) => {
            console.log(data);
            this.state.shortlists = data;
        });
    }
}
export const base_gc = new GlobalContext();
const global_context = React.createContext<GlobalContext>(base_gc);
export default global_context;

export const win_color = "#1C2633";
export const loss_color = "#2B1818";

export const scrollable = {
    "&::-webkit-scrollbar": {
        width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
        background: "#e1e1e1e",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
};
