import React, {useEffect, useState} from "react";
import { Grid, Grow, Stack } from "@mui/material";
import RoleSelect from "../../../../components/selectors/RoleSelect";
import { Champion, targets, useReferentialContext } from "../../../../Context";
import FilterSet from "../../../../components/selectors/Filterset";
import { GameMetrics } from "../../../../farsight-api";
import MultipleMetricsSelect from "../../../../components/selectors/Shared/MetricsSelect";
import TableDisplayGrid, { rowStyle } from "../../../../components/datagrids/shared/TableDisplayGrid";
import OverviewDetailed from "../../../../components/selectors/OverviewDetailed";
import WindowContainer from "../../../../components/layout/WindowContainer";
import Box from "@mui/material/Box";

export default function PlayersTierlists(props: { target: targets }) {
    const [detailed, setDetailed] = useState(false);

    const context = useReferentialContext();
    const [year, setYear] = useState(2024);
    const [league, setLeague] = useState(context.leaguesReferential.filter(l => l.league_name === 'LEC')[0] ? context.leaguesReferential.filter(l => l.league_name === 'LEC')[0] : null);
    const [champions, setChampions] = useState([] as Champion[]);
    const [split, setSplit] = useState(null as string | null);
    const [phases, setPhases] = useState([] as string[]);

    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [role, setRole] = useState<string>("MID_LANE");
    const [metrics, setMetrics] = useState<GameMetrics[]>(context.selectedMetrics);
    function encapsulateParamObject(newParamObject: any){
        console.log('New param object')
        const np = {
            ...newParamObject,
            groupby: "player,team1",
            metrics: detailed ? metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate"].toString(),
            ordering: "performance",
            format: "json",
        };
        setParamObject(np)
    }

    function encapsulateDetailed(ndetailed: boolean){
        setParamObject({
            ...paramObject, metrics: ndetailed ? metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate"].toString()
        })
        setDetailed(ndetailed)
    }


    useEffect(() => {

        setParamObject({
            ...paramObject, metrics: detailed ? metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate"].toString()
        })
    }, [metrics]);


    const [paramObject, setParamObject] = useState({
        groupby: "player,team1",
        metrics: detailed ? metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate"].toString(),
        ordering: "performance",
        format: "json",
        watermark: 'initial',
        league:'LCK',
        additional_filters: {date__year:2024}
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={encapsulateParamObject}
                target={targets.competitive}
                league={league}
                setLeague={setLeague}
                year={year}
                setYear={setYear}
                split={split}
                setSplit={setSplit}
                phases={phases}
                setPhases={setPhases}
                champions={champions}
                setChampions={setChampions}
                updateContext={false}
            />
            <Stack sx={{height:'60px',width: "100%" }}>
                <Grow in={true} style={{transformOrigin: "0 0 0"}} {...{timeout: 2000}}>
                    <Stack
                        direction={"row"}
                        sx={{
                            height: "50px",
                            justifyContent: "left",
                            width: "100%",
                        }}
                        spacing={2}
                    >
                        <OverviewDetailed value={detailed} onChange={setDetailed}/>
                        {detailed ? <Grow in={detailed} style={{transformOrigin: "0 0 0"}} {...{timeout: 2000}}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    height: "50px",
                                    justifyContent: "left",
                                    width: "100%",
                                }}
                                spacing={2}
                            >
                                <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target}
                                                       updateContext={false}/>
                                <RoleSelect value={role} onChange={setRole} label={`Role`}/>
                            </Stack>
                        </Grow> : <></>}
                    </Stack>
                </Grow>
            </Stack>
                {!detailed ? <OverviewTierlists paramObject={paramObject} api={api}/>
                    :
                    <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate({...paramObject, role: role})}
                        rowStyle={rowStyle.none}
                        disp_columns={["player", "team1"]}
                        sortBy={"performance"}
                        paramObject={{...paramObject, watermark: paramObject.watermark + role}}
                        metrics={metrics}
                    />}
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any, api: any}) {
    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() => props.api.aggregate({...props.paramObject, role: "TOP_LANE"})}
                rowStyle={rowStyle.none}
                disp_columns={["player", "team1", "games", "winrate", "performance", "relative_performance"]}
                sortBy={"performance"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() => props.api.aggregate({...props.paramObject, role: "JUNGLE"})}
                rowStyle={rowStyle.none}
                disp_columns={["player", "team1", "games", "winrate", "performance", "relative_performance"]}
                sortBy={"performance"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() => props.api.aggregate({...props.paramObject, role: "MID_LANE"})}
                rowStyle={rowStyle.none}
                disp_columns={["player", "team1", "games", "winrate", "performance", "relative_performance"]}
                sortBy={"performance"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() => props.api.aggregate({...props.paramObject, role: "BOT_LANE"})}
                rowStyle={rowStyle.none}
                disp_columns={["player", "team1", "games", "winrate", "performance", "relative_performance"]}
                sortBy={"performance"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() => props.api.aggregate({...props.paramObject, role: "UTILITY"})}
                rowStyle={rowStyle.none}
                disp_columns={["player", "team1", "games", "winrate", "performance", "relative_performance"]}
                sortBy={"performance"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}