import DraftPatternSelect, {DraftPatternParams} from "../../../components/selectors/Shared/DraftPatternSelect";
import {useState} from "react";
import {Champion} from "../../../Context";


export default function CheckDraftSituationsCompetitive(){
    const [paramObject, setParamObject] = useState({
        'b1__in': [],
        'r12__in': [],
        'b23__in': [],
        'r3__in': [],
        'r4__in': [],
        'r5__in': [],
        'b45__in':[],
        'firstBanRotation_in': [],
        'secondBanRotation_in': []
    } as DraftPatternParams);
    return <DraftPatternSelect paramObject={paramObject} setParamObject={setParamObject}/>
}