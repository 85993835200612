import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import {
    CompetitiveGameSummariesApi,
    Configuration,
    GameMetricsApi,
    GameMetrics,
    LeagueApi,
    League,
    ReferentialsApi,
    ScrimGameSummariesApi,
    SoloqGameSummariesApi,
    TeamApi,
    Team,
    GamesDataApi,
    ScrimsDataApi,
    PlayerApi,
    Login,
    AgenciesApi,
    CompetitiveChampionRatingsApi,
    ComputesApi,
    DetailedApi,
    DownloadsApi,
    MapsApi,
    OffseasonNotesApi,
    OffSeasonShortlistApi,
    OffSeasonShortlistItemApi,
    ScrimFeedbackTicketsApi,
    ScrimSessionApi,
    SoloQAccountsApi,
    SoloQChampionRatingApi,
    WeeklySoloQChampionRatingApi,
    Player, ModelsApi, BASE_PATH, OffSeasonShortlist, CompetitiveTeamfight, CompetitiveParticipantTeamfight,
} from "./farsight-api";
import {ChampionsQueueSummariesApi} from "./farsight-api/apis/ChampionsQueueSummariesApi";
import {DraftPrepsApi} from "./farsight-api/apis/DraftPrepsApi";
import {CompetitiveTeamfightApi} from "./farsight-api/apis/CompetitiveTeamfightApi";
import {CompetitiveParticipantTeamfightApi} from "./farsight-api/apis/CompetitiveParticipantTeamfightApi";

interface AppContextProps {
    user: Login;
    farsightApi: FarsightApi;
    current_tab: number;
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>;

    metricsReferential: GameMetrics[];
    selectedMetrics: GameMetrics[];
    setSelectedMetrics: React.Dispatch<React.SetStateAction<GameMetrics[]>>;

    leaguesReferential: League[];
    selectedLeagues: League[];
    setSelectedLeagues: React.Dispatch<React.SetStateAction<League[]>>;

    patchesReferential: string[];
    selectedPatches: string[];
    setSelectedPatches: React.Dispatch<React.SetStateAction<string[]>>;

    championsReferential: Champion[];
    selectedChampion: Champion | null;
    setSelectedChampion: React.Dispatch<React.SetStateAction<Champion | null>>;

    itemsReferential: Item[];

    teamsReferential: Team[];
    selectedTeam: Team | null;
    setSelectedTeam: React.Dispatch<React.SetStateAction<Team | null>>;

    playersReferential: Player[];
    selectedPlayer: Player | null;
    setSelectedPlayer: React.Dispatch<React.SetStateAction<Player | null>>;


    scrimAccountsReferential: string[],
    selectedScrimAccounts: string | null,
    setSelectedScrimAccounts: React.Dispatch<React.SetStateAction<string | null>>;

    ddragon_champions: { [id: string]: Champion };
    ddragon_items: { [id: string]: Item };
    currentTarget: targets;
    setCurrentTarget: React.Dispatch<React.SetStateAction<targets>>;
    selectedRole: string;
    setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
}

export interface Champion {
    id: string;
    name: string;
    tags: string[];
}

export interface Item {
    id: string;
    name: string;
    tags: string[];
    gold: {
        total: number;
        purchasable: boolean;
    };
    description: string;
    maps: any;
}

export enum targets {
    competitive = "competitive",
    soloq = "soloq",
    scrims = "scrims",
    championsq = "championsq",
}

export const target_to_data_api: { [target: string]: any } = {
    competitive: new GamesDataApi(),
    scrims: new ScrimsDataApi(),
};

const AppContext = createContext<AppContextProps | undefined>(undefined);

export default function ReferentialContext(props: { user: Login; children: ReactNode, initTab: number }) {
    const { user } = props;
    const farsightApi = new FarsightApi(user);
    const [current_tab, setCurrentTab] = useState(props.initTab);
    const [ddragon_champions, setDdragonChampions] = useState<{
        [id: string]: Champion;
    }>({});
    const [ddragon_items, setDdragonItems] = useState<{
        [id: string]: Item;
    }>({});
    const [metricsReferential, setMetricsReferential] = useState<GameMetrics[]>([]);
    const [leaguesReferential, setLeaguesReferential] = useState<League[]>([]);
    const [patchesReferential, setPatchesReferential] = useState<string[]>([]);
    const [championsReferential, setChampionsReferential] = useState<Champion[]>([]);
    const [itemsReferential, setItemsReferential] = useState<Item[]>([]);
    const [teamsReferential, setTeamsReferential] = useState<Team[]>([]);
    const [playersReferential, setPlayersReferential] = useState<Player[]>([]);
    const [scrimAccountsReferential, setScrimAccountsReferentials] = useState<string[]>([]);

    const [selectedMetrics, setSelectedMetrics] = useState<GameMetrics[]>([]);
    const [selectedLeagues, setSelectedLeagues] = useState<League[]>([]);
    const [selectedPatches, setSelectedPatches] = useState<string[]>([]);
    const [selectedChampion, setSelectedChampion] = useState<Champion | null>(null);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
    const [selectedScrimAccounts, setSelectedScrimAccounts] = useState<string | null>(null);
    const [currentTarget, setCurrentTarget] = useState(targets.competitive);
    const [selectedRole, setSelectedRole] = useState('BOT_LANE');

    async function fetchReferential<Option>(
        setReferentialState: React.Dispatch<React.SetStateAction<Option[]>>,
        setInitialState: React.Dispatch<React.SetStateAction<Option[] >>,
        initialStateFilter: (arg0: Option, arg1: number) => boolean,
        request: any,
    ) {
        return await request.then((result: Option[]) => {
            setReferentialState(result);
            setInitialState(result.filter(initialStateFilter));
            return result;
        });
    }

    useEffect(() => {

        fetchReferential(
            setMetricsReferential,
            setSelectedMetrics,
            (metric: GameMetrics) => metric.group === "Generic",
            farsightApi.gameMetrics.list({
                ordering: ["group", "index"],
                additional_filters: { label__gte: 0 },
            }),
        );
        fetchReferential(setLeaguesReferential, setSelectedLeagues, (league: League) => false, farsightApi.league.list({ ordering: ["group__index"] }));
        farsightApi.player.list({ ordering: ["current_team"] }).then((result) => {
            setPlayersReferential(result);
            setSelectedPlayer(result.filter((player) => player.player === "Faker")[0]);
        });
        farsightApi.team.list({ ordering: ["current_league"], additional_filters:{current_league__isnull:false} }).then((result) => {
            setTeamsReferential(result);
            setSelectedTeam(result.filter((team) => team.team_name === "T1")[0]);
        });

        fetchReferential(setPatchesReferential, setSelectedPatches, (patch: string, index: number) => index < 1, farsightApi.referentials.patches()).then((result) => {
            fetch(`https://ddragon.leagueoflegends.com/cdn/${result[1]}.1/data/en_US/champion.json`)
                .then((response) => response.json())
                .then((data) => {
                    setDdragonChampions(data.data);
                    const as_list = Object.entries(data.data).map(([key, value]) => value as Champion);
                    setChampionsReferential(as_list.sort((a, b) => b.tags[0].localeCompare(a.tags[0])));
                });

            fetch(`https://ddragon.leagueoflegends.com/cdn/${result[1]}.1/data/en_US/item.json`)
                .then((response) => response.json())
                .then((data) => {
                    setDdragonItems(data.data);
                    const as_list = Object.entries(data.data)
                        .filter(([key, value]) => !data.data[key].description.includes("ornn") && data.data[key].maps["11"])
                        .map(([key, value]) => value as Item);
                    console.log(as_list)
                    setItemsReferential(as_list.sort((a, b) => (b.tags[0] ? b.tags[0] : '').localeCompare(a.tags[0])));
                });
        });
        farsightApi.scrimGameSummaries.field_values({field: 'player', ordering: ['player']}).then(r => setScrimAccountsReferentials(r));
    }, []);

    return (
        <AppContext.Provider
            value={{
                user,
                farsightApi,
                current_tab,
                setCurrentTab,
                metricsReferential,
                selectedMetrics,
                setSelectedMetrics,
                leaguesReferential,
                selectedLeagues,
                setSelectedLeagues,
                patchesReferential,
                selectedPatches,
                setSelectedPatches,
                championsReferential,
                selectedChampion,
                setSelectedChampion,
                itemsReferential,
                teamsReferential,
                setSelectedTeam,
                selectedTeam,
                playersReferential,
                setSelectedPlayer,
                selectedPlayer,
                scrimAccountsReferential,
                selectedScrimAccounts,
                setSelectedScrimAccounts,
                ddragon_champions,
                ddragon_items,
                currentTarget,
                setCurrentTarget,
                selectedRole,
                setSelectedRole
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export const useReferentialContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};

class FarsightApi {
    user_infos: Login;
    agencies: AgenciesApi;
    competitiveChampionsRatings: CompetitiveChampionRatingsApi;
    competitiveGameSummaries: CompetitiveGameSummariesApi;
    computes: ComputesApi;
    detailed: DetailedApi;
    download: DownloadsApi;
    gameMetrics: GameMetricsApi;
    gamesData: GamesDataApi;
    league: LeagueApi;
    maps: MapsApi;
    offSeasonNotes: OffseasonNotesApi;
    offSeasonShortlist: OffSeasonShortlistApi;
    offSeasonShortlistItem: OffSeasonShortlistItemApi;
    player: PlayerApi;
    referentials: ReferentialsApi;
    scrimFeedbackTickets: ScrimFeedbackTicketsApi;
    scrimGameSummaries: ScrimGameSummariesApi;
    scrimsData: ScrimsDataApi;
    scrimSession: ScrimSessionApi;
    soloqAccount: SoloQAccountsApi;
    soloqChampionsRating: SoloQChampionRatingApi;
    soloqGameSummaries: SoloqGameSummariesApi;
    team: TeamApi;
    weeklySoloQChampionRating: WeeklySoloQChampionRatingApi;
    models: ModelsApi;
    championsqGameSummaries: ChampionsQueueSummariesApi;
    url: string;
    draftPreps: DraftPrepsApi;
    competitiveTeamfights: CompetitiveTeamfightApi;
    competitiveParticipantTeamfights: CompetitiveParticipantTeamfightApi;

    constructor(user_infos: Login) {
        const api_config = new Configuration({
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${user_infos.token}`,
            },
            // basePath: 'http://localhost:8000'
            basePath: 'https://api.farsight-alteration.com'
        });
        this.url = api_config.basePath;

        this.user_infos = user_infos;
        this.agencies = new AgenciesApi(api_config);
        this.competitiveChampionsRatings = new CompetitiveChampionRatingsApi(api_config);
        this.competitiveGameSummaries = new CompetitiveGameSummariesApi(api_config);
        this.computes = new ComputesApi(api_config);
        this.detailed = new DetailedApi(api_config);
        this.download = new DownloadsApi(api_config);
        this.gameMetrics = new GameMetricsApi(api_config);
        this.gamesData = new GamesDataApi(api_config);
        this.league = new LeagueApi(api_config);
        this.maps = new MapsApi(api_config);
        this.offSeasonNotes = new OffseasonNotesApi(api_config);
        this.offSeasonShortlist = new OffSeasonShortlistApi(api_config);
        this.offSeasonShortlistItem = new OffSeasonShortlistItemApi(api_config);
        this.player = new PlayerApi(api_config);
        this.referentials = new ReferentialsApi(api_config);
        this.scrimFeedbackTickets = new ScrimFeedbackTicketsApi(api_config);
        this.scrimGameSummaries = new ScrimGameSummariesApi(api_config);
        this.scrimsData = new ScrimsDataApi(api_config);
        this.scrimSession = new ScrimSessionApi(api_config);
        this.soloqAccount = new SoloQAccountsApi(api_config);
        this.soloqChampionsRating = new SoloQChampionRatingApi(api_config);
        this.soloqGameSummaries = new SoloqGameSummariesApi(api_config);
        this.team = new TeamApi(api_config);
        this.weeklySoloQChampionRating = new WeeklySoloQChampionRatingApi(api_config);
        this.models = new ModelsApi(api_config);
        this.championsqGameSummaries = new ChampionsQueueSummariesApi(api_config);
        this.draftPreps = new DraftPrepsApi(api_config);
        this.competitiveTeamfights = new CompetitiveTeamfightApi(api_config);
        this.competitiveParticipantTeamfights = new CompetitiveParticipantTeamfightApi(api_config);
    }

    getSummariesApi(target: targets) {
        if (target === targets.competitive) return this.competitiveGameSummaries;
        if (target === targets.soloq) return this.soloqGameSummaries;
        if (target === targets.scrims) return this.scrimGameSummaries;
        if (target === targets.championsq) return this.championsqGameSummaries;
        return this.competitiveGameSummaries;
    }

    getDataApi(target: targets) {
        if (target === targets.competitive) return this.gamesData;
        if (target === targets.scrims) return this.scrimsData;
        return this.gamesData;
    }

    getRatingsApi(target: targets){
        if (target === targets.competitive) return this.competitiveChampionsRatings;
        if (target === targets.soloq) return this.soloqChampionsRating;
        return this.competitiveChampionsRatings;
    }

    format_parameters(props: any) {
        let params_to_string = "format=json";
        Object.entries(props).forEach(([key, value]) => {
            if (value !== null && value !== undefined) params_to_string += `&${key}=${value.toString()}`;
        });
        return params_to_string;
    }

    async download_file(endpoint: string, params: any, file_name: string) {
        const params_string = this.format_parameters(params);
        const url = `${this.url}/${endpoint}?${params_string}`;
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${this.user_infos.token}`,
                },
                redirect: "follow",
            });
            if (response.status !== 200) {
                throw new Error(`HTTP status code: ${response.status}`);
            }
            const blob = await response.blob();
            // Create a URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = file_name; // Replace with the desired file name and extension
            a.style.display = "none";

            // Append the <a> element to the document and click it
            document.body.appendChild(a);
            a.click();

            // Remove the <a> element
            document.body.removeChild(a);

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error:", error);
        }
    }
}
