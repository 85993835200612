import React, { useState } from "react";
import dayjs from "dayjs";
import SoloQMonitoringChart from "../../../components/Charts/SoloQMonitoringChart";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../../../components/tabs_switch/tabs_panel";
import Tabs from "@mui/material/Tabs";
import GamesPerDay from "../../../components/Charts/GamesPerDay";
import {targets, useReferentialContext} from "../../../Context";
import FilterSet from "../../../components/selectors/Filterset";
import WindowContainer from "../../../components/layout/WindowContainer";

export default function SoloQMonitoring() {
    const context = useReferentialContext();

    const [from, setFrom] = useState(dayjs().subtract(14, "day"));
    const [until, setUntil] = useState(dayjs().add(1,"day"));
    const [player, setPlayer] = useState(context.selectedPlayer);
    const [tab, setTab] = useState(0);

    const [paramObject, setParamObject] = useState({
        ordering: "-performance",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} player={player} setPlayer={setPlayer} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil}/>
            <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} sx={{ height: "50px" }}>
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Time Slots" {...a11yProps(1)} />
                <Tab label="Games per day" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tab} index={1}>
                {player ? <SoloQMonitoringChart paramObject={paramObject} player={player}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={2}>
                {player ? <GamesPerDay paramObject={paramObject} player={player} from={from} until={until} /> : <></>}
            </TabPanel>
        </WindowContainer>
    );
}
