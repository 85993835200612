import dayjs, { Dayjs } from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import global_context, { api_url, loss_color, win_color } from "../../../static_vars";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { Box, Fade, keyframes, lighten, Link, Tooltip, Typography } from "@mui/material";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import FarsightApi from "../../../ApiWrapper";
import Button from "@mui/material/Button";
import AdaptablePageSizeGrid from "../AdaptablePageSizeGrid";
import {targets, useReferentialContext} from "../../../Context";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

function formatOutcome(result: number) {
    if (result) {
        return "Win";
    }
    return "Loss";
}

export default function TeamHistory(props: { from: number; until: number; team: string; target: string }) {
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const { team, from, until } = props;
    const api = useReferentialContext().farsightApi.detailed;
    const download = useReferentialContext().farsightApi;
    useEffect(() => {
        setLoading(true);
        setRows([]);
        api.team_history({...props})
            .then((data) => setRows(data))
            .then(() => setLoading(false));
    }, [team, from, until]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                "& .border-right": {
                    borderRight: "solid grey",
                    borderWidth: "2px",
                },
            }}
        >
            <AdaptablePageSizeGrid
                rowHeight={50}
                getRowId={(row: any) => rows.indexOf(row)}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "date", sort: "desc" }],
                    },
                }}
                sx={{
                    width: "100%",
                    "& .super-app-theme--Win": {
                        backgroundColor: win_color,
                        opacity: 0.8,
                        "&:hover": {
                            backgroundColor: lighten(win_color, 0.1),
                        },
                        "&.Mui-selected": {
                            backgroundColor: lighten(win_color, 0.1),
                            "&:hover": {
                                backgroundColor: lighten(win_color, 0.1),
                            },
                        },
                    },
                    "& .super-app-theme--Loss": {
                        backgroundColor: loss_color,
                        opacity: 0.8,
                        "&:hover": {
                            backgroundColor: lighten(loss_color, 0.1),
                        },
                        "&.Mui-selected": {
                            backgroundColor: lighten(loss_color, 0.1),
                            "&:hover": {
                                backgroundColor: lighten(loss_color, 0.1),
                            },
                        },
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "0.2em",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                        background: "#e1e1e1e",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
                autoPageSize
                columns={[
                    ColumnTypes.date("date", "Date"),
                    ColumnTypes.team("team2", "Vs."),
                    ColumnTypes.short_int("patch", "P."),
                    ColumnTypes.short_int("side", "S."),
                    ColumnTypes.duration("duration", "D."),
                    ColumnTypes.win("win", "R."),
                    ColumnTypes.champion("champion_" + "TOP_LANE", "C."),
                    ColumnTypes.champion("matchup_" + "TOP_LANE", "M."),
                    {
                        headerName: "KDA",
                        field: "kda" + "TOP_LANE",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => (
                            <Fade
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{
                                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                }}
                            >
                                <Box>{params.row["kills_" + "TOP_LANE"] + " / " + params.row["deaths_" + "TOP_LANE"] + " / " + params.row["assists_" + "TOP_LANE"]}</Box>
                            </Fade>
                        ),
                    },
                    ColumnTypes.rating("performance_TOP_LANE", "P."),
                    ColumnTypes.champion( "champion_" + "JUNGLE", "C."),
                    ColumnTypes.champion( "matchup_" + "JUNGLE", "M."),
                    {
                        headerName: "KDA",
                        field: "kda" + "JUNGLE",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => (
                            <Fade
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{
                                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                }}
                            >
                                <Box>{params.row["kills_" + "JUNGLE"] + " / " + params.row["deaths_" + "JUNGLE"] + " / " + params.row["assists_" + "JUNGLE"]}</Box>
                            </Fade>
                        ),
                    },
                    ColumnTypes.rating("performance_JUNGLE", "P."),
                    ColumnTypes.champion("champion_" + "MID_LANE", "C."),
                    ColumnTypes.champion("matchup_" + "MID_LANE", "M."),
                    {
                        headerName: "KDA",
                        field: "kda" + "MID_LANE",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => (
                            <Fade
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{
                                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                }}
                            >
                                <Box>{params.row["kills_" + "MID_LANE"] + " / " + params.row["deaths_" + "MID_LANE"] + " / " + params.row["assists_" + "MID_LANE"]}</Box>
                            </Fade>
                        ),
                    },
                    ColumnTypes.rating("performance_MID_LANE", "P."),
                    ColumnTypes.champion("champion_" + "BOT_LANE", "C."),
                    ColumnTypes.champion("matchup_" + "BOT_LANE", "M."),
                    {
                        headerName: "KDA",
                        field: "kda" + "BOT_LANE",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => (
                            <Fade
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{
                                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                }}
                            >
                                <Box>{params.row["kills_" + "BOT_LANE"] + " / " + params.row["deaths_" + "BOT_LANE"] + " / " + params.row["assists_" + "BOT_LANE"]}</Box>
                            </Fade>
                        ),
                    },
                    ColumnTypes.rating("performance_BOT_LANE", "P."),
                    ColumnTypes.champion("champion_" + "UTILITY", "C."),
                    ColumnTypes.champion("matchup_" + "UTILITY", "M."),
                    {
                        headerName: "KDA",
                        field: "kda" + "UTILITY",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => (
                            <Fade
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{
                                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                }}
                            >
                                <Box>{params.row["kills_" + "UTILITY"] + " / " + params.row["deaths_" + "UTILITY"] + " / " + params.row["assists_" + "UTILITY"]}</Box>
                            </Fade>
                        ),
                    },
                    ColumnTypes.rating("performance_UTILITY", "P."),
                    ColumnTypes.vod("vodGameStart", "VOD"),
                    {
                        headerName: "ROFL",
                        field: "game_id",
                        minWidth: 80,
                        align: "center",
                        flex: 1,
                        headerAlign: "center",
                        renderCell: (params: any) => {
                            if (params.row.replayFile)
                                return (
                                    <Fade
                                        in={true}
                                        style={{ transformOrigin: "0 0 0" }}
                                        {...{
                                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                        }}
                                    >
                                        <Tooltip title={"Click to download replay"} followCursor={true}>
                                            <Button
                                                variant={"outlined"}
                                                onClick={() => download.download_file('Downloads/GameReplay',
                                                    {game_id:params.row.game_id, target: props.target},
                                                    `${dayjs(params.row.date).format('MMM DD')} - ${params.row.team1} vs ${params.row.team2}.rofl`)}
                                            >{`Replay`}</Button>
                                        </Tooltip>
                                    </Fade>
                                );
                            return <></>;
                        },
                    },
                ]}
                rows={rows}
                hideFooter={false}
                loading={loading}
                pagination
                getRowClassName={(params: any) => `super-app-theme--${formatOutcome(params.row.win)}`}
                // disableSelectionOnClick={true}
            />
        </Box>
    );
}
