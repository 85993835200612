import {targets, useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import {Team} from "../../../farsight-api";
import dayjs, {Dayjs} from "dayjs";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {Grid, Stack, Switch, Typography} from "@mui/material";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import {WardMapDraws} from "./7_MacroScouting/WardMaps";
import MapDrawer from "../../../components/maps/map_drawer";
import HeatMapDrawer from "../../../components/maps/heatmap_drawer";

export default function PositionHeatmap(props: {target : targets}) {
    const [selectedGames, setSelectedGames] = useState([] as string[]);

    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [roles, setRoles] = useState<string[]>(["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"]);
    const [ingameInterval, setIngameInterval] = useState([0,600]);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGames(gameSelected.map(g => g.game_id));
    };

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                roles={roles}
                setRoles={setRoles}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                target={props.target}
            />

            <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
                spacing={1}
            >
                {paramObject.team && team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={true} side_select={true} target={props.target} single={false} />
                    : <></>
                }
                {paramObject.team && team ?
                    <PositionMapDraws
                            team={team}
                            game_ids={selectedGames}
                            target={props.target}
                            roles={roles}
                            ingameInterval={ingameInterval}
                    /> : <></>}
            </Stack>
        </WindowContainer>
    );
}

export function PositionMapDraws(props: {game_ids: string[],
    roles: string[],
    team: Team,
    target: targets,
    ingameInterval: number[],
}) {
    const api = useReferentialContext().farsightApi.competitiveGameSummaries
    const [data, setData] = useState<any[]>([]);
    const [disp, setDisp] = useState<boolean>(false);
    useEffect(() => {
        setData([]);
        api.list({
            additional_filters: {game_id__in:[props.game_ids.toString()], role__in:[props.roles.toString()]},
            team1: props.team.team_name
        }).then((d) => {
            console.log(props.game_ids)
            console.log(d);
            const points = d.map(s => s.position_history ? s.position_history.filter((p: any, index: number) => props.ingameInterval[0] < index &&  index < props.ingameInterval[1] ) : []).flat(1);
            console.log(points);
            setData(points.map(p => {
                return {
                    position: p
                }
            }));
        })
    }, [props.game_ids, props.roles, props.team, props.target, props.ingameInterval]);
    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&::-webkit-scrollbar": {
                    width: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#e1e1e1e",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                },
            }}
        >
            <Switch value={disp} onChange={() => setDisp(!disp)}></Switch>
            {disp? <HeatMapDrawer points={data} radius={10} max={data.length / 1000}/> : <></>}
            {<Typography>{data.length / 1000}</Typography>}
        </Grid>
    );
}
