import React, {useEffect, useState} from "react";
import {Grow, Stack} from "@mui/material";
import BestBlindCompetitiveGrid from "../../../../components/datagrids/Competitive/BestBlindsGrid";
import {Champion, targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {MultipleChampionSelect} from "../../../../components/selectors/Shared/ChampionSelect";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";

export default function BestBlindsCompetitive() {
    const context = useReferentialContext();
    const [paramObject, setParamObject] = useState({} as any);
    const [patches, setPatches] = useState(context.selectedPatches);
    const [leagues, setLeagues] = useState(context.selectedLeagues);
    const [champions, setChampions] = useState([] as Champion[]);
    const [role, setRole] = useState('TOP_LANE');
    const [data, setData] = useState([] as any[]);
    const api = context.farsightApi.computes;
    const query_params = {
        ...paramObject,
        champions_to_beat: [champions.map(c => c.id).toString()],
        target: 'competitive',
        watermark: JSON.stringify(paramObject) + champions.map(c => c.id).toString()
    }
    useEffect(() => {
            api.meta_breakers(query_params).then(r => setData(r))
        },
        [query_params.watermark])



    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive}
            patches={patches} setPatches={setPatches} leagues={leagues} setLeagues={setLeagues} role={role} setRole={setRole}/>
            <MultipleChampionSelect values={champions} updateValue={setChampions}/>
            <BestBlindCompetitiveGrid champions_to_beat={champions} role_to_beat={role} data={data}/>

        </WindowContainer>
    );
}
