import React, { useState } from "react";
import DraftingMetagameBlock from "../../../../components/visu_blocks/DraftingMetagame";
import FilterSet from "../../../../components/selectors/Filterset";
import { League } from "../../../../farsight-api";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {Grid, Grow} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../../components/tabs_switch/tabs_panel";
import {DraftsBlock} from "../../2_Scouting/8_DraftScouting/TeamDrafts";

export default function DraftingMetagameCompetitive() {
    const context = useReferentialContext();

    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);
    const [paramObject, setParamObject] = useState({} as any);
    const [tab, setTab] = useState(0);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} updateContext={false} leagues={leagues} setLeagues={setLeagues} patches={patches} setPatches={setPatches} />
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Tabs value={tab} aria-label="basic tabs example" onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
                    <Tab label="Stats" {...a11yProps(0)} />
                    <Tab label="History" {...a11yProps(1)} />
                    <Tab label="Priorities" {...a11yProps(2)} />
                </Tabs>
            </Grow>
            <TabPanel value={tab} index={0}>
                {paramObject.additional_filters? <DraftingMetagameBlock target={targets.competitive} paramObject={paramObject}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid
                    container
                    sx={{
                        mt: 5,
                        mb: 5,
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    {paramObject.additional_filters ? <DraftsBlock paramObject={paramObject} target={targets.competitive} /> : <></>}
                </Grid>
            </TabPanel>
        </WindowContainer>
    );
}
