import Leftbar from "./leftMenu";
import Header from "./topMenu";
import React from "react";
import { Grid } from "@mui/material";

interface LayoutProps {
    changePage: any;
    current: number;
    logout: () => void;
}

export default function Layout(props: LayoutProps) {
    let { changePage } = props;
    return (
        <Grid container sx={{ width: "100%" }}>
            <Header changePage={changePage} current={props.current} logout={props.logout}/>
            {/*<Leftbar changePage={changePage} current={props.current}/>*/}
        </Grid>
    );
}
