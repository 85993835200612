import { darken, Grow, Paper, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import React, {useContext, useRef} from "react";
import global_context, { loss_color, win_color } from "../../static_vars";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import FarsightApi from "../../ApiWrapper";
import { useReferentialContext } from "../../Context";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";

interface DraftParams {
    draft: any;
    team_target: string;
    index: number;
}

function export_draft_excel(draft: any, index: number, team_target: string){
    const rows = [
        `Game ${index + 1}\t\t\t\t\t${draft.winner === team_target ? 'Win' : 'Loose'}`,
        `${draft.team1}\t\t${draft.winner === draft.team1 ? 1 : 0}\t${draft.team2}\t\t${draft.winner === draft.team2 ? 1 : 0}`,
        `${draft.team1ban1}\t\t\t\t\t${draft.team2ban1}`
    ]
    console.log(rows)
    navigator.clipboard.writeText(rows.join("\n"));

}

const DraftTurn = styled(Paper)(() => ({
    // backgroundColor: darken('#ff726f',0.2),
    backgroundColor: "#340C0C",
    // color: 'white',
    borderRadius: 0,
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: "50%",
})) as typeof Paper;

export function DraftBlockScrim(props: DraftParams) {
    const { draft } = props;
    const gridRef = useRef(null);

    const handleCapture = () => {
      if (gridRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(gridRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: '#000000',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: (props.index + 1) * 500 }}>
            <Stack textAlign={"center"} justifyContent={"flex-start"} sx={{ width: "350px" }} ref={gridRef}>
                <Paper sx={{ borderRadius: 0, justifyContent: 'space-around', display: 'flex', alignItems:'center' }}>
                    {dayjs(draft.date).format("MMMM D HH:mm")}
                    <Button variant={"outlined"} sx={{height:'80%'}} onClick={() => handleCapture()}>PNG</Button>
                </Paper>
                <Paper
                    sx={{
                        borderRadius: 0,
                        justifyContent: "space-evenly",
                        display: "flex",
                        height: "40px",
                    }}
                >
                    <Tooltip title={draft.team1}>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${draft.team1}.png`} />
                    </Tooltip>
                    <Tooltip title={draft.team2}>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${draft.team2}.png`} />
                    </Tooltip>
                </Paper>
                <Stack direction={"row"}>
                    <Paper sx={{ width: "50%", borderRadius: "0px" }}>{`Patch ${draft.patch}`}</Paper>
                    <Paper sx={{ width: "50%", borderRadius: "0px" }}>{draft.winner === 'Remake'? 'Remake' : draft.winner === props.team_target ? "Win" : "Loss"}</Paper>
                </Stack>
                <Paper sx={{ width: "100%", borderRadius: "0px" }}>
                    <Stack
                        textAlign={"center"}
                        justifyContent={"space-evenly"}
                        sx={{
                            width: "350px",
                            alignItems: "center",
                            display: "flex",
                            border: `2px solid ${draft.drafting_sim_predicted_winner === draft.winner ? "lightgreen" : "red"}`,
                        }}
                        direction={"row"}
                        spacing={1}
                    >
                        <Typography>{`${(draft.drafting_sim_team1_pred * 100).toFixed(2)}%`}</Typography>
                        <Typography>{`${(draft.drafting_sim_team2_pred * 100).toFixed(2)}%`}</Typography>
                    </Stack>
                </Paper>
                <DraftBody draft={draft} team_target={props.team_target} index={props.index} />
            </Stack>
        </Grow>
    );
}

export function DraftBlockCompetitive(props: DraftParams) {
    const { draft } = props;
    const gridRef = useRef(null);
    const handleCapture = () => {
      if (gridRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(gridRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: '#000000',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: (props.index + 1) * 300 }}>
            <Stack
                ref={gridRef}
                textAlign={"center"}
                justifyContent={"flex-start"}
                sx={{
                    width: "353px",
                    height: "544px",
                    mt: 1,
                    border: `2px solid ${draft.winner === props.team_target ? win_color : loss_color}`,
                }}
            >
                <Paper sx={{ borderRadius: "0px", justifyContent:'space-around', display:'flex' }}>{draft.league + " : " + dayjs(draft.date).format("MMMM D")}<Button variant={"outlined"} sx={{height:'80%'}} onClick={() => handleCapture()}>PNG</Button></Paper>
                <Paper sx={{ width: "100%", borderRadius: "0px" }}>
                    <Stack
                        textAlign={"center"}
                        justifyContent={"space-evenly"}
                        sx={{
                            width: "350px",
                            alignItems: "center",
                            display: "flex",
                        }}
                        direction={"row"}
                        spacing={1}
                    >
                        <Box
                            width={"100%"}
                            height={"51px"}
                            sx={{
                                border: `2px solid ${draft.side_selection_id === draft.team1 ? "white" : "primary"}`,
                                alignContent: "center",
                                justifyContent: "center",
                                borderRadius: "0px",
                                alignItems: "center",
                                display: "flex",
                            }}
                            alignContent={"center"}
                        >
                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${draft.team1}.png`} />
                        </Box>
                        <Box
                            width={"100%"}
                            height={"51px"}
                            sx={{
                                border: `2px solid ${draft.side_selection_id === draft.team2 ? "white" : "primary"}`,
                                alignContent: "center",
                                justifyContent: "center",
                                borderRadius: "0px",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${draft.team2}.png`} />
                        </Box>
                    </Stack>
                </Paper>
                {draft.date > '2023-03-21' ? <Paper sx={{width: "100%", borderRadius: "0px"}}>
                    <Stack
                        textAlign={"center"}
                        justifyContent={"space-evenly"}
                        sx={{
                            width: "350px",
                            alignItems: "center",
                            display: "flex",
                            border: `2px solid ${draft.drafting_sim_predicted_winner === draft.winner ? "lightgreen" : "red"}`,
                        }}
                        direction={"row"}
                        spacing={1}
                    >
                        <Typography>{`${(draft.drafting_sim_team1_pred * 100).toFixed(2)}%`}</Typography>
                        <Typography>{`${(draft.drafting_sim_team2_pred * 100).toFixed(2)}%`}</Typography>
                    </Stack>
                </Paper> : <Paper sx={{width: "100%", borderRadius: "0px"}}>
                    <Stack
                    textAlign={"center"}
                    justifyContent={"space-evenly"}
                    sx={{
                    width: "350px",
                    alignItems: "center",
                    display: "flex",
                    border: `2px solid ${draft.drafting_sim_v3_predicted_winner === draft.winner ? "lightgreen" : "red"}`,
                }}
                    direction={"row"}
                    spacing={1}
                    >
                    <Typography>{`${(draft.drafting_sim_v3_team1_pred * 100).toFixed(2)}%`}</Typography>
                    <Typography>{`${(draft.drafting_sim_v3_team2_pred * 100).toFixed(2)}%`}</Typography>
                    </Stack>
                    </Paper>
                }
                <Stack direction={"row"}>
                    <Paper sx={{ width: "50%", borderRadius: "0px" }}>{"Patch " + draft.patch}</Paper>
                    <Paper
                        sx={{
                            width: "50%",
                            alignContent: "center",
                            justifyContent: "center",
                            borderRadius: "0px",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        {"Win : "}
                        <Box component={"img"} src={"https://res.cloudinary.com/xenesis/image/upload/v1/teamsLogo/" + draft.winner + ".png"} width={20} height={20} sx={{ ml: 1 }} />
                    </Paper>
                </Stack>
                <DraftBody draft={draft} team_target={props.team_target} index={props.index} />
            </Stack>
        </Grow>
    );
}

export default function DraftBody(props: DraftParams) {
    const { draft } = props;
    const { ddragon_champions } = useReferentialContext();
    return (
        <Stack textAlign={"center"} justifyContent={"space-evenly"} sx={{ width: "350px" }}>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban1 && draft.team1ban1 !== "None" ? ddragon_champions[draft.team1ban1].name : "None"}</DraftTurn>
                {draft.team1ban1 !== "None" && draft.team1ban1 !== null && draft.team1ban1 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban1}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban1 !== "None" && draft.team2ban1 !== null && draft.team2ban1 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban1}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban1 && draft.team2ban1 !== "None" ? ddragon_champions[draft.team2ban1].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban2 && draft.team1ban2 !== "None" ? ddragon_champions[draft.team1ban2].name : "None"}</DraftTurn>
                {draft.team1ban2 !== "None" && draft.team1ban2 !== null && draft.team1ban2 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban2}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban2 !== "None" && draft.team2ban2 !== null && draft.team2ban2 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban2}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban2 && draft.team2ban2 !== "None" ? ddragon_champions[draft.team2ban2].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban3 && draft.team1ban3 !== "None" ? ddragon_champions[draft.team1ban3].name : "None"}</DraftTurn>
                {draft.team1ban3 !== "None" && draft.team1ban3 !== null && draft.team1ban3 ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban3}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban3 !== "None" && draft.team2ban3 !== null && draft.team2ban3 ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban3}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban3 && draft.team2ban3 !== "None" ? ddragon_champions[draft.team2ban3].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#1C2633" }}>{draft.team1pick1}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick1}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick1}.png`} />
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team2pick1] ? ddragon_champions[draft.team2pick1].name : "???"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team1pick2].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick2}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick2}.png`} />
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team2pick2].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team1pick3].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick3}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick3}.png`} />
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team2pick3].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban4 && draft.team1ban4 !== "None" ? ddragon_champions[draft.team1ban4].name : "None"}</DraftTurn>
                {draft.team1ban4 !== "None" && draft.team1ban4 !== null && draft.team1ban4 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban4}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban4 !== "None" && draft.team2ban4 !== null && draft.team2ban4 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban4}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban4 && draft.team2ban4 !== "None" ? ddragon_champions[draft.team2ban4].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban5 && draft.team1ban5 !== "None" ? ddragon_champions[draft.team1ban5].name : "None"}</DraftTurn>
                {draft.team1ban5 !== "None" && draft.team1ban5 !== null && draft.team1ban5 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban5}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban5 !== "None" && draft.team2ban5 !== null && draft.team2ban5 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban5}.png`} />
                ) : (
                    <Box sx={{ width: "51px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban5 && draft.team2ban5 !== "None" ? ddragon_champions[draft.team2ban5].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team1pick4].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick4}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick4}.png`} />
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team2pick4].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team1pick5].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick5}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick5}.png`} />
                <DraftTurn sx={{ backgroundColor: "#1C2633" }}>{ddragon_champions[draft.team2pick5].name}</DraftTurn>
            </Stack>
        </Stack>
    );
}
