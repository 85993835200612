import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useContext, useState } from "react";
import global_context from "../../static_vars";
import { FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
    return `Season ${value}`;
}

export default function YearSelect(props: { value: number; onChange: any }) {
    const [value, setValue] = useState(props.value);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };
    return (
        <Box
            sx={{ height: "68px", zIndex: 0, width: "150px" }}
            component="fieldset"
            style={{
                marginTop: "-12px",
                borderRadius: 5,
                borderColor: "secondary",
            }}
        >
            <legend>{`Year : ${props.value}`}</legend>
            <Slider aria-label="Year" getAriaValueText={valuetext} valueLabelDisplay="auto" step={1} marks min={2010} max={new Date().getFullYear()} onChange={handleChange} onChangeCommitted={(event, value) => props.onChange(value)} value={value} />
        </Box>
    );
}

export function SplitSelect(props: { value: string | null; onChange: any }) {
    return (
        <FormControl sx={{ width: "130px" }}>
            <InputLabel>Split</InputLabel>
            <Select value={props.value ? props.value : undefined} label={"Split"} onChange={(event) => (event.target.value ? props.onChange(event.target.value) : props.onChange(null))}>
                <MenuItem value={undefined}>All</MenuItem>
                <MenuItem value={"Winter"}>Winter</MenuItem>
                <MenuItem value={"Spring"}>Spring</MenuItem>
                <MenuItem value={"Summer"}>Summer</MenuItem>
                <MenuItem value={"Split 1"}>Split 1</MenuItem>
                <MenuItem value={"Split 2"}>Split 2</MenuItem>
                <MenuItem value={"Finals"}>Finals</MenuItem>
            </Select>
        </FormControl>
    );
}

export function PhaseSelect(props: { value: string[]; onChange: any }) {
    return (
        <FormControl sx={{ width: "170px" }}>
            <InputLabel>Stage</InputLabel>
            <Select multiple value={props.value} label={"Stage"} onChange={(event) => props.onChange(event.target.value)}>
                <MenuItem value={"Regular Season"}>Regular Season</MenuItem>
                <MenuItem value={"Tiebreakers"}>Tiebreakers</MenuItem>
                <MenuItem value={"Playoffs"}>Playoffs</MenuItem>
                <MenuItem value={"Knockout"}>Knockout</MenuItem>
                <MenuItem value={"Group"}>Group</MenuItem>
                <MenuItem value={"Group Stage"}>Group Stage</MenuItem>
            </Select>
        </FormControl>
    );
}
