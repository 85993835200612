import { Box, Fade, Stack } from "@mui/material";
import React from "react";

export default function StatRow(props: { stat: any; value: any }) {
    const { stat, value } = props;
    return (
        <Fade in={stat} style={{ transformOrigin: "0 0 0" }} {...{ timeout: Math.floor(Math.random() * (6000 - 2000) + 2000) }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
                <Box
                    sx={{
                        width: "50%",
                        justifyContent: "left",
                        display: "flex",
                    }}
                >{`${stat}`}</Box>
                <Box
                    sx={{
                        width: "50%",
                        justifyContent: "right",
                        display: "flex",
                    }}
                >{`${value}`}</Box>
            </Stack>
        </Fade>
    );
}
