import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import {targets, useReferentialContext} from "../../../Context";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import OverviewDetailed from "../../../components/selectors/OverviewDetailed";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import Box from "@mui/material/Box";
import dayjs, {Dayjs} from "dayjs";


export default function ScrimsChampionsRatings() {
    const context = useReferentialContext();
    const [detailed, setDetailed] = useState(false);

    const [picktype, setPicktype] = useState(null as null | boolean);
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [from, setFrom] = useState(dayjs().subtract(14, "day"))
    const [until, setUntil] = useState(dayjs())

    const [paramObject, setParamObject] = useState({} as any);
    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.scrims}
                updateContext={false}
                patches={patches}
                setPatches={setPatches}
                picktype={picktype}
                setPicktype={setPicktype}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
            />
            <OverviewDetailed onChange={setDetailed} value={detailed} />
            {
                paramObject.additional_filters ? (!detailed ? <OverviewTierlists paramObject={paramObject} /> : <DetailedTierlist paramObject={paramObject} target={targets.scrims}/>) : <></>
            }
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const cols = ["champion", "games", "rating", "winrate", "team_games", "team_rel_rate", "team_winrate"];
    const user_team = useReferentialContext().user.team;

    const columnGroupingModel= [
            {
                groupId: "General",
                children: [{ field: "games" }, { field: "winrate" }, { field: "rating" }],
                headerClassName: "header",
            },
            {
                groupId: "Team",
                children: [{ field: "team_games" }, { field: "team_winrate" }, { field: "team_rel_rate" }],
                renderHeaderGroup: () =>
                    <Stack direction={'row'}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Box
                            component={"img"}
                            src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${user_team}.png`}
                            sx={{
                                filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                            }}
                        />
                    </Stack>
            },
        ]

    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "TOP_LANE",
                        target: targets.scrims,
                    })
                }
                grouping_model={columnGroupingModel}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "JUNGLE",
                        target: targets.scrims,
                    })
                }
                grouping_model={columnGroupingModel}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "MID_LANE",
                        target: targets.scrims,
                    })
                }
                grouping_model={columnGroupingModel}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "BOT_LANE",
                        target: targets.scrims,
                    })
                }
                grouping_model={columnGroupingModel}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: targets.scrims,
                    })
                }
                grouping_model={columnGroupingModel}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}
function DetailedTierlist(props: { paramObject: any, target: targets }) {
    const api = useReferentialContext().farsightApi.computes;
    const aggregate_api=useReferentialContext().farsightApi.getSummariesApi(targets.competitive);
    const [role, setRole] = useState("BOT_LANE");
    const [metrics, setMetrics] = useState<any[]>(useReferentialContext().selectedMetrics);
    const [paramObject, setParamObject] = useState<any>(props.paramObject as any);
    const [ratings, setRatings] = useState([] as any[]);
    const [metricsData, setMetricsData] = useState([] as any[]);
    const [ratingsDone, setRatingsDone] = useState(false);
    const [metricsDone, setMetricsDone] = useState(false);
    const [joinedData, setJoinedData] = useState([] as any[]);
    const [updateObject, setUpdateObject] = useState({} as any);
    const cols = ["champion","rating","performance_with","relative_performance_with","performance_against","relative_performance_against"];

    useEffect(() => {
        api.champion_ratings({
            ...props.paramObject,
            role: role,
            target: props.target,
        }).then(r => setRatings(r)).then(() => setRatingsDone(true));
        aggregate_api.aggregate({
            ...props.paramObject,
            ...paramObject,
            additional_filters: {...props.paramObject.additional_filters, ...paramObject.additional_filters},
            index_on: 'champion',
            groupby: ['champion'],
            ordering: ['champion'],
            metrics: [metrics.map(m => m.code).toString()],
            role: role,
        }).then(r => setMetricsData(r)).then(() => setMetricsDone(true));

    }, [metrics, props.paramObject, role])

    async function request(){
        return joinedData;
    }

    useEffect(() => {
        if(metricsDone && ratingsDone){
            const joined_data = [];
            for(let i in ratings){
                const rating_row = ratings[i];
                const additional_data = metricsData[rating_row.champion][0]
                const joined_object = {...rating_row, ...additional_data}
                joined_data.push(joined_object);
            }
            setMetricsDone(false);
            setRatingsDone(false);
            setJoinedData(joined_data);
            const update = {
                ...props.paramObject,
                ...paramObject,
                additional_filters: {...props.paramObject, ...paramObject}};
            update.watermark = JSON.stringify(update);
            setUpdateObject(update);
        }
    }, [ratingsDone, metricsDone])

    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "center",
                width: "100%",
                display: "flex",
                alignItems: "top",
            }}
            spacing={3}
        >
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} updateContext={false} role={role} setRole={setRole} metrics={metrics} setMetrics={setMetrics} />
            <TableDisplayGrid
                display_all={false}
                request={() => request()}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={updateObject}
                metrics={metrics}
            />
        </Stack>
    );
}