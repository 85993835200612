import {targets, useReferentialContext} from "../../../Context";
import WindowContainer from "../../../components/layout/WindowContainer";
import React, {useState} from "react";
import FilterSet from "../../../components/selectors/Filterset";
import {GamesData, Team} from "../../../farsight-api";
import dayjs, {Dayjs} from "dayjs";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import {Stack} from "@mui/material";
import {PositionMapDraws} from "./PositionHeatmap";
import TeamfightSelect from "../../../components/selectors/Competitive/TeamfightSelect";


export default function CheckTeamfights(props: {target: targets}) {
    const [selectedGame, setSelectedGame] = useState(null as GamesData|null);



    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [ingameInterval, setIngameInterval] = useState([0,600]);
    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGame(gameSelected[0]);
    };

    return <WindowContainer direction={'column'} spacing={2}>
        <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                target={props.target}
            />
        <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
                spacing={1}
            >
                {paramObject.team && team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={true} side_select={true} target={props.target} single={true} />
                    : <></>
                }
                {selectedGame ? <TeamfightSelect target={props.target} paramObject={{game:selectedGame.game_id}} perspective={team}/> : <></>}
            </Stack>
    </WindowContainer>
}