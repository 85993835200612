import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import MapDrawer from "../../../../components/maps/map_drawer";
import {targets} from "../../../../Context";
import dayjs, { Dayjs } from "dayjs";
import HeatMapDrawer from "../../../../components/maps/heatmap_drawer";
import FilterSet from "../../../../components/selectors/Filterset";
import {Team} from "../../../../farsight-api";
import { useReferentialContext } from "../../../../Context";
import SpecificGameSelector from "../../../../components/selectors/Competitive/SpecificGameSelector";
import WindowContainer from "../../../../components/layout/WindowContainer";

export default function WardMaps(props: {target : targets}) {
    const [selectedGames, setSelectedGames] = useState([] as string[]);

    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [roles, setRoles] = useState<string[]>(["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"]);
    const [wardTypes, setWardTypes] = useState<string[]>(['control','yellowTrinket','blueTrinket','sight']);
    const [ingameInterval, setIngameInterval] = useState([0,600]);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGames(gameSelected.map(g => g.game_id));
    };

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                roles={roles}
                setRoles={setRoles}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                wardTypes={wardTypes}
                setWardTypes={setWardTypes}
                target={props.target}
            />

            <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
                spacing={1}
            >
                {paramObject.team && team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={true} side_select={true} target={props.target} single={false} />
                    : <></>
                }
                {paramObject.team && team ?
                    <WardMapDraws
                            team={team}
                            game_ids={selectedGames}
                            target={props.target}
                            roles={roles}
                            ingameInterval={ingameInterval}
                            wardTypes={wardTypes}
                    /> : <></>}
            </Stack>
        </WindowContainer>
    );
}



export function WardMapDraws(props: {game_ids: string[],
    roles: string[],
    team: Team,
    target: targets,
    ingameInterval: number[],
    points?: boolean,
    heat?:boolean,
    wardTypes?: string[]
}) {
    const points = props.points === undefined ? true : props.points;
    const heat = props.heat === undefined ? true : props.heat;

    const api = useReferentialContext().farsightApi.maps
    const [data, setData] = useState<any>({});
    console.log(props.wardTypes)
    useEffect(() => {
        setData([]);
        api.events({
            team: props.team.team_name,
            games: [props.game_ids.toString()],
            roles: [props.roles.toString()],
            target: props.target,
            event_types: ['wards'],
            ig_from: props.ingameInterval[0],
            ig_until: props.ingameInterval[1],
        }).then((d: any) => {
            setData(d);
            console.log(d.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true)))
        })
    }, [props.game_ids, props.roles, props.team, props.target, props.ingameInterval]);
    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&::-webkit-scrollbar": {
                    width: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#e1e1e1e",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                },
            }}
        >
            {points ? <MapDrawer points={data.points ? data.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true)) : []}/> : <></>}
            {heat ? <HeatMapDrawer
                points={data.points ?
                    data.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true))
                    : []}/> : <></>}
        </Grid>
    );
}
