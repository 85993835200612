import {targets, useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import {CompetitiveGameSummaries, GamesData, League, ScrimGameSummaries, Team} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import {DisplayPlayerStats} from "../../../components/datagrids/Competitive/RankedStatsGrid";
import {FormControl, InputLabel, Select, Stack} from "@mui/material";
import dayjs from "dayjs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import Tabs from "@mui/material/Tabs";
import DraftingMetagameBlock from "../../../components/visu_blocks/DraftingMetagame";
import MenuItem from "@mui/material/MenuItem";
import TeamStatTimelineChart, {PlayerStatTimelineChart} from "../../../components/StatTimelinesChart";
import Box from "@mui/material/Box";

function StatTimelineSelect(props: {value: string, onChange: any}){
    // performance_timeline = models.JSONField(null=True, blank=True)
    // total_golds_timeline = models.JSONField(null=True)
    // total_damages_timeline = models.JSONField(null=True)
    // physical_damages_timeline = models.JSONField(null=True)
    // magic_damages_timeline = models.JSONField(null=True)
    // true_damages_timeline = models.JSONField(null=True)
    // total_damages_taken_timeline = models.JSONField(null=True)
    // physical_damages_taken_timeline = models.JSONField(null=True)
    // magic_damages_taken_timeline = models.JSONField(null=True)
    // true_damages_taken_timeline = models.JSONField(null=True)
    // minions_killed_timeline = models.JSONField(null=True)
    // xp_timeline = models.JSONField(null=True)
    // level_timeline = models.JSONField(null=True)
    // kp_timeline = models.JSONField(null=True)
    //
    // # Delta timelines
    // total_golds_delta_timeline = models.JSONField(null=True)
    // minions_killed_delta_timeline = models.JSONField(null=True)
    // xp_delta_timeline = models.JSONField(null=True)
    // level_delta_timeline = models.JSONField(null=True)
    return <FormControl sx={{width:'300px'}}>
        <InputLabel id="role">Stat</InputLabel>
        <Select value={props.value} label="Stat" onChange={(event) => props.onChange(event.target.value)}>
            <MenuItem value={"total_golds_timeline"}>Total Golds</MenuItem>
            <MenuItem value={"total_damages_timeline"}>Total Damages</MenuItem>
            <MenuItem value={"total_golds_delta_timeline"}>Gold Delta</MenuItem>
            <MenuItem value={"minions_killed_delta_timeline"}>CS Delta</MenuItem>
            <MenuItem value={"xp_delta_timeline"}>XP Delta</MenuItem>
            <MenuItem value={"level_delta_timeline"}>Level Delta</MenuItem>
        </Select>
    </FormControl>
}

function ChooseWithin(props: {value: string | undefined, onChange: any, options: string[]}){
    return <FormControl sx={{width:'300px'}}>
        <InputLabel id="role">Stat</InputLabel>
        <Select value={props.value} label="Stat" onChange={(event) => props.onChange(event.target.value)}>
            {
                props.options.map(s => <MenuItem value={s}>{s}</MenuItem>)
            }
        </Select>
    </FormControl>
}

export default function GoldsGraphs(props: {target: targets}) {
    const context = useReferentialContext();
    const [detailed, setDetailed] = useState(false);

    const [team, setTeam] = useState(context.selectedTeam as Team);
    const [selection, setSelection] = useState([] as GamesData[]);
    const [from, setFrom] = useState(dayjs().subtract(3, 'month'))
    const [until, setUntil] = useState(dayjs());
    const [tab, setTab] = useState(0);

    const [paramObject, setParamObject] = useState({} as any);

    const [stat, setStat] = useState('total_golds_delta_timeline');

    const [gamesSelectable, setGamesSelectable] = useState([] as (CompetitiveGameSummaries | ScrimGameSummaries)[]);
    const [gamesSelected, setGamesSelected] = useState([] as (CompetitiveGameSummaries | ScrimGameSummaries)[]);
    const [players, setPlayers] = useState([] as string[]);
    const [playerSelected, setPlayerSelected] = useState(undefined as string | undefined);

    useEffect(() => {
        context.farsightApi.getSummariesApi(props.target).list(paramObject).then(r => {
            r = r as CompetitiveGameSummaries[] | ScrimGameSummaries[]
            setGamesSelectable(r);
            let players_list = [] as string[];
            r.forEach(g => {
                if(!players_list.includes(g.player)) players_list.push(g.player);
            })
            setPlayers(players_list);
        });
    }, [paramObject.watermark])

    useEffect(() => {
        // @ts-ignore
        const ns = gamesSelectable.filter((g: CompetitiveGameSummaries | ScrimGameSummaries) => selection.map(g => g.game_id).includes(g.game as string));
        setGamesSelected(ns);
        console.log(ns);
        console.log('Updated Selection')
    }, [selection])

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                updateContext={false}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
            />
            <Stack direction={'row'} sx={{width:'100%', height:'90%'}} spacing={2}>
                <SpecificGameSelector team={team} updateSelection={setSelection} from={from} until={until} side_select={false} target={targets.competitive} single={false}/>
                <Stack direction={'column'} sx={{width:'100%', height:'80%'}} spacing={2}>
                    <Tabs value={tab} aria-label="basic tabs example" onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
                        <Tab label="Team" {...a11yProps(0)} />
                        <Tab label="Player" {...a11yProps(1)} />
                    </Tabs>
                    <StatTimelineSelect value={stat} onChange={setStat}/>
                    <TabPanel value={tab} index={0}>
                        <Box sx={{height:'100%', width:'100%', maxWidth:'1300px'}}>
                            <TeamStatTimelineChart stat={stat} games={gamesSelected} gameInfos={selection}/>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Box sx={{height:'100%', width:'100%', maxWidth:'1300px'}}>
                            <ChooseWithin value={playerSelected} onChange={setPlayerSelected} options={players}/>
                            <PlayerStatTimelineChart stat={stat} games={gamesSelected.filter((g: CompetitiveGameSummaries | ScrimGameSummaries) => g.player === playerSelected)}/>
                        </Box>
                    </TabPanel>
                </Stack>
            </Stack>
            </WindowContainer>
    );
}