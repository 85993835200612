import {CompetitiveGameSummaries, GamesData, ScrimGameSummaries} from "../farsight-api";
import Box from "@mui/material/Box";
import {Line} from "react-chartjs-2";
import React from "react";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";


export default function TeamStatTimelineChart(props: {games: (CompetitiveGameSummaries | ScrimGameSummaries)[], stat: string, gameInfos: GamesData[]}){

    const options = {
        responsive: true,
        scales: {
            y: {
                stepSize: 1000,
                grid: {
                    color: 'grey'
                }
            },
        },
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        lineTension: 0.3,
    } as any;

    const team_stats = new Map<string, number[]>;
    let max_len = 0;


    props.games.forEach(g => {
        let current_value = team_stats.get(g.game as string) || [];
        if(current_value.length === 0){
            // @ts-ignore
            current_value = [...g[props.stat]]
        }else{
            // @ts-ignore
            for(let i=0; i < g[props.stat].length; i++){
                // @ts-ignore
                current_value[i] += g[props.stat][i]
            }
        }
        team_stats.set(g.game as string, current_value);
        if(current_value.length > max_len) max_len = current_value.length;

    })

    const colors = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];

    let list = [];
    for (let i = 0; i <= max_len; i++) {
        list.push(i);
    }

    const data = {
        labels: list,
        datasets: props.gameInfos.map((g, index) => {
            return {
            label: g.team2,
            data: team_stats.get(g.game_id),
            borderColor: colors[index % colors.length],
            backgroundColor: colors[index % colors.length],
            pointRadius: 1,
        }}),
    };

    return (
        <Stack sx={{height: "100%", width: "100%" }} direction={'column'}>
            <Button onClick={() => to_excel(data.datasets, max_len)} variant={'outlined'} sx={{width:'300px'}}>Export</Button>
            <Line options={options} data={data} width={"100%"} height={"70%"}/>
        </Stack>
    );
}

export function PlayerStatTimelineChart(props: {games: (CompetitiveGameSummaries | ScrimGameSummaries)[], stat: string}){

    const options = {
        responsive: true,
        scales: {
            y: {
                stepSize: 1000,
                grid: {
                    color: 'grey'
                }
            },
        },
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        lineTension: 0.3,
    } as any;
    let max_len = 60;

    const colors = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];

    let list = [];
    for (let i = 0; i <= max_len; i++) {
        list.push(i);
    }

    const data = {
        labels: list,
        datasets: props.games.map((g, index) => {
            return {
                label: g.team2,
                // @ts-ignore
                data: g[props.stat],
                borderColor: colors[index % colors.length],
                backgroundColor: colors[index % colors.length],
                pointRadius: 1,
            }}),
    };

    return (
        <Stack sx={{height: "100%", width: "100%", border: '1px solid white' }} direction={'row'}>
            <Button onClick={() => to_excel(data.datasets, max_len)}>Export</Button>
            <Line options={options} data={data} width={"100%"} height={"70%"}/>
        </Stack>
    );
}

function to_excel(datasets: any[], max_len: number){
    const labels = datasets.map(d => d.label);
    let to_ret = ['\t' + labels.join('\t')];
    for (let i = 0; i < max_len; i++) {
        let row = `${i}\t`;
        let values = datasets.map(d => d.data[i]);
        row += values.join("\t");
        to_ret.push(row)
    }
    navigator.clipboard.writeText(to_ret.join("\n"));
}