import {FormControl, InputLabel, Stack} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";


export function MultipleWardTypeSelect(props: { values: string[]; updateValue: (newValue: string[]) => void }) {
    const labels: any = {
        control: "Control ward",
        yellowTrinket: "Yellow trinket",
        blueTrinket: "Blue trinket",
        sight: "Support Item",
    };
    return (
        <FormControl sx={{ width: "170px", height: "55px", zIndex: 0 }}>
            <InputLabel>Ward types</InputLabel>
            <Select
                multiple
                value={props.values as any}
                label="Ward types"
                onChange={(event) => props.updateValue(event.target.value as any)}
                renderValue={(value: string) => (
                    <Stack direction={"row"} sx={{ justifyContent: "left", width: "100%" }} spacing={0}>
                        {props.values.map((role: string) => {
                            return <img src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${role}.png`} width={25} height={25} />;
                        })}
                    </Stack>
                )}
            >
                {Object.entries(labels).map(([code, label]) => (
                    <MenuItem value={code}>
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                alignItems: "center",
                            }}
                            spacing={2}
                        >
                            <img src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${code}.png`} width={25} height={25} style={{ marginRight: 10 }} />
                            {labels[code]}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}