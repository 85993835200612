/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    DraftPreps,
    DraftPrepsAggregate,
    PatchedDraftPreps,
} from "../models/index";

export interface DraftPrepsAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    name?: string;
    organization?: string;
    prep_vs?: string;
}

export interface DraftPrepsCreateRequest {
    id: string;
    draft_preps: DraftPreps;
}

export interface DraftPrepsDeleteRequest {
    id: string;
}

export interface DraftPrepsFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    name?: string;
    organization?: string;
    prep_vs?: string;
}

export interface DraftPrepsGetRequest {
    id: string;
}

export interface DraftPrepsListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    name?: string;
    ordering?: Array<string>;
    organization?: string;
    prep_vs?: string;
}

export interface DraftPrepsPatchRequest {
    id: string;
    patched_draft_preps?: PatchedDraftPreps;
}

export interface DraftPrepsVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    name?: string;
    organization?: string;
    prep_vs?: string;
}

/**
 *
 */
export class DraftPrepsApi extends runtime.BaseAPI {
    /**
     */
    async draftPrepsAggregateRaw(
        requestParameters: DraftPrepsAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DraftPrepsAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling draftPrepsAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling draftPrepsAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.name !== undefined) {
            queryParameters["name"] = requestParameters.name;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters["organization"] = requestParameters.organization;
        }

        if (requestParameters.prep_vs !== undefined) {
            queryParameters["prep_vs"] = requestParameters.prep_vs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: DraftPrepsAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DraftPrepsAggregate>> {
        const response = await this.draftPrepsAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsCreateRaw(
        requestParameters: DraftPrepsCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<DraftPreps>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling draftPrepsCreate.",
            );
        }

        if (
            requestParameters.draft_preps === null ||
            requestParameters.draft_preps === undefined
        ) {
            throw new runtime.RequiredError(
                "draft_preps",
                "Required parameter requestParameters.draft_preps was null or undefined when calling draftPrepsCreate.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.draft_preps,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: DraftPrepsCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<DraftPreps> {
        const response = await this.draftPrepsCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsDeleteRaw(
        requestParameters: DraftPrepsDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling draftPrepsDelete.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete(
        requestParameters: DraftPrepsDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.draftPrepsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async draftPrepsFieldValuesRaw(
        requestParameters: DraftPrepsFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling draftPrepsFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling draftPrepsFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.name !== undefined) {
            queryParameters["name"] = requestParameters.name;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters["organization"] = requestParameters.organization;
        }

        if (requestParameters.prep_vs !== undefined) {
            queryParameters["prep_vs"] = requestParameters.prep_vs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: DraftPrepsFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.draftPrepsFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsGetRaw(
        requestParameters: DraftPrepsGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<DraftPreps>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling draftPrepsGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: DraftPrepsGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<DraftPreps> {
        const response = await this.draftPrepsGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsListRaw(
        requestParameters: DraftPrepsListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DraftPreps>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.name !== undefined) {
            queryParameters["name"] = requestParameters.name;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters["organization"] = requestParameters.organization;
        }

        if (requestParameters.prep_vs !== undefined) {
            queryParameters["prep_vs"] = requestParameters.prep_vs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: DraftPrepsListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DraftPreps>> {
        const response = await this.draftPrepsListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsPatchRaw(
        requestParameters: DraftPrepsPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<DraftPreps>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling draftPrepsPatch.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.patched_draft_preps,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: DraftPrepsPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<DraftPreps> {
        const response = await this.draftPrepsPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async draftPrepsVariableDistributionRaw(
        requestParameters: DraftPrepsVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling draftPrepsVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling draftPrepsVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling draftPrepsVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling draftPrepsVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.name !== undefined) {
            queryParameters["name"] = requestParameters.name;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters["organization"] = requestParameters.organization;
        }

        if (requestParameters.prep_vs !== undefined) {
            queryParameters["prep_vs"] = requestParameters.prep_vs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/DraftPreps/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: DraftPrepsVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.draftPrepsVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
