import React, { useContext, useEffect, useState } from "react";
import global_context from "../../../static_vars";
import FarsightApi from "../../../ApiWrapper";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import dayjs, {Dayjs} from "dayjs";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, MuiEvent } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, Typography } from "@mui/material";
import AdaptablePageSizeGrid from "../AdaptablePageSizeGrid";
import {useReferentialContext} from "../../../Context";
import {OffSeasonShortlist, OffSeasonShortlistItem, Player} from "../../../farsight-api";

export default function OffseasonShortlist(props: { shortlist: OffSeasonShortlist|null; from: Dayjs; until: Dayjs }) {
    const [rows, setRows] = useState([] as any[]);
    const [loading, setLoading] = useState(false);
    const context = useReferentialContext();

    const [shortlistItems, setShortlistItems] = useState([] as OffSeasonShortlistItem[]);
    const [competitiveStats, setCompetitiveStats] = useState(null as { [player: string] : any; }|null);
    const [soloQStats, setSoloQStats] = useState(null as { [player: string] : any; }|null);
    const [soloQStats1kLP, setSoloQStats1kLP] = useState(null as { [player: string] : any; }|null);
    const [players, setPlayers] = useState([] as Player[]);

    const [resetList, setResetList] = useState(true);

    useEffect(() => {
        if(soloQStats1kLP !== null && competitiveStats !== null && soloQStats !== null && players.length > 0){
            const rows = [];
            for(const i in shortlistItems){

                 const player = shortlistItems[i].player;
                 const player_data = players.filter(p => p.player === player)[0];
                 const row = {...shortlistItems[i], ...player_data} as any;

                 if(soloQStats1kLP[player] !== undefined) {
                     row.soloq_1k_games = soloQStats1kLP[player][0].games
                     row.avg_soloq_1k_winrate = soloQStats1kLP[player][0].winrate
                     row.avg_soloq_1k_performance = soloQStats1kLP[player][0].performance
                     row.avg_soloq_1k_relative_performance = soloQStats1kLP[player][0].relative_performance
                 }else{
                     row.soloq_1k_games = undefined
                     row.avg_soloq_1k_winrate = undefined
                     row.avg_soloq_1k_performance = undefined
                     row.avg_soloq_1k_relative_performance = undefined
                 }

                 if(soloQStats[player] !== undefined) {
                     row.soloq_games = soloQStats[player][0].games
                     row.avg_soloq_winrate = soloQStats[player][0].winrate
                     row.avg_soloq_performance = soloQStats[player][0].performance
                     row.avg_soloq_relative_performance = soloQStats[player][0].relative_performance
                     row.elo = soloQStats[player][0].elo
                 }else{
                     row.soloq_games = undefined
                     row.avg_soloq_winrate = undefined
                     row.avg_soloq_performance = undefined
                     row.avg_soloq_relative_performance = undefined
                 }
                 if(competitiveStats[player] !== undefined){
                     row.competitive_games = competitiveStats[player][0].games
                     row.avg_competitive_performance = competitiveStats[player][0].performance
                     row.avg_competitive_relative_performance = competitiveStats[player][0].relative_performance
                     row.avg_competitive_winrate = competitiveStats[player][0].winrate
                 }else{
                     row.competitive_games = undefined
                     row.avg_competitive_performance = undefined
                     row.avg_competitive_relative_performance = undefined
                     row.avg_competitive_winrate = undefined
                 }
                 rows.push(row);
            }
            setRows(rows);
            setLoading(false);
            setCompetitiveStats(null);
            setSoloQStats(null);
            setPlayers([]);
        };
    }, [competitiveStats, soloQStats, soloQStats1kLP]);


    useEffect(() => {
        if (props.shortlist) {
            setLoading(true);
            setRows([]);
            context.farsightApi.offSeasonShortlistItem.list({
                shortlist: props.shortlist.id
            }).then((shortlistItems) => {
                if(shortlistItems.length === 0){
                    setLoading(false);
                    return
                }
                setShortlistItems(shortlistItems);
                context.farsightApi.soloqGameSummaries.aggregate({
                    additional_filters: {
                        date__gte:props.from.toISOString(),
                        date__lt:props.until.toISOString(),
                        account__player__in:shortlistItems.map(p => p.player).join(','),
                        elo_as_total_lps_during_dame__gte:3100
                    },
                    groupby: ['account__player'],
                    metrics: ['games,performance,relative_performance,winrate,elo'],
                    ordering:['account__player'],
                    index_on: 'account__player'
                }).then(data => setSoloQStats(data))


                context.farsightApi.soloqGameSummaries.aggregate({
                    additional_filters: {
                        date__gte:props.from.toISOString(),
                        date__lt:props.until.toISOString(),
                        account__player__in:shortlistItems.map(p => p.player).join(','),
                        elo_as_total_lps_during_dame__gte:4100
                    },
                    groupby: ['account__player'],
                    metrics: ['games,performance,relative_performance,winrate,elo'],
                    ordering:['account__player'],
                    index_on: 'account__player'
                }).then(data => setSoloQStats1kLP(data))

                context.farsightApi.competitiveGameSummaries.aggregate({
                    additional_filters: {
                        date__gte:props.from.toISOString(),
                        date__lt:props.until.toISOString(),
                        player__in:[shortlistItems.map(p => p.player)]
                    },
                    groupby: ['player'],
                    metrics: ['games,performance,relative_performance,winrate'],
                    ordering:['player'],
                    index_on: 'player'
                }).then(data => setCompetitiveStats(data))

                context.farsightApi.player.list(
                    {additional_filters: {player__in: [shortlistItems.map(p => p.player)]}}
                ).then(data => setPlayers(data))

            });
        }}, [props.shortlist, props.from, props.until, resetList]);

    let columnGroupingModel = [
        {
            groupId: "Player",
            children: [{ field: "player" }, { field: "role" }, { field: "birthdate" }, { field: "contract_expires" }, { field: "nationality" }, { field: "lolpros_id" }, { field: "agency_id" }, { field: "last_team_id" }],
            headerClassName: "header",
        },
        {
            groupId: "SoloQ",
            children: [{ field: "soloq_games" }, { field: "avg_soloq_winrate" }, { field: "avg_soloq_performance" }, { field: "avg_soloq_relative_performance" },{ field: "elo" }],
            headerClassName: "header",
        },
        {
            groupId: "Chall SoloQ",
            children: [{ field: "soloq_1k_games" }, { field: "avg_soloq_1k_winrate" }, { field: "avg_soloq_1k_performance" }, { field: "avg_soloq_1k_relative_performance" }],
            headerClassName: "header",
        },
        {
            groupId: "Competitive",
            children: [{ field: "competitive_games" }, { field: "avg_competitive_performance" }, { field: "avg_competitive_relative_performance" }, { field: "avg_competitive_winrate" }],
            headerClassName: "header",
        },
    ];

    const grid_columns = [
        ColumnTypes.player("player", "Player"),
        ColumnTypes.role("role", "R."),
        ColumnTypes.year_date("birthdate", "Birthdate"),
        ColumnTypes.year_date("contract_expires", "Contract", { maxWidth: 50 }),
        ColumnTypes.nationality("nationality", "Nationality", { minWidth: 120 }),
        // ColumnTypes.string("lolpros_id", "Lolpros", { hide: true }),
        // ColumnTypes.string("agency_id", "Agency", { hide: true }),
        ColumnTypes.team("current_team", "T", { minWidth: 40 }),

        ColumnTypes.elo_from_total_lps("elo", "LPS"),
        ColumnTypes.short_int("soloq_games", "#"),
        ColumnTypes.percentage("avg_soloq_winrate", "WR"),
        ColumnTypes.rating("avg_soloq_performance", "P."),
        ColumnTypes.rating("avg_soloq_relative_performance", "RP"),

        ColumnTypes.short_int("soloq_1k_games", "#"),
        ColumnTypes.percentage("avg_soloq_1k_winrate", "WR"),
        ColumnTypes.rating("avg_soloq_1k_performance", "P."),
        ColumnTypes.rating("avg_soloq_1k_relative_performance", "RP"),

        ColumnTypes.short_int("competitive_games", "#"),
        ColumnTypes.percentage("avg_competitive_winrate", "WR"),
        ColumnTypes.rating("avg_competitive_performance", "P."),
        ColumnTypes.rating("avg_competitive_relative_performance", "RP"),

        {
            headerName: "Comment",
            field: "comment",
            align: "center",
            minWidth: 250,
            headerAlign: "center",
            flex: 1,
            editable: true,
            renderCell: (params: any) => (
                <Typography style={{ whiteSpace: "pre-line" }} sx={{ textAlign: "center" }}>
                    {params.value}
                </Typography>
            ),
        } as GridColDef,

        {
            headerName: "Contacted",
            field: "contacted",
            align: "center",
            headerAlign: "center",
            minWidth: 90,
            maxWidth: 90,
            flex: 1,
            renderCell: (params: any) => (
                <Checkbox
                    checked={params.value}
                    onClick={() => {
                        context.farsightApi.offSeasonShortlistItem.patch({
                            id: params.row.id,
                            patched_off_season_shortlist_item : {
                                contacted: !params.value
                            }
                        });
                        params.row.contacted = !params.row.contacted
                    }}
                />
            ),
        } as GridColDef,

        {
            headerName: "Locked",
            field: "locked",
            align: "center",
            headerAlign: "center",
            maxWidth: 80,
            flex: 1,
            renderCell: (params: any) => (
                <Checkbox
                    checked={params.value}
                    onClick={() => {
                        context.farsightApi.offSeasonShortlistItem.patch({
                            id: params.row.id,
                            patched_off_season_shortlist_item : {
                                locked: !params.value
                            }
                        });
                        params.row.locked = !params.row.locked
                    }}
                />
            ),
        } as GridColDef,
        ColumnTypes.rating("price_per_month", "Salary", { editable: true }),
        {
            headerName: "Delete",
            field: "id",
            align: "center",
            headerAlign: "center",
            maxWidth: 80,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    onClick={() => {
                        context.farsightApi.offSeasonShortlistItem.delete({
                            id: params.row.id
                        }).then(() => setResetList(!resetList))
                    }}
                >
                    <DeleteIcon />
                </Button>
            ),
        } as GridColDef,
    ];

    return (
        <StyledDatagrid
            autoPageSize
            rowHeight={65}
            sx={{
                width: "100%",
                "& .header": {
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                },
                "& .border-left": {
                    borderLeft: "solid grey",
                    borderWidth: "2px",
                },
            }}
            getRowId={(row) => row.id}
            initialState={{
                sorting: {
                    sortModel: [{ field: "date", sort: "desc" }],
                },
            }}
            columns={grid_columns}
            rows={rows}
            hideFooter={false}
            columnGroupingModel={columnGroupingModel}
            // experimentalFeatures={{ columnGrouping: true }}
            loading={loading}
            onCellEditStop={(params: GridCellEditStopParams, event: any) => {
                const request_params = {
                    id: params.row.id,
                    patched_off_season_shortlist_item : {}
                } as any;
                request_params.patched_off_season_shortlist_item[params.field] = event.target.value;
                context.farsightApi.offSeasonShortlistItem.patch(request_params);
            }}
        />
    );
}
