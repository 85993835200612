import React, { useState } from "react";
import { Autocomplete, Box, Checkbox, Chip, CircularProgress, IconButton, ListSubheader, Stack, TextField, Tooltip, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function SingleSelectorGrouped<Option>(props: {
    options: Option[];
    selected: Option | null;
    updateSelection: (new_selection: Option | null) => void;
    getOptionLabel: (option: Option) => string;
    getOptionShort: (option: Option) => string;
    getGroup: (option: Option) => string;
    label?: string | undefined;
    optionDisabled?: (option: Option) => boolean;
    optionDescription?: (option: Option) => string | undefined | null;
    optionDecoration?: (option: Option) => any;
    inputDecoration?: (option: Option) => any;
}) {
    const [openedGroups, setOpenedGroups] = useState([] as string[]);
    const [userTyping, setUserTyping] = useState(false as boolean);
    const [selectedItem, setSelectedItem] = useState(props.selected);

    if (props.options.length === 0) {
        return (
            <Stack
                direction={"row"}
                sx={{
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    display: "flex",
                }}
            >
                <CircularProgress />
                <Typography>{`Loading ${props.label}...`}</Typography>
            </Stack>
        );
    }

    const setGroupDisplay = (event: any, group: string) => {
        if (!openedGroups.includes(group)) {
            setOpenedGroups([...openedGroups, group]);
        } else {
            setOpenedGroups(openedGroups.filter((value: string) => value !== group));
        }
    };

    return (
        <Autocomplete
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            value={selectedItem}
            groupBy={props.getGroup}
            // onClose={() => {setUserTyping(false); props.updateSelection(selectedItem);}}
            renderGroup={(params) => {
                return (
                    <Box key={params.group}>
                        <ListSubheader sx={{ cursor: "pointer" }}>
                            {params.group}
                            <IconButton size="small" onClick={(event) => setGroupDisplay(event, params.group)} sx={{ marginLeft: "auto" }}>
                                {openedGroups.includes(params.group) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ListSubheader>
                        {openedGroups.includes(params.group) || userTyping ? params.children : <></>}
                    </Box>
                );
            }}
            onChange={(event: any, new_value) => {
                setUserTyping(false);
                setSelectedItem(new_value);
                props.updateSelection(new_value);
            }}
            renderOption={(renderOptionProps: any, option: Option, { selected }) => {
                return (
                    <Tooltip title={props.optionDescription ? props.optionDescription(option) : undefined} placement="left">
                        <li {...renderOptionProps}>
                            {props.optionDecoration ? props.optionDecoration(option) : <></>}
                            {props.getOptionLabel(option)}
                        </li>
                    </Tooltip>
                );
            }}
            onInputChange={() => setUserTyping(true)}
            getOptionDisabled={(option) => (props.optionDisabled ? props.optionDisabled(option) : false)}
            renderInput={(params) =>
                selectedItem ? (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {props.inputDecoration && selectedItem ? props.inputDecoration(selectedItem) : <></>}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                    />
                ) : (
                    <TextField {...params} label={props.label} variant="outlined" />
                )
            }
            ListboxProps={{ className: "scrollable" }}
        />
    );
}
