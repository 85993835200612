import {Player} from "../../farsight-api";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";



export default function PlayersList(props: {players : Player[]}){
    return (
        <Stack direction={'column'} sx={{display:'flex', alignItems:'center', width:'100%', height:'100%', border: '2px solid gray', borderRadius:2, padding:'20px'}} spacing={1}>
            {
                props.players.map(p => <Stack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'space-between'}}>
                    <Typography sx={{alignItems:'center', justifyContent:'center', display:'flex'}}>{p.player}</Typography>
                    <Box
                        component={"img"}
                        src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${p.current_team}.png`}
                        width={40}
                        height={40}
                        sx={{
                            filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                        }}
                    />
                </Stack>)
            }
        </Stack>
    )
}