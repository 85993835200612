import { Checkbox, Fade, FormControl, FormGroup, FormHelperText, Grow, Stack, Tooltip, Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import global_context, { loss_color, win_color } from "../../../static_vars";
import dayjs, { Dayjs } from "dayjs";
import FarsightApi from "../../../ApiWrapper";
import { SideSelect } from "../YesNoSelector";
import { ComputesApi, DetailedApi, Team } from "../../../farsight-api";
import {Simulate} from "react-dom/test-utils";
import {targets, useReferentialContext} from "../../../Context";

export default function SpecificGameSelector(props:
{ team: Team; updateSelection: any; from: Dayjs; until: Dayjs; include_bayes?: null | boolean; side_select: boolean, target: targets, single: boolean }) {
    const [games, setGames] = React.useState([] as any[]);
    const [selectedIndexes, setSelectedIndex] = React.useState([] as boolean[]);
    const [side, setSide] = useState(null as string | null);
    const api = useReferentialContext().farsightApi.detailed;
    const handleSideChange = (newValue: string | null) => {
        const s = newValue;
        setSide(s);
        if (s) {
            const ns = games.map((game: any) =>  game.side == s && props.include_bayes && game.bayes_id);
            setSelectedIndex(ns);
            props.updateSelection(games.filter((game, index) => ns[index]));
        } else {
            const ns = games.map((game: any) =>  props.include_bayes && game.bayes_id);
            setSelectedIndex(ns);
            props.updateSelection(games.filter((game, index) => ns[index]));
        }
    };
    const handleChange = (index: number) => {
        console.log('Here')
        if(props.single){
            const nsi = games.map(i => false);
            nsi[index] = !nsi[index];
            props.updateSelection(
                games.filter((game, index) => nsi[index])
            )
            setSelectedIndex(nsi);
            console.log(nsi);

        }else{
            console.log(selectedIndexes)
            const nsi = games.map((g, i) => selectedIndexes[i] ? true : false);
            console.log(nsi);
            nsi[index] = !nsi[index];

            props.updateSelection(
                games.filter((game, index) => nsi[index])
            )
            setSelectedIndex(nsi);
            console.log(nsi);
        }
    };

    useEffect(() => {
        setGames([]);
        api.team_history({
            team: props.team.team_name,
            target: props.target,
            from: props.from.unix(),
            until: props.until.unix()
        }).then((data: any[]) => {
            setGames(data);
            // setSelectedIndex(data.map((game: any) => props.include_bayes && game.bayes_id));
            setSelectedIndex([]);
            props.updateSelection([]);
        });
    }, [props.team, props.from, props.until]);

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    overflow: "auto",
                    width: "550px",
                    border: "2px solid white",
                    padding: 1,
                    borderRadius: 3,
                    "&::-webkit-scrollbar": {
                        width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#e1e1e1e",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                        zIndex: 0,
                    },
                }}
            >
                <FormControl
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <FormLabel
                        component="legend"
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-around",
                            display: "flex",
                            width: "100%",
                            mb: 1,
                        }}
                    >
                        <Box
                            component={"img"}
                            src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/` + props.team.team_name + ".png"}
                            sx={{
                                filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                            }}
                        />
                        {`${props.team.team_name} - ${games.length} games`}
                        <SideSelect onChange={handleSideChange} value={side} />
                    </FormLabel>
                    <FormGroup>
                        {games.map((game: any, index: number) => (
                            <Tooltip
                                title={!(Boolean(props.include_bayes) && game.bayes_id === null) ? `${game.league_id} ${game.split} ${game.phase}` : `This game has no bayes data attached, information can't be displayed`}
                                placement={"top"}
                                arrow
                                followCursor={true}
                            >

                                <FormControlLabel
                                    sx={{
                                        width: "100%",
                                        backgroundColor: game.win ? win_color : loss_color,
                                    }}
                                    disabled={Boolean(props.include_bayes) && game.bayes_id === null}
                                    control={
                                        <Checkbox
                                            checked={selectedIndexes[index] === true}
                                            onChange={(event) => handleChange(index)}
                                            name={index.toString()}
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: "white",
                                                    fill: "white",
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack
                                            direction={"row"}
                                            sx={{
                                                backgroundColor: game.win ? win_color : loss_color,
                                                alignItems: "center",
                                                width: "100%",
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                component={"img"}
                                                src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/` + game.team2 + ".png"}
                                                sx={{
                                                    filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                                                    ml: 1,
                                                    mr: 1,
                                                }}
                                            />
                                            {game.patch}
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_TOP_LANE}.png`} sx={{ ml: 1 }} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_JUNGLE}.png`} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_MID_LANE}.png`}  />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_BOT_LANE}.png`} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_UTILITY}.png`} sx={{ mr: 1 }} />
                                            {game.side}
                                        </Stack>
                                    }
                                />
                            </Tooltip>
                        ))}
                    </FormGroup>
                </FormControl>
            </Box>
        </Grow>
    );
}
