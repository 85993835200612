import {Grid, Grow, Paper, Select, Stack, Tooltip, Typography} from "@mui/material";
import { SingleChampionSelect } from "../../../components/selectors/Shared/ChampionSelect";
import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import global_context, { loss_color, win_color } from "../../../static_vars";
import FarsightApi from "../../../ApiWrapper";
import PatchSelect from "../../../components/selectors/PatchSelect";
import {Champion, useReferentialContext} from "../../../Context";
import MenuItem from "@mui/material/MenuItem";

const roles_ordered = ["TOP_LANE_BLUE", "JUNGLE_BLUE", "MID_LANE_BLUE", "BOT_LANE_BLUE", "UTILITY_BLUE", "TOP_LANE_RED", "JUNGLE_RED", "MID_LANE_RED", "BOT_LANE_RED", "UTILITY_RED"];

export default function DraftingSim(props: any) {
    const champions = useReferentialContext().championsReferential;

    const [blueChampions, setBlueChampions] = useState([champions.filter(c => c.id === 'Renekton')[0], champions.filter(c => c.id === 'Sejuani')[0], champions.filter(c => c.id === 'Ahri')[0], champions.filter(c => c.id === 'Zeri')[0], champions.filter(c => c.id === 'Nautilus')[0]]);
    const [redChampions, setRedChampions] = useState([champions.filter(c => c.id === 'Ornn')[0], champions.filter(c => c.id === 'Maokai')[0], champions.filter(c => c.id === 'Jayce')[0], champions.filter(c => c.id === 'Kaisa')[0], champions.filter(c => c.id === 'Rell')[0]]);
    const [patch, setPatch] = useState("13.19");
    const roles = ["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"];

    return (
        <Stack
            sx={{
                ml: "50px",
                width: window.innerWidth - 100 + "px",
                height: window.innerHeight - 100 + "px",
                justifyContent: "space-evenly",
                display: "flex",
            }}
            direction={"row"}
            spacing={3}
        >
            <Stack
                direction={"column"}
                sx={{
                    width: "300px",
                    justifyContent: "space-evenly",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {blueChampions.map((champ: Champion, index: number) => (
                    <ChampionSelectCell
                        value={blueChampions[index]}
                        onChange={(newValue: Champion) => {
                            const nv = [...blueChampions];
                            nv[index] = newValue;
                            setBlueChampions(nv);
                        }}
                        role={roles[index]}
                        index={index}
                    />
                ))}
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%", alignItems: "center" }}>
                <DraftPrediction blueChampions={blueChampions} redChampions={redChampions} patch={patch} changePatch={(event: any, newValue: any) => setPatch(newValue)} />
            </Stack>
            <Stack
                direction={"column"}
                sx={{
                    width: "300px",
                    justifyContent: "space-evenly",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {redChampions.map((champ: Champion, index: number) => (
                    <ChampionSelectCell
                        value={redChampions[index]}
                        onChange={(newValue: Champion) => {
                            const nv = [...redChampions];
                            nv[index] = newValue;
                            setRedChampions(nv);
                        }}
                        role={roles[index]}
                        index={5 + index}
                    />
                ))}
            </Stack>
        </Stack>
    );
}

function ChampionSelectCell(props: { value: any; onChange: any; role: string; index: number }) {
    const context = useContext(global_context);
    const { version } = context.state;
    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: (props.index + 1) * 500 }}>
            <Stack
                direction={"column"}
                sx={{
                    borderRadius: 1,
                    outline: "2px solid white",
                    padding: "15px",
                    height: "18%",
                    width: "300px",
                }}
                spacing={1}
            >
                <SingleChampionSelect value={props.value} updateValue={props.onChange} updateContext={false} />
                <Stack
                    direction={"row"}
                    sx={{
                        width: "100%",
                        justifyContent: "space-evenly",
                        display: "flex",
                        alignItems: "center",
                        height: "55%",
                    }}
                >
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${props.role}.png`} height={"100%"} />
                    <Box component={"img"} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${props.value.id}.png`} height={"100%"} />
                </Stack>
            </Stack>
        </Grow>
    );
}

function DraftPrediction(props: { blueChampions: Champion[]; redChampions: Champion[]; patch: string; changePatch: any }) {
    const context = useContext(global_context);
    const { version } = context.state;
    const [data, setData] = useState(undefined as any);
    const roles = ["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"];
    const api = useReferentialContext().farsightApi.models;
    const [modelVersion, setModelVersion] = useState('v2');

    useEffect(() => {
        setData(undefined);
        if(modelVersion === 'v3') {
            api.evaluate_draft_v3({champions: props.blueChampions.map(c => c.id).concat(props.redChampions.map(c => c.id)).toString(), patch: props.patch}).then((data) => {
                console.log(data);
                setData(data)
            });
        }else{
            api.evaluate_draft({champions: props.blueChampions.map(c => c.id).concat(props.redChampions.map(c => c.id)).toString(), patch: props.patch}).then((data) => {
                console.log(data);
                setData(data)
            });
        }
    }, [props, modelVersion]);

    return data ? (
        <Stack
            sx={{
                width: "100%",
                height: "95%",
                borderRadius: 2,
                outline: "2px solid white",
                alignItems: "center",
                display: "flex",
                padding: "20px",
                justifyContent: "space-evenly",
                mt: 3,
            }}
            spacing={1}
        >
            <Stack direction={"row"} sx={{ justifyContent: "space-evenly", width: "100%" }}>
                <Stack
                    direction={"column"}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: win_color,
                        padding: "20px",
                    }}
                >
                    <Typography>Blue side win%</Typography>
                    <Typography>
                        {data.p_team1_wins.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            style: "percent",
                        })}
                    </Typography>
                    <Typography>Expected Performance</Typography>
                    <Typography>{`${data.team1_performance_avg.toFixed(2)} ± ${data.team1_performance_std.toFixed(2)}`}</Typography>
                </Stack>
                <PatchSelect value={props.patch} onChange={props.changePatch} />
                <Select value={modelVersion} onChange={(e) => setModelVersion(e.target.value)} sx={{ m: 1, width: "150px", mt: 3, height:'70px' }}>
                    <MenuItem value={'v2'}>V2</MenuItem>
                    <MenuItem value={'v3'}>V3</MenuItem>
                </Select>
                <Stack
                    direction={"column"}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: loss_color,
                        padding: "20px",
                    }}
                >
                    <Typography>Red side win%</Typography>
                    <Typography>
                        {data.p_team2_wins.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            style: "percent",
                        })}
                    </Typography>
                    <Typography>Expected Performance</Typography>
                    <Typography>{`${data.team2_performance_avg.toFixed(2)} ± ${data.team2_performance_std.toFixed(2)}`}</Typography>
                </Stack>
            </Stack>
            <DraftMatrix draftMatrix={data.draft_matrix} weights={data.combinations_weights} />
            <Stack direction={"row"} sx={{ justifyContent: "space-evenly", width: "100%" }}>
                <Stack direction={"row"} spacing={1}>
                    {props.blueChampions.map((champion: Champion, index: number) => (
                        <Tooltip
                            title={
                                <Stack>
                                    <Typography>{`Performance predicted : ${data.roles_predicted_performances[roles[index] + "_BLUE"]["Performance avg"].toFixed(2)}`}</Typography>
                                    <Typography>{`Performance variance : ${data.roles_predicted_performances[roles[index] + "_BLUE"]["Performance std"].toFixed(2)}`}</Typography>
                                    <Typography>{`Role weight : ${data.role_weights[index].toFixed(2)}`}</Typography>
                                    <Typography>{`After ponderation : ${(data.roles_predicted_performances[roles[index] + "_BLUE"]["Performance avg"] * data.role_weights[index]).toFixed(2)}`}</Typography>
                                </Stack>
                            }
                            followCursor={true}
                        >
                            <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                <Box component={"img"} width={"80px"} height={"80px"} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.id}.png`} />
                                <Typography>{(data.roles_predicted_performances[roles[index] + "_BLUE"]["Performance avg"] * data.role_weights[index]).toFixed(2)}</Typography>
                            </Stack>
                        </Tooltip>
                    ))}
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    {props.redChampions.map((champion: Champion, index: number) => (
                        <Tooltip
                            title={
                                <Stack>
                                    <Typography>{`Performance predicted : ${data.roles_predicted_performances[roles[index] + "_RED"]["Performance avg"].toFixed(2)}`}</Typography>
                                    <Typography>{`Performance variance : ${data.roles_predicted_performances[roles[index] + "_RED"]["Performance std"].toFixed(2)}`}</Typography>
                                    <Typography>{`Role weight : ${data.role_weights[index].toFixed(2)}`}</Typography>
                                    <Typography>{`After ponderation : ${(data.roles_predicted_performances[roles[index] + "_RED"]["Performance avg"] * data.role_weights[index]).toFixed(2)}`}</Typography>
                                </Stack>
                            }
                            followCursor={true}
                        >
                            <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                <Box component={"img"} width={"80px"} height={"80px"} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.id}.png`} />
                                <Typography>{(data.roles_predicted_performances[roles[index] + "_RED"]["Performance avg"] * data.role_weights[index]).toFixed(2)}</Typography>
                            </Stack>
                        </Tooltip>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    ) : (
        <Stack
            sx={{
                width: "100%",
                height: "95%",
                borderRadius: 2,
                outline: "2px solid white",
                m: 5,
            }}
        />
    );
}

function DraftMatrix(props: { draftMatrix: any; weights: any }) {
    return (
        <Box>
            <Stack direction={"row"} sx={{ width: "1100px", height: "50px" }}>
                <Paper
                    sx={{
                        width: "200px",
                        backgroundColor: win_color,
                        borderRadius: 0,
                    }}
                />
                {roles_ordered.map((role: string) => (
                    <MatrixLabel label={role} />
                ))}
            </Stack>
            {roles_ordered.map((role: string) => (
                <MatrixRow matrixRow={props.draftMatrix[role]} role={role} weights={props.weights[role]} />
            ))}
        </Box>
    );
}

function MatrixRow(props: { matrixRow: any; role: string; weights: any }) {
    return (
        <Stack direction={"row"} sx={{ width: "1100px", height: "50px" }}>
            <MatrixLabel label={props.role} />
            {roles_ordered.map((role: string) => (
                <MatrixCell value={props.matrixRow[role]} weight={props.weights[role]} />
            ))}
        </Stack>
    );
}

function MatrixCell(props: { value: any; weight?: number }) {
    const color = props.value["Performance avg"] > -50 && props.value["Performance avg"] < 50 ? "black" : "white";
    const weight = props.weight ? "\nW:" + props.weight.toFixed(2) : "";
    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: Math.random() * 2000 }}>
            <Tooltip
                title={
                    <Stack direction={"column"}>
                        <Typography>{`Performance average : ${props.value["Performance avg"].toFixed(2)}`}</Typography>
                        <Typography>{`Performance std : ${props.value["Performance std"].toFixed(2)}`}</Typography>
                        <Typography>{`Games : ${props.value["games"]}`}</Typography>
                        <Typography>{`Source : ${props.value["source"]}`}</Typography>
                        <Typography>{`After Ponderation : ${(props.value["Performance avg"] * (props.weight ? props.weight : 1)).toFixed(2)}`}</Typography>
                    </Stack>
                }
                followCursor={true}
            >
                <Paper
                    sx={{
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        borderRadius: 0,
                        typography: {
                            textAlign: "center",
                        },
                        backgroundColor: getColor(props.value["Performance avg"] * (props.weight ? props.weight : 1), -70, 70, [165, 42, 42], [68, 122, 255], [250, 249, 246]),
                        "&:hover": {
                            border: "2px solid black",
                            cursor: "pointer",
                        },
                    }}
                >
                    <Stack
                        direction={"column"}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Typography sx={{ color: { color } }}>{props.value["Performance avg"].toFixed(2)}</Typography>
                        <Typography sx={{ color: { color } }}>{weight}</Typography>
                    </Stack>
                </Paper>
            </Tooltip>
        </Grow>
    );
}

function MatrixLabel(props: { label: string }) {
    const role = props.label.split("_")[0];
    const side = props.label.split("_").slice(-1)[0].toLowerCase();
    const color = side === "red" ? loss_color : win_color;
    return (
        <Paper
            sx={{
                width: "141px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                borderRadius: 0,
                typography: {
                    textAlign: "center",
                },
                backgroundColor: color,
                color: "#FAF9F6",
            }}
        >
            <Typography>{role}</Typography>
        </Paper>
    );
}

function getColor(value: number, minValue: number, maxValue: number, minColor: number[], maxColor: number[], midColor: number[]) {
    // Calculate the mid-getValue as the average of the min and max values
    const midValue = (minValue + maxValue) / 2;

    // Determine which part of the gradient the getValue falls into
    let colorRange, valueRange;
    if (value <= midValue) {
        colorRange = [minColor, midColor];
        valueRange = [minValue, midValue];
    } else {
        colorRange = [midColor, maxColor];
        valueRange = [midValue, maxValue];
    }

    // Calculate the percentage of the getValue between the getValue range
    const percent = (value - valueRange[0]) / (valueRange[1] - valueRange[0]);

    // Interpolate the RGB components of the color using the color range and percent
    const red = Math.round(colorRange[0][0] + (colorRange[1][0] - colorRange[0][0]) * percent);
    const green = Math.round(colorRange[0][1] + (colorRange[1][1] - colorRange[0][1]) * percent);
    const blue = Math.round(colorRange[0][2] + (colorRange[1][2] - colorRange[0][2]) * percent);

    // Construct the CSS color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
}
