import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Bar, Line } from "react-chartjs-2";
import {targets, useReferentialContext} from "../../Context";
import {Fade, Select, Stack} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {ChartsReferenceLine, LineChart} from "@mui/x-charts";

export default function VariableDistribution(props: {paramObject: any, target: targets, metric: string, label: string, bucket_size: number, min: number, max: number,
    gaussianParameters?:{mean:number, std:number, label?:string, multiplier?:number, color?:string}[],
    poissonParameters?:{mean:number, label?:string, multiplier?:number, color?:string}[]}) {

    const [rows, setRows] = useState([] as any[]);
    const [references, setReferences] = useState(undefined as any | undefined);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const series = [
                {label: props.label, dataKey:'proportion'},
                ...props.gaussianParameters ? props.gaussianParameters.map(gp => {
                    return {label: gp.label, dataKey:gp.label, showMark:false}
                }) : []
            ];

    useEffect(() => {
        setRows([]);
        setReferences(undefined)
        const request_parameters = {
            ...props.paramObject,
            bucket_size: props.bucket_size,
            min: props.min,
            max: props.max,
            metric: props.metric,
        }
        api.variable_distribution(request_parameters).then((r: any) => {
            for(const i in r){
                for(const y in props.gaussianParameters){
                    // @ts-ignore
                    r[i][props.gaussianParameters[y].label] = densityNormal(r[i].bucket as number, props.gaussianParameters[y].mean, props.gaussianParameters[y].std) * props.bucket_size * props.gaussianParameters[y].multiplier
                }
            }
            setRows(r)
        });
        api.aggregate({
            ...props.paramObject,
            groupby: ['include_in_performance'],
            aggregates: [`${props.metric}__std,${props.metric}__avg,${props.metric}__avg__in_wins,${props.metric}__avg__in_losses,${props.metric}__std__in_wins,${props.metric}__std__in_losses`],
            ordering: ['include_in_performance']
        }).then(r => setReferences(r[0]))

    }, [props.paramObject.watermark, props.min, props.max, props.metric]);

    const densityNormal = (value: number, mean: number, stdev: number) => {
        const SQRT2PI = Math.sqrt(2 * Math.PI);
        stdev = (stdev == null) ? 1 : stdev;
        const z = (value - (mean || 0)) / stdev;
        return Math.exp(-0.5 * z * z) / (stdev * SQRT2PI);
    };


    return (
        <Stack direction={'row'}
            sx={{
                borderRadius: 3,
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <LineChart grid={{horizontal: true, vertical: true}} dataset={rows} series={series} xAxis={[{ data: rows.map((r: any, i:number) => r.bucket)}]}>
                {references !== undefined ? <ChartsReferenceLine x={references[`${props.metric}__avg`]} label={`${props.label} Mean\n\n${references[`${props.metric}__avg`].toFixed(1)}`} labelAlign="end"/> : <></>}
                {props.gaussianParameters ? props.gaussianParameters.map(gp => <ChartsReferenceLine x={gp.mean} label={`${gp.label}`} labelAlign="start" />) : <></>}
            </LineChart>
        </Stack>
    );
}
