/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    ScrimGameSummaries,
    ScrimGameSummariesAggregate,
} from "../models/index";

export interface ScrimGameSummariesAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    having?: object;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    index_on?: string;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    macrostats_ptr?: number;
    metrics?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface ScrimGameSummariesFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    macrostats_ptr?: number;
    participant_id?: number;
    patch?: string;
    performance?: number;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface ScrimGameSummariesGetRequest {
    id: string;
}

export interface ScrimGameSummariesListRequest {
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    annotations?: Array<string>;
    ban?: string;
    ban_rotation?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    macrostats_ptr?: number;
    ordering?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface ScrimGameSummariesVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    macrostats_ptr?: number;
    participant_id?: number;
    patch?: string;
    performance?: number;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

/**
 *
 */
export class ScrimGameSummariesApi extends runtime.BaseAPI {
    /**
     */
    async scrimGameSummariesAggregateRaw(
        requestParameters: ScrimGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimGameSummariesAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling scrimGameSummariesAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling scrimGameSummariesAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.ban_rotation !== undefined) {
            queryParameters["ban_rotation"] = requestParameters.ban_rotation;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.include_in_lane_performance !== undefined) {
            queryParameters["include_in_lane_performance"] =
                requestParameters.include_in_lane_performance;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.is_cp !== undefined) {
            queryParameters["is_cp"] = requestParameters.is_cp;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.lane_swap !== undefined) {
            queryParameters["lane_swap"] = requestParameters.lane_swap;
        }

        if (requestParameters.laneperformancescores_ptr !== undefined) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters.laneperformancescores_ptr;
        }

        if (requestParameters.macrostats_ptr !== undefined) {
            queryParameters["macrostats_ptr"] =
                requestParameters.macrostats_ptr;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.pick_rotation !== undefined) {
            queryParameters["pick_rotation"] = requestParameters.pick_rotation;
        }

        if (requestParameters.pickturn !== undefined) {
            queryParameters["pickturn"] = requestParameters.pickturn;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.teamwise_pickturn !== undefined) {
            queryParameters["teamwise_pickturn"] =
                requestParameters.teamwise_pickturn;
        }

        if (requestParameters.timelineblobs_ptr !== undefined) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters.timelineblobs_ptr;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimGameSummaries/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: ScrimGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimGameSummariesAggregate>> {
        const response = await this.scrimGameSummariesAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimGameSummariesFieldValuesRaw(
        requestParameters: ScrimGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling scrimGameSummariesFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling scrimGameSummariesFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.ban_rotation !== undefined) {
            queryParameters["ban_rotation"] = requestParameters.ban_rotation;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.include_in_lane_performance !== undefined) {
            queryParameters["include_in_lane_performance"] =
                requestParameters.include_in_lane_performance;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_cp !== undefined) {
            queryParameters["is_cp"] = requestParameters.is_cp;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.lane_swap !== undefined) {
            queryParameters["lane_swap"] = requestParameters.lane_swap;
        }

        if (requestParameters.laneperformancescores_ptr !== undefined) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters.laneperformancescores_ptr;
        }

        if (requestParameters.macrostats_ptr !== undefined) {
            queryParameters["macrostats_ptr"] =
                requestParameters.macrostats_ptr;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.pick_rotation !== undefined) {
            queryParameters["pick_rotation"] = requestParameters.pick_rotation;
        }

        if (requestParameters.pickturn !== undefined) {
            queryParameters["pickturn"] = requestParameters.pickturn;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.teamwise_pickturn !== undefined) {
            queryParameters["teamwise_pickturn"] =
                requestParameters.teamwise_pickturn;
        }

        if (requestParameters.timelineblobs_ptr !== undefined) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters.timelineblobs_ptr;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimGameSummaries/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: ScrimGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.scrimGameSummariesFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimGameSummariesGetRaw(
        requestParameters: ScrimGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScrimGameSummaries>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling scrimGameSummariesGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimGameSummaries/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: ScrimGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScrimGameSummaries> {
        const response = await this.scrimGameSummariesGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimGameSummariesListRaw(
        requestParameters: ScrimGameSummariesListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimGameSummaries>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.ban_rotation !== undefined) {
            queryParameters["ban_rotation"] = requestParameters.ban_rotation;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.include_in_lane_performance !== undefined) {
            queryParameters["include_in_lane_performance"] =
                requestParameters.include_in_lane_performance;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_cp !== undefined) {
            queryParameters["is_cp"] = requestParameters.is_cp;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.lane_swap !== undefined) {
            queryParameters["lane_swap"] = requestParameters.lane_swap;
        }

        if (requestParameters.laneperformancescores_ptr !== undefined) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters.laneperformancescores_ptr;
        }

        if (requestParameters.macrostats_ptr !== undefined) {
            queryParameters["macrostats_ptr"] =
                requestParameters.macrostats_ptr;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.pick_rotation !== undefined) {
            queryParameters["pick_rotation"] = requestParameters.pick_rotation;
        }

        if (requestParameters.pickturn !== undefined) {
            queryParameters["pickturn"] = requestParameters.pickturn;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.teamwise_pickturn !== undefined) {
            queryParameters["teamwise_pickturn"] =
                requestParameters.teamwise_pickturn;
        }

        if (requestParameters.timelineblobs_ptr !== undefined) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters.timelineblobs_ptr;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimGameSummaries/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: ScrimGameSummariesListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimGameSummaries>> {
        const response = await this.scrimGameSummariesListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimGameSummariesVariableDistributionRaw(
        requestParameters: ScrimGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling scrimGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling scrimGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling scrimGameSummariesVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling scrimGameSummariesVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.ally1 !== undefined) {
            queryParameters["ally1"] = requestParameters.ally1;
        }

        if (requestParameters.ally2 !== undefined) {
            queryParameters["ally2"] = requestParameters.ally2;
        }

        if (requestParameters.ally3 !== undefined) {
            queryParameters["ally3"] = requestParameters.ally3;
        }

        if (requestParameters.ally4 !== undefined) {
            queryParameters["ally4"] = requestParameters.ally4;
        }

        if (requestParameters.ally5 !== undefined) {
            queryParameters["ally5"] = requestParameters.ally5;
        }

        if (requestParameters.ban !== undefined) {
            queryParameters["ban"] = requestParameters.ban;
        }

        if (requestParameters.ban_rotation !== undefined) {
            queryParameters["ban_rotation"] = requestParameters.ban_rotation;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.champion !== undefined) {
            queryParameters["champion"] = requestParameters.champion;
        }

        if (requestParameters.champion_role_patch !== undefined) {
            queryParameters["champion_role_patch"] =
                requestParameters.champion_role_patch;
        }

        if (requestParameters.date !== undefined) {
            queryParameters["date"] = requestParameters.date;
        }

        if (requestParameters.direct_matchup !== undefined) {
            queryParameters["direct_matchup"] =
                requestParameters.direct_matchup;
        }

        if (requestParameters.enemy1 !== undefined) {
            queryParameters["enemy1"] = requestParameters.enemy1;
        }

        if (requestParameters.enemy2 !== undefined) {
            queryParameters["enemy2"] = requestParameters.enemy2;
        }

        if (requestParameters.enemy3 !== undefined) {
            queryParameters["enemy3"] = requestParameters.enemy3;
        }

        if (requestParameters.enemy4 !== undefined) {
            queryParameters["enemy4"] = requestParameters.enemy4;
        }

        if (requestParameters.enemy5 !== undefined) {
            queryParameters["enemy5"] = requestParameters.enemy5;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.include_in_lane_performance !== undefined) {
            queryParameters["include_in_lane_performance"] =
                requestParameters.include_in_lane_performance;
        }

        if (requestParameters.include_in_performance !== undefined) {
            queryParameters["include_in_performance"] =
                requestParameters.include_in_performance;
        }

        if (requestParameters.is_cp !== undefined) {
            queryParameters["is_cp"] = requestParameters.is_cp;
        }

        if (requestParameters.item1 !== undefined) {
            queryParameters["item1"] = requestParameters.item1;
        }

        if (requestParameters.item2 !== undefined) {
            queryParameters["item2"] = requestParameters.item2;
        }

        if (requestParameters.item3 !== undefined) {
            queryParameters["item3"] = requestParameters.item3;
        }

        if (requestParameters.keystone !== undefined) {
            queryParameters["keystone"] = requestParameters.keystone;
        }

        if (requestParameters.lane_swap !== undefined) {
            queryParameters["lane_swap"] = requestParameters.lane_swap;
        }

        if (requestParameters.laneperformancescores_ptr !== undefined) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters.laneperformancescores_ptr;
        }

        if (requestParameters.macrostats_ptr !== undefined) {
            queryParameters["macrostats_ptr"] =
                requestParameters.macrostats_ptr;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.participant_id !== undefined) {
            queryParameters["participant_id"] =
                requestParameters.participant_id;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.performance !== undefined) {
            queryParameters["performance"] = requestParameters.performance;
        }

        if (requestParameters.pick_rotation !== undefined) {
            queryParameters["pick_rotation"] = requestParameters.pick_rotation;
        }

        if (requestParameters.pickturn !== undefined) {
            queryParameters["pickturn"] = requestParameters.pickturn;
        }

        if (requestParameters.player !== undefined) {
            queryParameters["player"] = requestParameters.player;
        }

        if (requestParameters.relative_performance !== undefined) {
            queryParameters["relative_performance"] =
                requestParameters.relative_performance;
        }

        if (requestParameters.role !== undefined) {
            queryParameters["role"] = requestParameters.role;
        }

        if (requestParameters.side !== undefined) {
            queryParameters["side"] = requestParameters.side;
        }

        if (requestParameters.skill_order1 !== undefined) {
            queryParameters["skill_order1"] = requestParameters.skill_order1;
        }

        if (requestParameters.skill_order2 !== undefined) {
            queryParameters["skill_order2"] = requestParameters.skill_order2;
        }

        if (requestParameters.skill_order3 !== undefined) {
            queryParameters["skill_order3"] = requestParameters.skill_order3;
        }

        if (requestParameters.skill_order4 !== undefined) {
            queryParameters["skill_order4"] = requestParameters.skill_order4;
        }

        if (requestParameters.skill_order5 !== undefined) {
            queryParameters["skill_order5"] = requestParameters.skill_order5;
        }

        if (requestParameters.skill_order6 !== undefined) {
            queryParameters["skill_order6"] = requestParameters.skill_order6;
        }

        if (requestParameters.subtree !== undefined) {
            queryParameters["subtree"] = requestParameters.subtree;
        }

        if (requestParameters.summoner1 !== undefined) {
            queryParameters["summoner1"] = requestParameters.summoner1;
        }

        if (requestParameters.summoner2 !== undefined) {
            queryParameters["summoner2"] = requestParameters.summoner2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.team_relative_performance !== undefined) {
            queryParameters["team_relative_performance"] =
                requestParameters.team_relative_performance;
        }

        if (
            requestParameters.teamwise_adjusted_relative_performance !==
            undefined
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters.teamwise_adjusted_relative_performance;
        }

        if (requestParameters.teamwise_pickturn !== undefined) {
            queryParameters["teamwise_pickturn"] =
                requestParameters.teamwise_pickturn;
        }

        if (requestParameters.timelineblobs_ptr !== undefined) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters.timelineblobs_ptr;
        }

        if (requestParameters.win !== undefined) {
            queryParameters["win"] = requestParameters.win;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimGameSummaries/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: ScrimGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.scrimGameSummariesVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
