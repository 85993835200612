import React, { useContext, useEffect, useState } from "react";
import { Grid, Grow, Stack } from "@mui/material";
import PatchSelect from "../../../../components/selectors/PatchSelect";
import ChampionTierlist from "../../../../components/datagrids/Competitive/champions_tierlist";
import global_context, { api_url } from "../../../../static_vars";
import ServerSelect from "../../../../components/selectors/ServerSelect";
import ChampionTierlistSoloQ from "../../../../components/datagrids/SoloQ/champions_tierlist";
// import MultipleChampionSelect from "../../../../components/selectors/Shared/MultipleChampionSelect";
import MetaBreakersSoloQGrid from "../../../../components/datagrids/SoloQ/MetaBreakersSoloQGrid";
import RoleSelect from "../../../../components/selectors/RoleSelect";
import LeaguesSelect from "../../../../components/selectors/Competitive/LeagueSelect";
import MetaBreakersCompetitiveGrid from "../../../../components/datagrids/Competitive/MetaBreakersCompetitiveGrid";

export default function MetaBreakersCompetitive() {
    const context = useContext(global_context);
    const [state, setState] = useState({
        patch: context.state.current_patch.toString(),
        leagues: context.state.current_leagues.map((league: any) => league.league_name),
        champions: context.state.current_champions,
        role: context.state.current_role,
    });

    const handleLeaguesChange = (event: any, newValue: string[]) => {
        setState({ ...state, leagues: newValue });
    };
    const handlePatchChange = (event: any) => {
        setState({ ...state, patch: event.target.value });
    };
    const handleChampionsChange = (event: any, newValue: any) => {
        setState({ ...state, champions: newValue });
    };
    const handleRoleChange = (event: any) => {
        setState({ ...state, role: event.target.value });
    };

    return (
        <Grid
            container
            sx={{
                ml: "100px",
                width: window.innerWidth - 200 + "px",
                height: window.innerHeight - 220 + "px",
            }}
        >
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        mt: 5,
                        mb: 5,
                        height: "50px",
                        justifyContent: "left",
                        width: "100%",
                    }}
                    spacing={2}
                >
                    {/*<LeaguesSelect getValue={state.leagues} onChange={handleLeaguesChange}/>*/}
                    <PatchSelect value={state.patch} onChange={handlePatchChange} />
                    {/*<MultipleChampionSelect getValue={state.champions} onChange={handleChampionsChange}/>*/}
                    <RoleSelect value={state.role} onChange={handleRoleChange} />
                </Stack>
            </Grow>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction="row"
                    sx={{
                        height: "100%",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <MetaBreakersCompetitiveGrid patch={state.patch} leagues={state.leagues} champions_to_beat={state.champions} role_to_beat={state.role} target={"competitive"} />
                </Stack>
            </Grow>
        </Grid>
    );
}
