import { Box, Button, Checkbox, Collapse, darken, Fade, Grow, Link, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, {Context, useContext, useState} from "react";
import global_context from "../../static_vars";
import dayjs from "dayjs";
import { initMetric } from "web-vitals/dist/modules/lib/initMetric";
import FarsightApi from "../../ApiWrapper";
import {Champion, targets, useReferentialContext} from "../../Context";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ChampionIcon from "../Icons/ChampionIcon";

const StyledDatagrid = styled(DataGrid)(() => ({
    zIndex: 0,
    justify: "center",
    "*::-webkit-scrollbar": {
        width: "0.2em",
    },
    "*::-webkit-scrollbar-track": {
        background: "#e1e1e1e",
    },
    "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
    },
    "*::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
})) as typeof DataGrid;

export default StyledDatagrid;

export function formatRating(rating: any) {
    if (rating.value === null) return "";
    if (rating.value !== -1000) {
        return Math.round(rating.value * 100) / 100;
    } else {
        return "";
    }
}
function formatOutcome(result: boolean) {
    if (result) {
        return "Win";
    }
    return "Loss";
}

function DownloadReplayButton(props: {params: any}){
    const gid = props.params.row.game_id ? props.params.row.game_id : props.params.row.game;
    const ctx = useReferentialContext();
    return <Fade
        in={true}
        style={{ transformOrigin: "0 0 0" }}
        {...{
            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
        }}
    >
    <Button
        variant={"outlined"}
        onClick={() => ctx.farsightApi.download_file('Downloads/GameReplay',
            {game_id:gid, target: targets.competitive},
            `${dayjs(props.params.row.date).format('MMM DD')} - ${props.params.row.team1} vs ${props.params.row.team2}.rofl`)}
    >{`Replay`}</Button>
    </Fade>

}

export const ColumnTypes: {
    [id: string]: (arg0?: any, arg1?: any, arg2?: any, arg3?: any) => GridColDef;
} = {
    champion: (fieldName: string = "champion", headerName: string = "Champion", other_props: any | null = null): GridColDef => {
        // const ctx = useReferentialContext();
        return {
            field: fieldName,
            headerName: headerName,
            hideable: false,
            renderCell: (params: any) => (
                    <ChampionIcon champion={params.value} role={params.row.role}/>
            ),
            minWidth: 40,
            maxWidth: 45,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            ...other_props,
        };
    },
    short_int: (fieldName: string = "shortInt", headerName: string = "shortInt", other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            flex: 1,
            minWidth: 30,
            maxWidth: 60,
            headerAlign: "center",
            align: "center",
            type: "number",
            renderCell: (params: any) => {
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Box>{params.value}</Box>
                    </Fade>
                );
            },
            ...other_props,
        };
    },
    percentage: (fieldName: string = "percent", headerName: string = "percent", other_props: any | null = null): GridColDef => {
        return {
            field: fieldName,
            headerName: headerName,
            description: other_props ? other_props.description : null,
            flex: 1,
            minWidth: 60,
            headerAlign: "center",
            type: "number",
            renderCell: (params: any) => {
                let value = params.value;
                if (params.value === true) value = 1;
                if (params.value === false) value = 0;

                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Box>
                            {value !== undefined && value !== null
                                ?  `${(value * 100).toFixed(1)}%` : ""}
                        </Box>
                    </Fade>
                );
            },
            align: "center",
            ...other_props,
        };
    },
    rating: (fieldName: string = "rating", headerName: string = "rating", other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            flex: 1,
            minWidth: 60,
            headerAlign: "center",
            type: "number",
            renderCell: (params: any) => {
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Box>{formatRating(params)}</Box>
                    </Fade>
                );
            },
            align: "center",
            ...other_props,
            description: other_props ? other_props.description : null,
        };
    },
    delta_rating: (fieldName: string = "delta", headerName: string = "Delta", other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            flex: 1,
            minWidth: 60,
            headerAlign: "center",
            type: "number",
            renderCell: (params: any) => {
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Stack direction={'row'} sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                            {params.value > 0 ? <KeyboardDoubleArrowUpIcon sx={{color:'lightgreen'}}/> : <KeyboardDoubleArrowDownIcon sx={{color:'red'}}/>}
                            <Box sx={{color: params.value > 0 ? 'lightgreen' : 'red'}}>{params.value ? `${params.value > 0 ? '+' : ''}${params.value.toFixed(2)}` : ''}</Box>
                        </Stack>
                    </Fade>
                );
            },
            align: "center",
            ...other_props,
            description: other_props ? other_props.description : null,
        };
    },
    delta_percentage: (fieldName: string = "delta", headerName: string = "Delta", other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            flex: 1,
            minWidth: 60,
            headerAlign: "center",
            type: "number",
            renderCell: (params: any) => {
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Stack direction={'row'} sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                            {params.value == 0 ? <></> : params.value > 0 ? <KeyboardDoubleArrowUpIcon sx={{color:'lightgreen'}}/> : <KeyboardDoubleArrowDownIcon sx={{color:'red'}}/>}
                            <Box sx={{color: params.value == 0 ? 'white' : params.value > 0 ? 'lightgreen' : 'red'}}>{params.value !== null ? `${params.value > 0 ? '+' : ''}${(params.value*100).toFixed(2)}%` : ''}</Box>
                        </Stack>
                    </Fade>
                );
            },
            align: "center",
            ...other_props,
            description: other_props ? other_props.description : null,
        };
    },
    asset: (fieldName: string = "keystone", headerName: string = "Keystone", other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 40,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            renderCell: (params: any) => {
                if (params.value && params.value !== "")
                    return (
                        <Fade
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{
                                timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                            }}
                        >
                            <Tooltip title={params.value}>
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/leagueAssets/${params.value}.png`} />
                            </Tooltip>
                        </Fade>
                    );
            },
            ...other_props,
        };
    },
    described: (fieldName: string, headerName: string, descField: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 40,
            flex: 1,
            align: "center",
            headerAlign: "center",
            type: "number",
            renderCell: (params: any) => {
                return (
                    <Tooltip title={params.row[descField]} arrow>
                        <Box>{formatRating(params)}</Box>
                    </Tooltip>
                );
            },
        };
    },
    role: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 40,
            maxWidth: 45,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            renderCell: (params: any) => (
                ['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'].includes(params.value) ? <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                    {<Box component={"img"}
                          src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/leagueAssets/${params.value}.png`}/>}
                </Fade> : <></>
            ),
            ...other_props,
        };
    },
    player: (fieldName: string, headerName: string): GridColDef => {
        return {
            field: fieldName,
            headerName: headerName,
            hideable: false,
            renderCell: (params: any) => (
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                    <Box>{params.value ? params.value.split("(")[0] : ""}</Box>
                </Fade>
            ),
            flex: 3,
            minWidth: 55,
            maxWidth: 120,
            align: "center",
            headerAlign: "center",
        };
    },
    game_asset: (fieldName: string, headerName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 40,
            maxWidth: 42,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) => {
                if (params.value !== "")
                    return (
                        <Fade in={true} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                            <Box component={"img"} src={"https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/" + params.value + ".png"} width={40} height={40} />
                        </Fade>
                    );
            },
        };
    },
    string: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            align: "center",
            headerAlign: "center",
            flex: 1,
            ...other_props,
        };
    },
    calc_string: (operation: any, fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            align: "center",
            headerAlign: "center",
            flex: 1,
            ...other_props,
        };
    },
    team: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            field: fieldName,
            headerName: headerName,
            renderCell: (params: any) =>
                params.value ? (
                    <Fade in={true} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                        <Tooltip title={params.value} followCursor={true}>
                            <Box
                                component={"img"}
                                src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/TeamsLogo/${params.value}.png`}
                                // width={40}
                                // height={40}
                                sx={{
                                    filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                                }}
                            />
                        </Tooltip>
                    </Fade>
                ) : (
                    ""
                ),
            hideable: false,
            minWidth: 40,
            maxWidth: 50,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            ...other_props,
        };
    },
    league: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            field: fieldName,
            headerName: headerName,
            renderCell: (params: any) =>
                params.value ? (
                    <Fade in={true} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                        <Typography>{params.value}</Typography>
                    </Fade>
                ) : (
                    ""
                ),
            hideable: false,
            flex: 1,
            minWidth: 40,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            ...other_props,
        };
    },
    kda: (): GridColDef => {
        return {
            headerName: "KDA",
            field: "kills",
            minWidth: 70,
            maxWidth: 70,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) => {
                return (
                    <Fade in={true} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                        <Box>{params.row.kills + " / " + params.row.deaths + " / " + params.row.assists}</Box>
                    </Fade>
                );
            },
        };
    },
    win: (fieldName: string, headerName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            width: 60,
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: (params: any) => <Fade in={true} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}><Box>{formatOutcome(params.value)}</Box></Fade>,
            hideable: true,
        };
    },
    account: (fieldName: string, headerName: string, server: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            flex: 1,
            minWidth: 160,
            maxWidth: 160,
            headerAlign: "center",
            align: "center",
            renderCell: (params: any) => (
                <Stack
                    direction={"column"}
                    sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "space-between",
                        display: "flex",
                        height:'100%'
                    }}
                >
                    <Typography sx={{width: "100%", height:'50%'}}>{params.value}</Typography>
                    <Stack
                        direction={"row"}
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            align: 'center',
                            display: 'flex',
                            height:'50%'
                        }}
                        spacing={1}
                    >
                        <Tooltip title={"deeplol"} followCursor={true}>
                            <Link href={"https://www.deeplol.gg/summoner/" + params.row.server.replace("1", "") + "/" + params.value} target="_blank" sx={{justifyContent: "center",
                                align: 'center',
                                display: 'flex',}}>
                                <Box
                                    component={"img"}
                                    src="../icons/deeplol.ico"
                                    alt="Deeplol"
                                />
                            </Link>
                        </Tooltip>
                        <Tooltip title={"op.gg"} followCursor={true}>
                            <Link href={"https://www.op.gg/summoners/" + params.row.server.replace("1", "") + "/" + params.value} target="_blank"
                                  sx={{justifyContent: "center",
                                      align: 'center',
                                      display: 'flex',}}>
                                <Box
                                    component={"img"}
                                    src="../icons/opgg.ico"
                                    alt="op.gg"
                                />
                            </Link>
                        </Tooltip>
                    </Stack>
                </Stack>
            ),
        }
    },
    date: (fieldName: string, headerName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 80,
            maxWidth: 120,
            align: "center",
            display:'flex',
            headerAlign: "center",
            renderCell: (params: any) => (
                <Fade
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{
                        timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                    }}
                >

                    <Typography sx={{ textAlign: "center" }}>
                        <pre
                            style={{
                                fontFamily: "inherit",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {params.value ? dayjs(params.value).format(dayjs(params.value).year() === dayjs().year() ? "MMM DD\nHH:mm" : "MMM DD YYYY\nHH:mm") : ''}
                        </pre>
                    </Typography>

                </Fade>
            ),
        };
    },
    year_date: (fieldName: string, headerName: string, otherProps: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 120,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            hideable: false,
            renderCell: (params: any) =>
                params.value ? (
                    <Typography>
                        <pre
                            style={{
                                fontFamily: "inherit",
                                textAlign: "center",
                            }}
                        >
                            {dayjs(params.value).format("MMM DD\nYYYY")}
                        </pre>
                    </Typography>
                ) : (
                    ""
                ),
            ...otherProps,
        };
    },
    duration: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 50,
            maxWidth: 50,
            align: "center",
            justify: "center",
            display:'flex',
            headerAlign: "center",
            renderCell: (params: any) => {
                const [hours, minutes, seconds] = params.value.split(":").map(Number);
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Typography>
                            {dayjs
                                .duration({
                                    hours,
                                    minutes,
                                    seconds,
                                })
                                .format("m:ss")}
                        </Typography>
                    </Fade>
                );
            },
            ...other_props,
            description: other_props ? other_props.description : null,
        };
    },
    duration_from_seconds: (fieldName: string, headerName: string, other_props: any | null = null): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 50,
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: (params: any) => {
                const seconds = params.value;
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Typography>
                            {dayjs.duration(seconds, "seconds").format("m:ss")}
                        </Typography>
                    </Fade>
                );
            },
            ...other_props,
            description: other_props ? other_props.description : null,
        };
    },
    vod: (fieldName: string, headerName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 60,
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: (params: any) => {
                if (params.value)
                    return (
                        <Fade
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{
                                timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                            }}
                        >
                            <Tooltip title={params.value}>
                                <Link href={params.value} target="_blank" style={{ color: "white" }}>
                                    <Button variant={"outlined"}>VOD</Button>
                                </Link>
                            </Tooltip>
                        </Fade>
                    );
            },
        };
    },
    replay: (fieldName: string, headerName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth: 110,
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: (params: any) => {
                if (params.row.replayFile || params.row.replay)
                    return (
                            <Tooltip title={"Click to download replay"} followCursor={true}>
                                <DownloadReplayButton params={params}/>
                            </Tooltip>
                    );
                return <></>;
            },
        };
    },
    team_champions: (fieldName: string, headerName: string, champions_displayed?: number): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            minWidth:  champions_displayed ? champions_displayed * 40 : 220 ,
            maxWidth: champions_displayed ? champions_displayed * 40 : 220,
            align: "center",
            display:'flex',
            flex: 1,
            headerAlign: "center",
            renderCell: (params: any) => {
                return (
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            {params.value ? params.value.split(",").map((champion: string) => (
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${champion}.png`} />
                            )) : <></>}
                        </Stack>
                    </Fade>
                );
            },
        };
    },
    boolean: (exp: any, headerName: string, fieldName: string): GridColDef => {
        return {
            headerName: headerName,
            field: fieldName,
            align: "center",
            headerAlign: "center",
            minWidth: 60,
            maxWidth: 60,
            flex: 1,
            renderCell: (params: any) => <Checkbox checked={exp(params.row)} />,
        };
    },
    elo : (field='elo'): GridColDef => {return {
        field: field,
        headerName: "Elo",
        flex: 1,
        minWidth: 130,
        maxWidth: 130,
        headerAlign: "center",
        align: "center",
        renderCell: (params: any) =>
            params.value ? (
                <Fade
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{
                        timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                    }}
                >
                    <Stack
                        direction={"row"}
                        sx={{
                            height: "100%",
                            width: "100%",
                            justifyContent: "center",
                            align:'center',
                            display: "flex"
                        }}
                    >
                        <Box component={"img"} src={`../icons/emblem-${params.value.split(" ")[0].toLowerCase()}.png`}
                             sx={{ transform: "scale(2)"}} />
                        <Typography
                            sx={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >{`${params.value.split(" ")[2].toLowerCase()}`}</Typography>
                    </Stack>
                </Fade>
            ) : (
                <></>
            ),
    }},
    elo_from_total_lps : (field='elo'): GridColDef => {return {
        field: field,
        headerName: "Elo",
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
        headerAlign: "center",
        align: "center",
        renderCell: (params: any) =>
            params.value ? (
                <Fade
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{
                        timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                    }}
                >
                    <Stack
                        direction={"row"}
                        sx={{
                            height: "100%",
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        {/*<Box component={"img"} src={`../icons/emblem-${params.value.split(" ")[0].toLowerCase()}.png`} sx={{ transform: "scale(2)" }} />*/}
                        <Typography
                            sx={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >{`${(params.value - 3100).toFixed(0)} LP`}</Typography>
                    </Stack>
                </Fade>
            ) : (
                <></>
            ),
    }},
    nationality: (field='player__nationality'): GridColDef => {return {
        field: field,
        headerName: "Nationality",
        minWidth: 70,
        maxWidth: 70,
        align: "center",
        display:'flex',
        headerAlign: "center",
        hideable: false,
        renderCell: (params: any) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [flagUrl, setFlagUrl] = useState(undefined as string|undefined);
            const apiUrl = `https://restcountries.com/v3.1/name/${params.value}?fullText=true`;
            fetch(apiUrl).then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const flagUrl = data[0].flags.svg;
                        setFlagUrl(flagUrl);
                    } else {
                        console.log(`Country ${params.value} not found.`);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching country data:", error);
                });

            return params.value ? (
                <Fade
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{
                        timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                    }}
                >
                    <Tooltip title={params.value}>
                        <Box component={"img"} src={flagUrl} sx={{width:'70px', height:'40px'}}/>
                    </Tooltip>
                </Fade>
            ) : (
                <></>
            )
        }
    }},
};

export function intToOrdinal(number: number) {
    if (10 <= number % 100 && number % 100 <= 20) {
        return number + "th";
    } else {
        const lastDigit = number % 10;
        const suffix = lastDigit === 1 ? "st" : lastDigit === 2 ? "nd" : lastDigit === 3 ? "rd" : "th";
        return number + suffix;
    }
}

export function ranked_stat(metric: any): GridColDef {
    const renderValue = {
        rating: (params: any) => formatRating(params),
        percentage: (params: any) => (params.value !== undefined && params.value !== null ? params.value.toLocaleString("en", { style: "percent" }) : ""),
        short_int: (params: any) => params.value,
    };

    return {
        headerName: metric.short,
        field: metric.code,
        minWidth: 50,
        align: "center",
        flex: 1,
        headerAlign: "center",
        renderCell: (params: any) => (
            <Fade
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...{
                    timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                }}
            >
                <Typography>
                    {
                        // @ts-ignore
                        `${renderValue[metric.type](params)} - (${intToOrdinal(params.row[`rank_${metric.code}`])})`
                    }
                </Typography>
            </Fade>
        ),
    };
}

export const field_name_to_column: { [id: string]: GridColDef } = {
    league: ColumnTypes.league("league", "League"),
    date: ColumnTypes.date("date", "Date"),
    team1: ColumnTypes.team("team1", "Team"),
    account__player__current_team: ColumnTypes.team("account__player__current_team", "Team"),
    player__current_team: ColumnTypes.team("player__current_team", "Team"),
    team2: ColumnTypes.team("team2", "Vs"),
    patch: ColumnTypes.short_int("patch", "P."),
    duration: ColumnTypes.duration("duration", "D."),
    winner: ColumnTypes.team("winner", "Win"),
    team1champions: ColumnTypes.team_champions("team1champions", "C. Blue"),
    matchups: ColumnTypes.team_champions("matchups", "Matchups"),
    team2champions: ColumnTypes.team_champions("team2champions", "C. Red"),
    game_id: ColumnTypes.replay("game_id", "ROFL"),
    replay: ColumnTypes.replay("game_id", "ROFL"),
    vodGameStart: ColumnTypes.vod("vodGameStart", "VOD"),
    vod: ColumnTypes.vod("vod", "VOD"),
    drafting_sim_team1_pred: ColumnTypes.percentage("drafting_sim_team1_pred", "Pred Blue"),
    drafting_sim_team2_pred: ColumnTypes.percentage("drafting_sim_team2_pred", "Pred Red"),
    drafting_sim_v3_team1_pred: ColumnTypes.percentage("drafting_sim_v3_team1_pred", "Pred Blue V3"),
    drafting_sim_v3_team2_pred: ColumnTypes.percentage("drafting_sim_v3_team2_pred", "Pred Red V3"),
    champion: ColumnTypes.champion("champion", "C."),
    champion_TOP_LANE: ColumnTypes.champion("champion_TOP_LANE", "Top"),
    champion_JUNGLE: ColumnTypes.champion("champion_JUNGLE", "Jungle"),
    champion_MID_LANE: ColumnTypes.champion("champion_MID_LANE", "Mid"),
    champion_BOT_LANE: ColumnTypes.champion("champion_BOT_LANE", "Adc"),
    champion_UTILITY: ColumnTypes.champion("champion_UTILITY", "Supp"),
    matchup_TOP_LANE: ColumnTypes.champion("matchup_TOP_LANE", "Vs"),
    matchup_JUNGLE: ColumnTypes.champion("matchup_JUNGLE", "Vs"),
    matchup_MID_LANE: ColumnTypes.champion("matchup_MID_LANE", "Vs"),
    matchup_BOT_LANE: ColumnTypes.champion("matchup_BOT_LANE", "Vs"),
    matchup_UTILITY: ColumnTypes.champion("matchup_UTILITY", "Vs"),
    direct_matchup: ColumnTypes.champion("direct_matchup", "Vs"),
    winrate: ColumnTypes.percentage("winrate", "WR"),
    Matchup_winrate: ColumnTypes.percentage("Matchup_winrate", "WR"),
    Matchup_gd15: ColumnTypes.delta_rating("Matchup_gd15", "GD@15"),
    Matchup_csd15: ColumnTypes.delta_rating("Matchup_csd15", "CSD@15"),
    Matchup_kp15: ColumnTypes.rating("Matchup_kp15", "K+A@15"),
    gd15: ColumnTypes.delta_rating("gd15", "GD@15"),
    csd15: ColumnTypes.delta_rating("csd15", "CSD@15"),
    kp15: ColumnTypes.rating("kp15", "K+A@15"),
    Synergy_winrate: ColumnTypes.percentage("Synergy_winrate", "WR"),
    competitive_winrate: ColumnTypes.percentage("competitive_winrate", "WR"),
    soloq_winrate: ColumnTypes.percentage("soloq_winrate", "WR"),
    banrate: ColumnTypes.percentage("banrate", "BR"),
    team_winrate: ColumnTypes.percentage("team_winrate", "WR"),
    games: ColumnTypes.short_int("games", "#"),
    Matchup_games: ColumnTypes.short_int("Matchup_games", "#"),
    Synergy_games: ColumnTypes.short_int("Synergy_games", "#"),
    in_meta_games: ColumnTypes.short_int("in_meta_games", "#"),
    competitive_games: ColumnTypes.short_int("competitive_games", "#"),
    soloq_games: ColumnTypes.short_int("soloq_games", "#"),
    team_games: ColumnTypes.short_int("team_games", "#"),
    cp_frequency: ColumnTypes.percentage("cp_frequency", "CP%"),
    rating: ColumnTypes.rating("rating", "R."),
    projected_competitive_rating: ColumnTypes.rating("projected_competitive_rating", "Comp."),
    team_rel_rate: ColumnTypes.rating("team_rel_rate", "R."),
    ally1: ColumnTypes.champion("ally1", "Top"),
    ally2: ColumnTypes.champion("ally2", "Jng"),
    ally3: ColumnTypes.champion("ally3", "Mid"),
    ally4: ColumnTypes.champion("ally4", "Bot"),
    ally5: ColumnTypes.champion("ally5", "Sup"),
    enemy1: ColumnTypes.champion("enemy1", "Top"),
    enemy2: ColumnTypes.champion("enemy2", "Jng"),
    enemy3: ColumnTypes.champion("enemy3", "Mid"),
    enemy4: ColumnTypes.champion("enemy4", "Bot"),
    enemy5: ColumnTypes.champion("enemy5", "Sup"),
    performance: ColumnTypes.rating("performance", "P."),
    teamwise_adjusted_relative_performance: ColumnTypes.rating("teamwise_adjusted_relative_performance", "TRP."),
    Matchup_performance: ColumnTypes.rating("Matchup_performance", "P."),
    Synergy_performance: ColumnTypes.rating("Synergy_performance", "P."),
    competitive_performance: ColumnTypes.rating("competitive_performance", "P."),
    soloq_performance: ColumnTypes.rating("soloq_performance", "P."),
    performance_with: ColumnTypes.rating("performance_with", "Ally P."),
    performance_against: ColumnTypes.rating("performance_against", "Enemy P."),
    relative_performance: ColumnTypes.rating("relative_performance", "RP."),
    Matchup_relative_performance: ColumnTypes.rating("Matchup_relative_performance", "RP."),
    Synergy_relative_performance: ColumnTypes.rating("Synergy_relative_performance", "RP."),
    competitive_relative_performance: ColumnTypes.rating("competitive_relative_performance", "RP."),
    soloq_relative_performance: ColumnTypes.rating("soloq_relative_performance", "RP."),
    relative_performance_with: ColumnTypes.rating("relative_performance_with", "Ally RP."),
    relative_performance_against: ColumnTypes.rating("relative_performance_against", "Enemy RP."),
    keystone: ColumnTypes.asset("keystone", "K.",{maxWidth:45}),
    rune1: ColumnTypes.asset("rune1", "R1"),
    rune2: ColumnTypes.asset("rune2", "R2"),
    rune3: ColumnTypes.asset("rune3", "R3"),
    rune4: ColumnTypes.asset("rune4", "R4"),
    rune5: ColumnTypes.asset("rune5", "R5"),
    item1: ColumnTypes.asset("item1", "I1", {maxWidth:45}),
    item2: ColumnTypes.asset("item2", "I2",{maxWidth:45}),
    item3: ColumnTypes.asset("item3", "I3",{maxWidth:45}),
    item4: ColumnTypes.asset("item4", "I4",{maxWidth:45}),
    item5: ColumnTypes.asset("item5", "I5",{maxWidth:45}),
    item6: ColumnTypes.asset("item6", "I6",{maxWidth:45}),
    boots: ColumnTypes.asset("boots", "Boots",{maxWidth:45}),
    role: ColumnTypes.asset("role", "R."),
    account__player__role: ColumnTypes.asset("account__player__role", "R."),
    player: ColumnTypes.player("player", "P."),
    account__player: ColumnTypes.player("account__player", "P."),
    competitive_player: ColumnTypes.player("competitive_player", "P."),
    accountName: ColumnTypes.account("accountName", "Account"),
    account__accountName: ColumnTypes.account("account__accountName", "Account"),
    kda: ColumnTypes.kda(),
    elo: ColumnTypes.elo(),
    account__elo: ColumnTypes.elo('account__elo'),
    server: ColumnTypes.string('server','S.'),
    season_wins: ColumnTypes.short_int('season_wins','Wins'),
    season_losses: ColumnTypes.short_int('season_losses','Losses'),
    last_tracking_update: ColumnTypes.date('last_tracking_update','Last update'),
    most_recent_games: ColumnTypes.date('most_recent_games','Most recent games'),
    date__max: ColumnTypes.date('date__max','Last game'),
    tracked: ColumnTypes.boolean((row: any) => row.tracked, 'Tracked'),
    drafting_sim_pred_accurate: ColumnTypes.boolean((row: any) => row.drafting_sim_pred_accurate, 'Pred Res', 'drafting_sim_pred_accurate'),
    drafting_sim_v3_pred_accurate: ColumnTypes.boolean((row: any) => row.drafting_sim_v3_pred_accurate, 'Pred Res', 'drafting_sim_v3_pred_accurate'),
    pre_game_odds_team1: ColumnTypes.percentage("pre_game_odds_team1", "Odds Blue"),
    pre_game_odds_team2: ColumnTypes.percentage("pre_game_odds_team2", "Odds Red"),
    expected_winrate: ColumnTypes.percentage("expected_winrate", "EWR"),
    pre_game_pred_accurate: ColumnTypes.boolean((row: any) => row.pre_game_pred_accurate, 'Odds Acc', 'pre_game_pred_accurate'),
    account__player__birthdate: ColumnTypes.date('account__player__birthdate','Birthdate'),
    account__player__nationality: ColumnTypes.nationality('account__player__nationality'),
    champion_pool: ColumnTypes.team_champions('champion_pool', 'Champions')
};
