import {CompetitiveGameSummaries} from "../../farsight-api";
import React, {useEffect, useState} from "react";
import {targets, useReferentialContext} from "../../Context";
import Box from "@mui/material/Box";
import {HighlightScope, pieArcLabelClasses, PieChart} from "@mui/x-charts";
import { PieCenterLabel } from "./ChampionsPie";


export default function StatSharePie(props: {paramObject: any, stat: string, target: targets}){
    const context = useReferentialContext();
    const api = context.farsightApi.getSummariesApi(props.target);
    const [data, setData] = useState(undefined as any[] | undefined);
    const [highlighted, setHighlighted] = useState('item');
    const [faded, setFaded] = useState('global');
    const [statSum, setStatSum] = useState(0);

    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: 'player',
            metrics: ['dmg_share,gold_share,games'],
            ordering: "participant_id__avg",
            format: "json",
            aggregates: ['damages__sum,total_golds__sum,total_cs__sum,participant_id__avg,deaths__sum,time_spent_dead__sum']
        };
        api.aggregate(request_parameters).then((result: any[]) => {
            console.log(result);
            setData(result)
            const nss = result.reduce((accumulator: number, row: any) => accumulator + row[props.stat], 0);
            console.log(nss);
            setStatSum(nss);
        })
    }, [props]);

    const labels = {
        'damages__sum' : "Dmg%",
        'total_golds__sum' : "Gold%",
        'total_cs__sum' : "CS%",
        'deaths__sum' : "Deaths%",
        'time_spent_dead__sum' : "TSD%",
    }

    return data ?
        <Box sx={{width:'100%', height:'100%'}}>
            <PieChart
                series={[
                    {
                        type: 'pie',
                        innerRadius: 100,
                        outerRadius: 150,
                        data: data.map((e: any) => {return {label: `${e.player.split('(')[0]}`, value: (e[props.stat] / e.games)}}),
                        highlighted: { additionalRadius: 10 },
                        highlightScope: {
                            highlighted,
                            faded,
                        } as HighlightScope,
                        paddingAngle: 2,
                        cornerRadius: 5,
                        arcLabel: (item) => `${item.label}`,
                        arcLabelMinAngle: 20,
                    },
                    {
                        type: 'pie',
                        innerRadius: 50,
                        outerRadius: 100,
                        data: data.map((e: any) => {return {label: `${(100 * e[props.stat] / statSum).toFixed(1)}%`, value: (100 * e[props.stat] / statSum)}}),
                        highlighted: { additionalRadius: 10 },
                        highlightScope: {
                            highlighted,
                            faded,
                        } as HighlightScope,
                        paddingAngle: 2,
                        cornerRadius: 5,
                        arcLabel: (item) => `${item.label}`,
                        arcLabelMinAngle: 20,
                    },
                ]}
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                slotProps={{
                    legend: {
                        hidden: true,
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                        labelStyle: {
                            fontSize: 14,
                            fill: 'blue',
                        },
                    },

                }}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                    },
                }}
            >
                {/*@ts-ignore*/}
            <PieCenterLabel>{labels[props.stat]}</PieCenterLabel>
            </PieChart>
        </Box> : <></>
}