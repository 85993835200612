import {Fade, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {targets, useReferentialContext} from "../../Context";


export default function ChampionIcon(props: {champion: string, role:string, sx? :any}){
    const ctx = useReferentialContext();
    let details_page = ctx.currentTarget === targets.competitive ? 1 : ctx.currentTarget === targets.soloq ? 18 : 1
    return <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000)}} >
                <Tooltip title={"Check details"} followCursor={true}>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.champion}.png`}
                onClick={() => {
                    ctx.setSelectedRole(props.role);
                    ctx.setSelectedChampion(ctx.championsReferential.filter(c => c.id===props.champion)[0]);
                    ctx.setCurrentTab(details_page);
                }} sx={{cursor: 'pointer'}}/>
                </Tooltip>
    </Fade>

}