import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import React, {useEffect, useState} from "react";
import {targets, useReferentialContext} from "../../../Context";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Grow, Stack} from "@mui/material";
import {League, Team} from "../../../farsight-api";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import {FooterExporter} from "../../../components/datagrids/shared/TableDisplayGrid";
import StyledDatagrid, {ColumnTypes} from "../../../components/datagrids/styled_datagrid";
import MapDrawer from "../../../components/maps/map_drawer";
import dayjs from "dayjs";


export default function FindGameplayElements(){
    const [paramObject, setParamObject] = useState({} as any);
    const [tab, setTab] = useState(0);
    const [team, setTeam] = useState(null as Team|null);
    const [leagues, setLeagues] = useState([] as League[]);
    const [year, setYear] = useState(dayjs().year());


    return <WindowContainer direction={'column'} spacing={1}>
        <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} team={team}
                   setTeam={setTeam} leagues={leagues} setLeagues={setLeagues} year={year} setYear={setYear}/>
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Tabs value={tab} onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
                <Tab label="2v2 Mid/Jungle" {...a11yProps(0)} />
                <Tab label="3v3 Mid/Jungle/Support" {...a11yProps(1)} />
            </Tabs>
        </Grow>
        <TabPanel value={tab} index={0}>
            <MidJungle2v2sMidSection paramObject={paramObject}/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <MidJungleSup3v3sMidSection paramObject={paramObject}/>
        </TabPanel>
    </WindowContainer>
}

function MidJungle2v2sMidSection(props: {paramObject: any}){
    const context = useReferentialContext();
    const [data, setData] = useState([] as any[]);


    useEffect(() => {
        context.farsightApi.competitiveGameSummaries.list(
            {...props.paramObject, role: 'MID_LANE', ordering:"-date", additional_filters: {...props.paramObject.additional_filters, mid_jungle_2v2_kills__gte:1}}
        ).then( result =>
            {
                let list = [] as any[];
                for(const i in result){
                    const game = result[i];
                    console.log(game);
                    console.log(i)
                    for(const y in game.mid_jungle_2v2_kills_events){
                        const event = game.mid_jungle_2v2_kills_events[y]
                        const new_row = {
                            type: "KILL",
                            league: game.league,
                            date: game.date,
                            team1: game.team1,
                            versus: game.team2,
                            victim: event.champion_killed,
                            champion: event.champion_killer,
                            mid_jungle: `${game.champion},${game.ally2}`,
                            vs_mid_jungle: `${game.enemy3},${game.enemy2}`,
                            player: game.player,
                            gameTime: event.timestamp,
                            vod: game.vod?.includes('t=') ? game.vod?.split('?')[0].concat('?t=').concat((parseInt(game.vod?.split('t=')[1]) + parseInt(event.timestamp) - 40).toString()) : game.vod,
                            ...event
                        }
                        console.log(new_row)
                        list.push(new_row)
                    }
                }
                setData(list)
            }
        )
    }, [props.paramObject.watermark]);

    const columns = [
        ColumnTypes.league('league','League'),
        ColumnTypes.date('date','Date'),
        ColumnTypes.team("team1","T."),
        ColumnTypes.team("versus","Vs."),
        ColumnTypes.team_champions('mid_jungle','Mid / Jungle', 2),
        ColumnTypes.team_champions('vs_mid_jungle','VS', 2),
        ColumnTypes.duration_from_seconds('timestamp','TS'),
        ColumnTypes.vod('vod','VOD')
    ]

    return <Stack direction={'row'} sx={{height:'90%'}} spacing={1}>
        <StyledDatagrid
            autoPageSize
            rowHeight={50}
            getRowId={(row) => data.indexOf(row)}
            // experimentalFeatures={{ columnGrouping: true }}
            columns={columns}
            rows={data}
            hideFooter={false}
            // loading={loading}
            // rowBuffer={3}
            pagination
            slots={{
                footer: FooterExporter as any,
            }}
            slotProps={{
                footer: {
                    data: data,
                    columns: columns
                } as any,
            }}
        />
        <MapDrawer points={data} />
    </Stack>
}

function MidJungleSup3v3sMidSection(props: {paramObject: any}){
    const context = useReferentialContext();
    const [data, setData] = useState([] as any[]);


    useEffect(() => {
        context.farsightApi.competitiveGameSummaries.list(
            {...props.paramObject, role: 'MID_LANE', ordering:"-date", additional_filters: {...props.paramObject.additional_filters, mid_jungle_sup_3v3_kills__gte:1}}
        ).then( result =>
            {
                let list = [] as any[];
                for(const i in result){
                    const game = result[i];
                    console.log(game);
                    console.log(i)
                    for(const y in game.mid_jungle_sup_3v3_kills_events){
                        const event = game.mid_jungle_sup_3v3_kills_events[y]
                        const new_row = {
                            type: "KILL",
                            league: game.league,
                            date: game.date,
                            team1: game.team1,
                            versus: game.team2,
                            victim: event.champion_killed,
                            champion: event.champion_killer,
                            mid_jungle_sup: `${game.champion},${game.ally2},${game.ally5}`,
                            vs_mid_jungle_sup: `${game.enemy3},${game.enemy2},${game.enemy5}`,
                            player: game.player,
                            gameTime: event.timestamp,
                            vod: game.vod?.includes('t=') ? game.vod?.split('?')[0].concat('?t=').concat((parseInt(game.vod?.split('t=')[1]) + parseInt(event.timestamp) - 40).toString()) : game.vod,
                            ...event
                        }
                        console.log(new_row)
                        list.push(new_row)
                    }
                }
                setData(list)
            }
        )
    }, [props.paramObject.watermark]);

    const columns = [
        ColumnTypes.league('league','League'),
        ColumnTypes.date('date','Date'),
        ColumnTypes.team("team1","T."),
        ColumnTypes.team("versus","Vs."),
        ColumnTypes.team_champions('mid_jungle_sup','Mid / Jungle / Sup', 3),
        ColumnTypes.team_champions('vs_mid_jungle_sup','VS', 3),
        ColumnTypes.duration_from_seconds('timestamp','TS'),
        ColumnTypes.vod('vod','VOD')
    ]

    return <Stack direction={'row'} sx={{height:'90%'}} spacing={1}>
        <StyledDatagrid
            autoPageSize
            rowHeight={50}
            getRowId={(row) => data.indexOf(row)}
            // experimentalFeatures={{ columnGrouping: true }}
            columns={columns}
            rows={data}
            hideFooter={false}
            // loading={loading}
            // rowBuffer={3}
            pagination
            slots={{
                footer: FooterExporter as any,
            }}
            slotProps={{
                footer: {
                    data: data,
                    columns: columns
                } as any,
            }}
        />
        <MapDrawer points={data} />
    </Stack>
}