import {targets, useReferentialContext} from "../../Context";
import React, {SetStateAction, useEffect, useState} from "react";
import {CompetitiveChampionRatings, SoloQChampionRating} from "../../farsight-api";
import WindowContainer from "../../components/layout/WindowContainer";
import PatchSelect from "../../components/selectors/PatchSelect";
import {Box, Checkbox, Select, Stack, Typography} from "@mui/material";
import RoleSelect from "../../components/selectors/RoleSelect";
import StyledDatagrid, {ColumnTypes} from "../../components/datagrids/styled_datagrid";
import {FooterExporter} from "../../components/datagrids/shared/TableDisplayGrid";
import {DataGridProps, GridColDef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {Line} from "react-chartjs-2";
import MenuItem from "@mui/material/MenuItem";
import {blueberryTwilightPalette, blueberryTwilightPaletteDark, LineChart, mangoFusionPalette} from "@mui/x-charts";

export function SoloQChampionRatingsEvolution(){
    const [selectedChamps, setSelectedChamps] = useState([] as string[]);
    const [role, setRole] = useState('MID_LANE' as string);

    return <WindowContainer direction={'row'} spacing={1}>
        <Box sx={{width:'60%', height:'100%'}}>
            <ChampionRatingsEvolution2Patches target={targets.soloq} paramObject={{servers:'EUW1,KR'}} selectedChamps={selectedChamps} setSelectedChamps={setSelectedChamps}
            role={role} setRole={setRole}/>
        </Box>
        <Box sx={{width:'40%', height:'100%'}}>
            <SelectedChampionRatingEvolutionInYear selectedChamps={selectedChamps} paramObject={{servers:'EUW1,KR'}} target={targets.soloq} role={role}/>
        </Box>
    </WindowContainer>
}


export function SelectedChampionRatingEvolutionInYear(props:{target: targets, paramObject: any, selectedChamps: string[], role: string}){
    const [dataByChampion, setDataByChampion] = useState(new Map<string, (CompetitiveChampionRatings|SoloQChampionRating)[]>() as Map<string, (CompetitiveChampionRatings|SoloQChampionRating)[]>);
    const patches = useReferentialContext().patchesReferential.filter(p => p.includes(`${dayjs().year() - 2010}.`)).reverse().map(p => p.toString())
    const api = useReferentialContext().farsightApi.getRatingsApi(props.target);
    const [metric, setMetric] = useState('rel_rate');
    const [series, setSeries] = useState([] as any[]);

    useEffect(() => {
        const params = {
            ...props.paramObject,
            role: props.role,
            additional_filters: {
                champion__in: [props.selectedChamps.toString()],
                patch__startswith: '14.'
            }
        }
        api.list(params).then((result) => {
            let new_data = new Map<string, (CompetitiveChampionRatings|SoloQChampionRating)[]>();
            for(let i in result){
                let rating = result[i];
                let current_list = new_data.get(rating.champion)  || [];
                current_list.push(rating);
                current_list.sort((a, b) => {
                    let a_version = a.patch.split('.')[1];
                    let b_version = b.patch.split('.')[1];
                    return parseInt(a_version) - parseInt(b_version);
                })
                new_data.set(rating.champion, current_list);
            }
            setDataByChampion(new_data);
        });
    }, [props.paramObject, props.selectedChamps]);

    useEffect(() => {
            const new_series = props.selectedChamps.map((champion, index) => {
                const ratings = dataByChampion.get(champion) || [];
                if(ratings.length === 0){
                    return undefined;
                }
                return {
                    label: champion,
                    data: ratings.map((r: any) => r[metric]),
                    id: (index+1).toString(),
                    color: blueberryTwilightPalette('dark')[index],
                }
            }).filter(d => d) as any[];
            setSeries(new_series);
        },
        [dataByChampion, metric])

    const metric_to_y_axis = {
        rel_rate: { id: 'linearAxis', scaleType: 'linear', min:-10, max: 30},
        pickrate: { id: 'linearAxis', scaleType: 'linear', min:0, max: 0.3},
        presence: { id: 'linearAxis', scaleType: 'linear', min:0, max: 1},
        banrate: { id: 'linearAxis', scaleType: 'linear', min:0, max: 1},
        winrate: { id: 'linearAxis', scaleType: 'linear', min:0.35, max: 0.65},
    } as any;

    return <Stack direction={'column'} sx={{height:'90%', width:'100%', mt:'60px'}} spacing={1}>
        <Select value={metric} onChange={(event) => setMetric(event.target.value)} sx={{width:'150px'}}>
            <MenuItem value={'rel_rate'}>Rating</MenuItem>
            <MenuItem value={'pickrate'}>Pickrate</MenuItem>
            <MenuItem value={'presence'}>Presence</MenuItem>
            <MenuItem value={'banrate'}>Banrate</MenuItem>
            <MenuItem value={'winrate'}>Winrate</MenuItem>
        </Select>
        <LineChart
            // xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }]}
            xAxis={[{ data: patches, scaleType: 'point' }]}
            series={series}
            yAxis={[
                metric_to_y_axis[metric]
            ]}
            skipAnimation={false}
        />
    </Stack>

}

export function ChampionRatingsEvolution2Patches(props: { target: targets, paramObject: any, selectedChamps: string[], setSelectedChamps: any, role: string, setRole: any }) {
    const [patch1, setPatch1] = useState(undefined as string | undefined);
    const [patch2, setPatch2] = useState(undefined as string | undefined);
    const api = useReferentialContext().farsightApi.getRatingsApi(props.target);

    const [patch1done, setPatch1done] = useState(false);
    const [patch2done, setPatch2done] = useState(false);
    const [patch1data, setPatch1data] = useState([] as (SoloQChampionRating | CompetitiveChampionRatings)[])
    const [patch2data, setPatch2data] = useState([] as (SoloQChampionRating | CompetitiveChampionRatings)[])
    const [finalData, setFinalData] = useState([] as any[])

    useEffect(() => {
        if(patch1 && patch2) {
            let params1 = {
                ...props.paramObject,
                role: props.role,
                patch: patch1,
            }
            let params2 = {
                ...props.paramObject,
                role: props.role,
                patch: patch2,
                additional_filters: {
                    games__gte: 200
                }
            }
            api.list(params1).then(r => {
                setPatch1data(r);
                setPatch1done(true);
            });
            api.list(params2).then(r => {
                setPatch2data(r);
                setPatch2done(true);
            })
        }
    }, [
        patch1, patch2, props.role
    ])

    useEffect(() => {
            if (patch1done && patch2done) {
                let new_final_data = []
                for (let i in patch2data) {
                    const patch_2_item = patch2data[i];
                    const patch_1_items = patch1data.filter(r => r.champion === patch_2_item.champion)
                    const item = {
                        patch2: patch_2_item,
                        patch1: patch_1_items.length > 0 ? patch_1_items[0] : undefined
                    }
                    new_final_data.push({
                        champion: item.patch2.champion,
                        patch1_pickrate: item.patch1 ? item.patch1.pickrate : 0,
                        patch1_games: item.patch1 ? item.patch1.games : 0,
                        patch1_winrate: item.patch1 ? item.patch1.winrate : 0,
                        patch1_rating: item.patch1 ? item.patch1.rel_rate : 0,
                        patch2_pickrate: item.patch2 ? item.patch2.pickrate : 0,
                        patch2_games: item.patch2 ? item.patch2.games : 0,
                        patch2_winrate: item.patch2 ? item.patch2.winrate : undefined,
                        patch2_rating: item.patch2 ? item.patch2.rel_rate : undefined,
                        delta_pickrate: item.patch1 && item.patch2.pickrate && item.patch1.pickrate  ? item.patch2.pickrate - item.patch1.pickrate : undefined,
                        delta_winrate: item.patch1 && item.patch2.winrate && item.patch1.winrate ? item.patch2.winrate - item.patch1.winrate : undefined,
                        delta_rating: item.patch1 && item.patch2.rel_rate && item.patch1.rel_rate ? item.patch2.rel_rate - item.patch1.rel_rate : undefined,
                    });
                }
                setPatch1done(false);
                setPatch2done(false);
                setFinalData(new_final_data);
            }
        },
        [patch1done, patch2done]
    )

    const columns = [ColumnTypes.champion("champion", "C."),
        ColumnTypes.percentage("patch1_pickrate", "PR%"),
        ColumnTypes.rating("patch1_games", "#"),
        ColumnTypes.percentage("patch1_winrate", "WR"),
        ColumnTypes.rating("patch1_rating", "R."),
        ColumnTypes.percentage("patch2_pickrate", "PR%"),
        ColumnTypes.rating("patch2_games", "#"),
        ColumnTypes.percentage("patch2_winrate", "WR"),
        ColumnTypes.rating("patch2_rating", "R."),
        ColumnTypes.delta_percentage("delta_pickrate", "PR%"),
        ColumnTypes.delta_percentage("delta_winrate", "WR"),
        ColumnTypes.delta_rating("delta_rating", "R."),
        {
        headerName: 'S.',
        field: 'select',
        flex: 1,
        minWidth: 10,
        headerAlign: "center",
        type: "number",
        renderCell: (params: any) => {
            return (
                <Checkbox onChange={(event, checked) => {
                    if(!checked){
                        let new_champions = props.selectedChamps.flat();
                        new_champions.splice(props.selectedChamps.indexOf(params.row.champion), 1);
                        props.setSelectedChamps(new_champions);
                    }else{
                        let new_champions = props.selectedChamps.flat();
                        new_champions.push(params.row.champion);
                        props.setSelectedChamps(new_champions);
                    }
                }} checked={props.selectedChamps.includes(params.row.champion)}/>
            );
        },
        align: "center"}
    ] as GridColDef[];

    const columnGroupingModel= [
        {
            groupId: patch1 ? patch1 : "Patch1",
            children: [{ field: "patch1_pickrate" },{ field: "patch1_games" }, { field: "patch1_winrate" }, { field: "patch1_rating" }],
            headerClassName: "header",
        },
        {
            groupId: patch2 ? patch2 : "Patch2",
            children: [{ field: "patch2_pickrate" },{ field: "patch2_games" }, { field: "patch2_winrate" }, { field: "patch2_rating" }],
            headerClassName: "header",
        },
        {
            groupId: "Delta",
            children: [{ field: "delta_pickrate" }, { field: "delta_winrate" }, { field: "delta_rating" }],
            headerClassName: "header",
        },

    ]


    return <Stack direction={'column'} spacing={1} sx={{height:'100%'}}>
        <Stack direction={'row'} spacing={1} sx={{height:'60px'}}>
            <RoleSelect value={props.role} onChange={props.setRole}/>
            <PatchSelect value={patch1} onChange={(event: any, newValue: SetStateAction<string | undefined>) => setPatch1(newValue)}/>
            <PatchSelect value={patch2} onChange={(event: any, newValue: SetStateAction<string | undefined>) => setPatch2(newValue)}/>
        </Stack>
        <Stack direction={'row'} sx={{height:'100%'}}>
            <StyledDatagrid
                autoPageSize
                rowHeight={50}
                getRowId={(row) => finalData.indexOf(row)}
                // experimentalFeatures={{ columnGrouping: true }}
                columns={columns}
                rows={finalData}
                hideFooter={false}
                // loading={loading}
                pagination
                slots={{
                    footer: FooterExporter as any,
                }}
                slotProps={{
                    footer: {
                        data: finalData,
                        columns: columns,
                        handleCapture: () => false
                    } as any,
                }}
                columnGroupingModel={columnGroupingModel}
            />
        </Stack>
    </Stack>

}