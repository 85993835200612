import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../Styles/listbox.css";
import { useReferentialContext } from "../../../Context";
import { MultipleSelectorGrouped } from "../../Abstract/Multiple";
import { GameMetrics } from "../../../farsight-api";
import {targets} from "../../../Context";

export default function MultipleMetricsSelect(props: { values: GameMetrics[]; updateValue: (newValue: GameMetrics[]) => void; updateContext?: boolean | undefined; target: targets }) {
    const { metricsReferential, selectedMetrics, setSelectedMetrics } = useReferentialContext();

    const [localMetrics, setLocalMetrics] = useState(props.values);
    // console.log(metricsReferential);
    const updateSelection = (newSelection: GameMetrics[]) => {
        if (props.updateContext) {
            setSelectedMetrics(newSelection);
        } else {
            setLocalMetrics(newSelection);
        }
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "350px" , height:'57px'}}>
            <MultipleSelectorGrouped
                getGroup={(option: GameMetrics) => (option.group ? option.group : "Other")}
                getOptionLabel={(option: GameMetrics) => (option.label ? option.label : option.code)}
                getOptionShort={(option: GameMetrics) => (option.short_name ? option.short_name : option.code)}
                limitTags={3}
                label={"Metrics"}
                options={metricsReferential}
                selected={props.updateContext ? selectedMetrics : localMetrics}
                updateSelection={updateSelection}
                optionDisabled={(option) => (option.available_for ? !option.available_for.includes(props.target) : false)}
                optionDescription={(option) => option.desc}
            />
        </Box>
    );
}
