import Stack from "@mui/material/Stack";
import {Fade, Grow, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import StatRow from "./StatRow";
import React from "react";

export default function StatsSummary(props: {data: any, championRatings?:any}) {
    const statsOverview = props.data


    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                display: "flex",
                width: "230px",
            }}
        >
            <Stack
                direction={"column"}
                sx={{
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"Metagame"}
                    </Typography>
                </Fade>
                <StatRow stat={"Nb. Games"} value={statsOverview["games"]} />
                {/*<StatRow stat={"Pickrate"} getValue={(statsOverview["games"] / statsOverview["large_perimeter"]).toLocaleString("en", { style: "percent" })} />*/}
                <StatRow stat={"Bans"} value={statsOverview["bans"]} />
                {/*<StatRow stat={"Ban%"} getValue={(statsOverview["bans"] / statsOverview["large_perimeter"]).toLocaleString("en", { style: "percent" })} />*/}
                {/*<StatRow stat={"Presence"} getValue={((data["bans"] + data["games"]) / data["large_perimeter"]).toLocaleString("en", { style: "percent" })} />*/}
                <StatRow stat={"WR"} value={statsOverview["winrate"].toLocaleString("en", {style: "percent"})}/>
                {props.championRatings ? <StatRow stat={"Rating"} value={Math.round(props.championRatings["rating"] * 100) / 100}/> : <></>}
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={0}>
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 3000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"In Game"}
                    </Typography>
                </Fade>
                <StatRow stat={"Perf."} value={Math.round(statsOverview["performance__avg"] * 100) / 100} />
                <StatRow stat={"Perf. STD"} value={Math.round(statsOverview["performance__std"] * 100) / 100} />
                <StatRow stat={"RPerf."} value={Math.round(statsOverview["relative_performance__avg"] * 100) / 100} />
                <StatRow stat={"RPerf. STD"} value={Math.round(statsOverview["relative_performance__std"] * 100) / 100} />
                <StatRow stat={"relativeKills"} value={Math.round(statsOverview["relativeKills_score"] * 100) / 100} />
                <StatRow stat={"relativeDeaths"} value={Math.round(statsOverview["relativeDeaths_score"] * 100) / 100} />
                <StatRow stat={"relativeDamages"} value={Math.round(statsOverview["relativeDamages_score"] * 100) / 100} />
                <StatRow stat={"relativeDamageTaken"} value={Math.round(statsOverview["relativeDamageTaken_score"] * 100) / 100} />
                <StatRow stat={"relativeGolds"} value={Math.round(statsOverview["relativeGolds_score"] * 100) / 100} />
                <StatRow stat={"relativeDmgHealed"} value={Math.round(statsOverview["relativeDmgHealed_score"] * 100) / 100} />
                <StatRow stat={"relativeDmgMitigated"} value={Math.round(statsOverview["relativeDmgMitigated_score"] * 100) / 100} />
                <StatRow stat={"relativeWardsPlaced"} value={Math.round(statsOverview["relativeWardsPlaced_score"] * 100) / 100} />
                <StatRow stat={"relativeWardsKilled"} value={Math.round(statsOverview["relativeWardsKilled_score"] * 100) / 100} />
                <StatRow stat={"relativeLevel"} value={Math.round(statsOverview["relativeLevel_score"] * 100) / 100} />
                <StatRow stat={"relativeCCtime"} value={Math.round(statsOverview["relativeCCtime_score"] * 100) / 100} />
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={0}>
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 4000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"Laning"}
                    </Typography>
                </Fade>
                <StatRow stat={"GD@15"} value={Math.round(statsOverview["gd15"] * 100) / 100} />
                <StatRow stat={"XPD@15"} value={Math.round(statsOverview["xpd15"] * 100) / 100} />
                <StatRow stat={"CSD@15"} value={Math.round(statsOverview["csd15"] * 100) / 100} />
            </Stack>
        </Stack>
    );
}

export function PlayerStatsSummary(props: {data: any}) {
    const statsOverview = props.data


    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                display: "flex",
                width: "230px",
            }}
        >
            <Stack
                direction={"column"}
                sx={{
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"Metagame"}
                    </Typography>
                </Fade>
                <StatRow stat={"Nb. Games"} value={statsOverview["games"]} />
                <StatRow stat={"WR"} value={statsOverview["winrate"].toLocaleString("en", {style: "percent"})}/>
                <StatRow stat={"CP%"} value={(statsOverview["cp_frequency"]).toLocaleString("en", { style: "percent" })} />
                <StatRow stat={"KDA"} value={statsOverview["kda"].toFixed(2)} />
                <StatRow stat={"DPM"} value={statsOverview["dpm"].toFixed(2)} />
                <StatRow stat={"GPM"} value={statsOverview["gpm"].toFixed(2)} />
                <StatRow stat={"CSPM"} value={statsOverview["cspm"].toFixed(2)} />
                <StatRow stat={"Deaths / Game"} value={statsOverview["deaths__sum__per_game"].toFixed(2)} />
                <StatRow stat={"Deaths / Win"} value={statsOverview["deaths__avg__in_wins"].toFixed(2)} />
                <StatRow stat={"Deaths / Loose"} value={statsOverview["deaths__avg__in_losses"].toFixed(2)} />
                <StatRow stat={"Solokills / G"} value={statsOverview["solo_kills__avg"].toFixed(2)} />
                <StatRow stat={"SoloDeaths / G"} value={statsOverview["solo_deaths__avg"].toFixed(2)} />
                <StatRow stat={"Wards / 10m"} value={statsOverview["vision_wards_bought__sum__per_10_minute"].toFixed(2)} />
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={0}>
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 3000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"In Game"}
                    </Typography>
                </Fade>
                <StatRow stat={"Perf."} value={Math.round(statsOverview["performance__avg"] * 100) / 100} />
                <StatRow stat={"Perf. STD"} value={Math.round(statsOverview["performance__std"] * 100) / 100} />
                <StatRow stat={"RPerf."} value={Math.round(statsOverview["relative_performance__avg"] * 100) / 100} />
                <StatRow stat={"RPerf. STD"} value={Math.round(statsOverview["relative_performance__std"] * 100) / 100} />
                <StatRow stat={"relativeKills"} value={Math.round(statsOverview["relativeKills_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeDeaths"} value={Math.round(statsOverview["relativeDeaths_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeDamages"} value={Math.round(statsOverview["relativeDamages_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeDamageTaken"} value={Math.round(statsOverview["relativeDamageTaken_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeGolds"} value={Math.round(statsOverview["relativeGolds_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeDmgHealed"} value={Math.round(statsOverview["relativeDmgHealed_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeDmgMitigated"} value={Math.round(statsOverview["relativeDmgMitigated_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeWardsPlaced"} value={Math.round(statsOverview["relativeWardsPlaced_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeWardsKilled"} value={Math.round(statsOverview["relativeWardsKilled_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeLevel"} value={Math.round(statsOverview["relativeLevel_score__avg"] * 100) / 100} />
                <StatRow stat={"relativeCCtime"} value={Math.round(statsOverview["relativeCCtime_score__avg"] * 100) / 100} />
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={0}>
                <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 4000 }}>
                    <Typography
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: 3,
                            outline: "1px solid white",
                        }}
                    >
                        {"Laning"}
                    </Typography>
                </Fade>
                <StatRow stat={"GD@8"} value={Math.round(statsOverview["gd8"] * 100) / 100} />
                <StatRow stat={"CSD@8"} value={Math.round(statsOverview["csd8"] * 100) / 100} />
                <StatRow stat={"GD@15"} value={Math.round(statsOverview["gd15"] * 100) / 100} />
                <StatRow stat={"XPD@15"} value={Math.round(statsOverview["xpd15"] * 100) / 100} />
                <StatRow stat={"CSD@15"} value={Math.round(statsOverview["csd15"] * 100) / 100} />
                <StatRow stat={"FWD%"} value={statsOverview["forward_percentage_pre_15__avg"].toLocaleString("en", {style: "percent"})}/>
            </Stack>
        </Stack>
    );
}