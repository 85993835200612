import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Grow, Stack, Switch, Typography } from "@mui/material";

export default function OverviewDetailed(props: { value: boolean; onChange: any }) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.checked);
    };

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
            <Stack
                direction="row"
                sx={{
                    height: "55px",
                    zIndex: 0,
                    width: "200px",
                    border: "1px solid grey",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: 1,
                    justifyContent: "center",
                    paddingLeft: 2,
                    paddingRight: 2,
                }}
            >
                <Typography>Overview</Typography>
                <Switch checked={props.value} onChange={handleChange} />
                <Typography>Detailed</Typography>
            </Stack>
        </Grow>
    );
}
