import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormControl, InputLabel} from "@mui/material";


export default function LPThresholdSelect(props: {value: number, onChange: (newValue: number) => void}){
    return <FormControl sx={{ width: "150px", height: "57px", zIndex: 0 }}>
        <InputLabel>Min LP</InputLabel>
    <Select value={props.value} onChange={event => props.onChange(event.target.value as number)} label={"Min LP"}>
        <MenuItem value={0}>All</MenuItem>
        <MenuItem value={3100}>Above Master</MenuItem>
        <MenuItem value={3400}>300 LP (Mid Master)</MenuItem>
        <MenuItem value={3700}>600 LP (Grandmaster MMR)</MenuItem>
        <MenuItem value={4100}>1000 LP (Challenger MMR)</MenuItem>
    </Select>
    </FormControl>
}