import { useContext, useEffect, useState } from "react";
import global_context, { api_url } from "../../../static_vars";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import FarsightApi from "../../../ApiWrapper";

export default function MetaBreakersCompetitiveGrid(props: any) {
    const [rows, setRows] = useState([] as any[]);
    const [loading, setLoading] = useState(false);
    const context = useContext(global_context);
    const { version, ddragon_champions } = context.state;

    useEffect(() => {
        setLoading(true);
        FarsightApi.get_request("MetaBreakers", props)
            .then((data) => setRows(data))
            .then(() => setLoading(false));
    }, [props]);

    let grid_columns: GridColDef[] = [ColumnTypes.champion(version, "champion", "C."), ColumnTypes.role("role", "Role")];
    let champion_columns: GridColDef[][] = props.champions_to_beat.map((champion: string) => {
        return [
            ColumnTypes.short_int(`Games_against_${champion}`, "#", {
                cellClassName: "border-left",
            }),
            ColumnTypes.percentage(`Winrate_against_${champion}`, "WR"),
            ColumnTypes.rating(`Performance_against_${champion}`, "P."),
            ColumnTypes.rating(`Relative_Performance_against_${champion}`, "RP."),
        ];
    });
    let champ_columns = champion_columns.flat();

    let total_columns: GridColDef[] = [
        ColumnTypes.short_int(`Total_Games`, "#", {
            cellClassName: "border-left",
        }),
        ColumnTypes.rating("Wheighted_Average_Performance", "P."),
        ColumnTypes.rating("Wheighted_Average_Relative_Performance", "RP."),
    ];

    grid_columns = [...grid_columns, ...champ_columns, ...total_columns];

    let columnGroupingModel = [
        {
            groupId: "Total",
            children: [{ field: "Total_Games" }, { field: "Wheighted_Average_Performance" }, { field: "Wheighted_Average_Relative_Performance" }],
            headerClassName: "header",
        },
    ];
    const championGroupingModel = props.champions_to_beat.map((champion: string) => {
        return {
            groupId: `Vs ${ddragon_champions[champion].name}`,
            children: [{ field: `Winrate_against ${champion}` }, { field: `Performance_against ${champion}` }, { field: `Relative_Performance_against ${champion}` }, { field: `Games_against_${champion}` }],
            headerClassName: "header",
        };
    });
    columnGroupingModel = [...columnGroupingModel, ...championGroupingModel];

    return (
        <StyledDatagrid
            rowHeight={60}
            sx={{
                width: "100%",
                "& .header": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                },
                "& .border-left": {
                    borderLeft: "solid grey",
                    borderWidth: "2px",
                },
            }}
            getRowId={(row) => rows.indexOf(row)}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: "Wheighted_Average_Performance",
                            sort: "desc",
                        },
                    ],
                },
            }}
            columns={grid_columns}
            rows={rows}
            hideFooter={true}
            columnGroupingModel={columnGroupingModel}
            // experimentalFeatures={{ columnGrouping: true }}
            loading={loading}
        />
    );
}
