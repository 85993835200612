import Menu from "@mui/material/Menu";
import React from "react";
import { Avatar, Divider, IconButton, ListItemIcon, Paper } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import Box from "@mui/material/Box";
import {useReferentialContext} from "../../Context";

export default function ProfileButton(props: {logout: () => void}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [open, setOpen] = React.useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose: any = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const logout: any = () => {
        props.logout()
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // onBlur={handleClose}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClick={handleClose}
                // onBlur={handleClose}
            >
                <Paper>
                    <MenuItem onClick={handleClose}>
                        <Avatar sx={{ mr: 1 }} /> Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Avatar sx={{ mr: 1 }} /> My account
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Add another account
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    <MenuItem onClick={props.logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Paper>
            </Menu>
        </React.Fragment>
    );
}
