import Slider from "@mui/material/Slider";
import * as React from "react";
import Box from "@mui/material/Box";
import {useState} from "react";


export default function SelectIntervalBetween(props: {value: number[], onChange: any, min:number, max:number}){
    const [value, setValue] = useState(props.value);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };
    return <Box sx={{border:'1px solid white', width:'300px', paddingLeft:'20px', paddingRight:'20px'}}>
            <Slider
                getAriaLabel={() => "Timestamp range"}
                min={props.min}
                max={props.max}
                step={100}
                value={value}
                onChange={handleChange}
                onChangeCommitted={(event, value) => props.onChange(value)}
                valueLabelDisplay="auto"
            />
        </Box>
}