import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { DesktopTimePicker, LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";

export default function DatePicker(props: { onChange: any; value: Dayjs; label: string }) {
    return (
        <Box sx={{ width: 200 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker label={props.label} inputFormat="DD / MM / YYYY" value={props.value} onChange={props.onChange} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
        </Box>
    );
}

export function TimePicker(props: { onChange: any; value: Dayjs; label: string }) {
    return (
        <Box sx={{ width: 200 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker label={props.label} value={props.value} onChange={props.onChange} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
        </Box>
    );
}
