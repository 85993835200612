/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveTeamfight,
    CompetitiveTeamfightAggregate,
} from "../models/index";

export interface CompetitiveTeamfightAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    game?: string;
    having?: object;
    index_on?: string;
    looser_team?: string;
    metrics?: Array<string>;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightGetRequest {
    id: string;
}

export interface CompetitiveTeamfightListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    ordering?: Array<string>;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

/**
 *
 */
export class CompetitiveTeamfightApi extends runtime.BaseAPI {
    /**
     */
    async competitiveTeamfightAggregateRaw(
        requestParameters: CompetitiveTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveTeamfightAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling competitiveTeamfightAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling competitiveTeamfightAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.looser_team !== undefined) {
            queryParameters["looser_team"] = requestParameters.looser_team;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.objective_killer_team !== undefined) {
            queryParameters["objective_killer_team"] =
                requestParameters.objective_killer_team;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.winner_team !== undefined) {
            queryParameters["winner_team"] = requestParameters.winner_team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveTeamfightAggregate>> {
        const response = await this.competitiveTeamfightAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightFieldValuesRaw(
        requestParameters: CompetitiveTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling competitiveTeamfightFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling competitiveTeamfightFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.looser_team !== undefined) {
            queryParameters["looser_team"] = requestParameters.looser_team;
        }

        if (requestParameters.objective_killer_team !== undefined) {
            queryParameters["objective_killer_team"] =
                requestParameters.objective_killer_team;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.winner_team !== undefined) {
            queryParameters["winner_team"] = requestParameters.winner_team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.competitiveTeamfightFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightGetRaw(
        requestParameters: CompetitiveTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveTeamfight>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling competitiveTeamfightGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveTeamfight> {
        const response = await this.competitiveTeamfightGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightListRaw(
        requestParameters: CompetitiveTeamfightListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveTeamfight>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.looser_team !== undefined) {
            queryParameters["looser_team"] = requestParameters.looser_team;
        }

        if (requestParameters.objective_killer_team !== undefined) {
            queryParameters["objective_killer_team"] =
                requestParameters.objective_killer_team;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.winner_team !== undefined) {
            queryParameters["winner_team"] = requestParameters.winner_team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveTeamfightListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveTeamfight>> {
        const response = await this.competitiveTeamfightListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightVariableDistributionRaw(
        requestParameters: CompetitiveTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling competitiveTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling competitiveTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling competitiveTeamfightVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling competitiveTeamfightVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.game !== undefined) {
            queryParameters["game"] = requestParameters.game;
        }

        if (requestParameters.looser_team !== undefined) {
            queryParameters["looser_team"] = requestParameters.looser_team;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.objective_killer_team !== undefined) {
            queryParameters["objective_killer_team"] =
                requestParameters.objective_killer_team;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        if (requestParameters.winner_team !== undefined) {
            queryParameters["winner_team"] = requestParameters.winner_team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.competitiveTeamfightVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
