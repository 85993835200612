import { FormControl, InputLabel, Stack, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { leagues } from "../../static_vars";
import TextField from "@mui/material/TextField";
import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function ServerSelect(props: { values: string[]; updateValue: any }) {
    const labels: any = {
        EUW1: "EUW",
        KR: "KR",
    };

    return (
        <FormControl sx={{ width: "170px", height: "55px", zIndex: 0 }}>
            <InputLabel>Servers</InputLabel>
            <Select
                multiple
                value={props.values as any}
                label="Servers"
                onChange={(event) => props.updateValue(event.target.value as any)}
                renderValue={(value: string) => (
                    <Stack direction={"row"} sx={{ justifyContent: "left", width: "100%" }} spacing={1}>
                        {props.values.map((role: string) => (
                            <Typography>{role}</Typography>
                        ))}
                    </Stack>
                )}
            >
                {Object.entries(labels).map(([code, label]) => (
                    <MenuItem value={code}>
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                alignItems: "center",
                            }}
                            spacing={2}
                        >
                            {labels[code]}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
