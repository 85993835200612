import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function TabPanel(props: { children: any; value: any; index: any }) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} style={{ width: "100%", height: "100%" }} {...other}>
            {value === index && <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>}
        </div>
    );
}
