import React, {useEffect, useState} from "react";
import RankedStatsVs, {
    DisplayPlayerStats,
    DisplayStatsH2H
} from "../../../components/datagrids/Competitive/RankedStatsGrid";
import FilterSet from "../../../components/selectors/Filterset";
import {Champion, targets, useReferentialContext} from "../../../Context";
import dayjs from "dayjs";
import {League, Team} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import {Stack} from "@mui/material";

export default function StatsVs() {
    const context = useReferentialContext();

    const [metrics, setMetrics] = useState<any[]>(context.selectedMetrics);
    const [paramObject, setParamObject] = useState<any>({});
    const [league, setLeague] = useState<League | null>(null);
    const [champions, setChampions] = useState<Champion[]>([]);
    const [split, setSplit] = useState<string | null>(null);
    const [phases, setPhases] = useState<string[]>([]);
    const [from, setFrom] = useState(dayjs(`${dayjs().year()}-01-01`))
    const [until, setUntil] = useState(dayjs(`${dayjs().year()+1}-01-01`))
    const [laneSwap, setLaneSwap] = useState(null)


    return (
        <WindowContainer spacing={1}>
                <FilterSet
                    paramObject={paramObject}
                    setParamObject={setParamObject}
                    target={targets.competitive}
                    metrics={metrics}
                    setMetrics={setMetrics}
                    date__gte={from}
                    setDateGte={setFrom}
                    date__lt={until}
                    setDateLt={setUntil}
                    league={league}
                    setLeague={setLeague}
                    champions={champions}
                    setChampions={setChampions}
                    split={split}
                    setSplit={setSplit}
                    phases={phases}
                    setPhases={setPhases}
                    laneSwap={laneSwap}
                    setLaneSwap={setLaneSwap}
                />
            {league && paramObject.league && paramObject.watermark ? <RankedStatsVs paramObject={paramObject} metrics={metrics} target={targets.competitive}/> : <></>}
        </WindowContainer>
    );
}

export function StatsH2H() {
    const context = useReferentialContext();

    const [metrics, setMetrics] = useState<any[]>(context.selectedMetrics);
    const [paramObject, setParamObject] = useState<any>({});
    const [paramObject1, setParamObject1] = useState<any>({});
    const [leagues, setLeagues] = useState<League[]>([]);
    const [champions, setChampions] = useState<Champion[]>([]);
    const [split, setSplit] = useState<string | null>(null);
    const [phases, setPhases] = useState<string[]>([]);
    const [from, setFrom] = useState(dayjs(`2010-01-01`))
    const [until, setUntil] = useState(dayjs(`${dayjs().year()+1}-01-01`))

    const [player1, setPlayer1] = useState(context.selectedPlayer);
    const [player2, setPlayer2] = useState(context.selectedPlayer);


    return (
        <WindowContainer spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                metrics={metrics}
                setMetrics={setMetrics}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                leagues={leagues}
                setLeagues={setLeagues}
                champions={champions}
                setChampions={setChampions}
                split={split}
                setSplit={setSplit}
                phases={phases}
                setPhases={setPhases}
            />
            <FilterSet paramObject={paramObject1} setParamObject={setParamObject1} target={targets.competitive} player={player1} setPlayer={setPlayer1}/>
            <FilterSet paramObject={paramObject1} setParamObject={setParamObject1} target={targets.competitive} player={player2} setPlayer={setPlayer2}/>
            {player1 && player2 && paramObject.additional_filters ? <DisplayStatsH2H paramObject={paramObject} metrics={metrics} player1={player1} player2={player2}/> : <></>}
        </WindowContainer>
    );
}

export function TeamStatsInSetOfGames() {
    const context = useReferentialContext();

    const [metrics, setMetrics] = useState<any[]>(context.selectedMetrics);
    const [paramObject, setParamObject] = useState<any>({});
    const [leagues, setLeagues] = useState<League[]>([]);
    const [split, setSplit] = useState<string | null>(null);
    const [phases, setPhases] = useState<string[]>([]);
    const [from, setFrom] = useState(dayjs(`${dayjs().year()}-01-01`))
    const [until, setUntil] = useState(dayjs(`${dayjs().year()+1}-01-01`))
    const [team, setTeam] = useState(context.selectedTeam);
    const [stats, setStats] = useState([] as any[]);

    const [selection, setSelection] = useState([] as any[]);

    useEffect(() => {
        if(team) {
            console.log(selection)
            context.farsightApi.competitiveGameSummaries.aggregate({
                team1: team.team_name,
                additional_filters: {
                    game_id__in: [selection.map(g => g.game_id).toString()],
                },
                ordering: ['participant_id'],
                groupby: ['player'],
                metrics:[metrics.map(m => m.code).toString()],
            }).then(r => setStats(r));
        }
    }, [team, selection, metrics])

    return (
        <WindowContainer spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                metrics={metrics}
                setMetrics={setMetrics}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                leagues={leagues}
                setLeagues={setLeagues}
                split={split}
                setSplit={setSplit}
                phases={phases}
                setPhases={setPhases}
                team={team}
                setTeam={setTeam}
            />
            {team && paramObject.leagues ? <Stack direction={'row'} sx={{width:'100%', height:'100%'}} spacing={2}>
                    <SpecificGameSelector team={team} updateSelection={setSelection} from={from} until={until} side_select={true} target={targets.competitive} single={false}/>
                    {stats.length > 0 ? <DisplayPlayerStats metrics={metrics} stats={stats}/> : <></>}
                </Stack>
                : <></>}


        </WindowContainer>
    );
}
