import React, {useEffect, useRef, useState} from "react";
import { ImageOverlay, MapContainer, Popup, TileLayer, Marker, Rectangle, Circle } from "react-leaflet";
import { Icon, CRS } from "leaflet";
import { Grid, Grow, Paper, Stack, Typography } from "@mui/material";
import "./Map.css";
import Box from "@mui/material/Box";
import WardPlacedMarker from "./map_icons/ward_placed";
import CampClearedMarker from "./map_icons/killed_camp";
import KillMarker from "./map_icons/kill";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3/lib";
import Button from "@mui/material/Button";
import {useThemeContext} from "../../themeContext";
import html2canvas from "html2canvas";

var summoners_rift = "https://static.wikia.nocookie.net/leagueoflegends/images/0/04/Summoner%27s_Rift_Minimap.png/revision/latest";

interface HeatmapDrawerParams {
    points: any[];
    radius? : number,
    max? : number,
    gradient? : any
}
function renderSwitch(row: any) {
    switch (row["type"]) {
        case "WARD_PLACED":
            return WardPlacedMarker(row);
        case "KILLED_ANCIENT":
            return CampClearedMarker(row);
        case "KILL":
            return KillMarker(row);
        default:
            return WardPlacedMarker(row);
    }
}

function renderRectangle(row: any) {
    switch (row["type"]) {
        case "KILLED_ANCIENT":
            return (
                <Rectangle
                    bounds={[
                        [row["position"][1] * 750 - 22, row["position"][0] * 750 - 22],
                        [row["position"][1] * 750 + 22, row["position"][0] * 750 + 22],
                    ]}
                    color={row["side"]}
                ></Rectangle>
            );
        case "KILL_HITMAP":
            return <Circle center={[row["position"][1] * 750, row["position"][0] * 750]} radius={10} color={row["side"]}></Circle>;
        default:
            return <></>;
    }
}

export default function HeatMapDrawer(props: HeatmapDrawerParams) {
    let map_url = "../map tiles/0/0/0.png";

    const gradient = { 0.2: 'yellow', 0.4: 'orange', 0.6: 'red', 1: 'blue' }
    const theme = useThemeContext();

    const chartRef = useRef(null);
    const handleCapture = () => {
      if (chartRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(chartRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: theme.mode === 'dark' ? '#000000' : '#F7F4EF',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    return (

        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Stack sx={{
                width: "910px",
                height: "840px",
                minWidth: "900px",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px solid ${theme.mode === 'dark' ? 'white' : 'black'}`
            }} direction={'column'} spacing={1}>
            <Box ref={chartRef}
                sx={{
                    width: "900px",
                    height: "800px",
                    minWidth: "900px",
                    overflow: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MapContainer
                    center={[375, 375]}
                    scrollWheelZoom={false}
                    zoom={0}
                    dragging={false}
                    minZoom={0}
                    maxZoom={0}
                    attributionControl={false}
                    zoomControl={false}
                    doubleClickZoom={false}
                    crs={CRS.Simple}
                    bounds={[
                        [-0, -0],
                        [750, 750],
                    ]}
                >
                    <ImageOverlay
                        url={map_url}
                        bounds={[
                            [-0, -0],
                            [750, 750],
                        ]}
                        className={"heatmap-overlay"}
                    />
                    <HeatmapLayer
                        points={props.points.map((row: any) => [row.position[0] * 750, row.position[1] * 750, "test"])}
                        gradient={props.gradient ? props.gradient : { 0.2: 'yellow', 0.4: 'orange', 0.6: 'red', 0.8: 'purple' }}
                        intensityExtractor={(m: any) => 1}
                        longitudeExtractor={(m: any) => m[0]}
                        latitudeExtractor={(m: any) => m[1]}
                        radius={props.radius ? props.radius : 30}
                        blur={30}
                        max={props.max ? props.max : props.points.length / 1000}
                        minOpacity={0.4}
                    />
                    {/*{rows.map(row => renderSwitch(row))}*/}
                </MapContainer>
            </Box>
                <Stack direction={'row'} sx={{align:'left', justify:'left', display:'flex', width:'90%'}}>
                    <Button variant={'outlined'} sx={{mb:1}} onClick={() => handleCapture()}>PNG</Button>
                </Stack>
            </Stack>
        </Grow>
    );
}
