import {targets, useReferentialContext} from "../../Context";
import WindowContainer from "../layout/WindowContainer";
import VariableDistribution from "./VariableDistribution";
import React, {useEffect, useState} from "react";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


export default function MetricDistribution(props: {paramObject: any, target: targets}){
    const [bucketSize, setBucketSize] = useState(10);
    const [label, setLabel] = useState('Performance');
    const [metric, setMetric] = useState('performance');
    const [statsOverview, setStatsOverview] = useState(undefined as any | undefined);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    useEffect(() => {
        setStatsOverview(undefined);
        const request_parameters = {
            ...props.paramObject,
            groupby: 'champion',
            metrics: ['games'],
            aggregates: `${metric}__avg,${metric}__std`,
            format: "json",
            ordering: 'champion'
        };
        api.aggregate(request_parameters).then(result => {
                setStatsOverview(result ? result[0] : undefined);
            }
        );

    }, [props.paramObject.watermark, metric])

    return <WindowContainer>
        <Select onChange={(event) => setMetric(event.target.value as any)}>
            <MenuItem value={'performance'}>Performance</MenuItem>
            <MenuItem value={'relativeKills'}>relativeKills</MenuItem>
            <MenuItem value={'relativeDeaths'}>relativeDeaths</MenuItem>
            <MenuItem value={'relativeDamages'}>relativeDamages</MenuItem>
        </Select>
        <VariableDistribution paramObject={props.paramObject}
                              target={props.target}
                              bucket_size={0.04}
                              min={0}
                              max={1}
                              metric={metric}
                              label={metric}
            gaussianParameters={statsOverview ? [
            {mean: statsOverview[`${metric}__avg`], std: statsOverview[`${metric}__std`], label: 'Normal'},

            // {mean: statsOverview['performance__avg__in_wins'], std: statsOverview['performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},
            // {mean: statsOverview['performance__avg__in_losses'], std: statsOverview['performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},
        ] : undefined}
            poissonParameters={statsOverview ? [
                {mean: statsOverview[`${metric}__avg`], label: 'Poisson', color:"rgb(100, 100, 255)"},

                // {mean: statsOverview['performance__avg__in_wins'], std: statsOverview['performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},
                // {mean: statsOverview['performance__avg__in_losses'], std: statsOverview['performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},
            ] : undefined}/>
    </WindowContainer>
}