import React, { useEffect, useState } from "react";
import { ImageOverlay, MapContainer, Popup, TileLayer, Marker, Rectangle, Circle } from "react-leaflet";
import { Icon, CRS } from "leaflet";
import { LatLngBounds } from "leaflet";
import { css, Grid, Grow, keyframes, Paper, Stack, styled, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { api_url } from "../../static_vars";
// import 'leaflet/dist/leaflet.css';
import "./Map.css";
import Box from "@mui/material/Box";
import WardPlacedMarker from "./map_icons/ward_placed";
import CampClearedMarker from "./map_icons/killed_camp";
import KillMarker from "./map_icons/kill";
import dayjs, { Dayjs } from "dayjs";
import FarsightApi from "../../ApiWrapper";
import AnimatedCircle, { getRandomDuration } from "./Markers";
import StyledPopup from "./StyledPopup";

var summoners_rift = "https://static.wikia.nocookie.net/leagueoflegends/images/0/04/Summoner%27s_Rift_Minimap.png/revision/latest";

interface MapDrawerParams {
    points: any[];
    positions?: any[]
}
function renderSwitch(row: any) {
    switch (row["type"]) {
        case "WARD_PLACED":
            return WardPlacedMarker(row);
        case "KILLED_ANCIENT":
            return CampClearedMarker(row);
        case "KILL":
            return KillMarker(row);
        case "ASSIST":
            return KillMarker(row);
        case "DEATH":
            return KillMarker(row);
        default:
            return WardPlacedMarker(row);
    }
}

function renderPosition(state: any){
    return <Marker
        position={[state.position[1] * 750, state.position[0] * 750]}
        icon={
            new Icon({
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${state.champion}.png`
            })
        }
        draggable={false}
    >
    </Marker>
}

function renderRectangle(row: any) {
    switch (row["type"]) {
        case "KILLED_ANCIENT":
            return (
                <Rectangle
                    bounds={[
                        [row["position"][1] * 750 - 22, row["position"][0] * 750 - 22],
                        [row["position"][1] * 750 + 22, row["position"][0] * 750 + 22],
                    ]}
                    color={row["side"]}
                ></Rectangle>
            );
        case "KILL_HITMAP":
            return <AnimatedCircle center={[row["position"][1] * 750, row["position"][0] * 750]} radius={10} color={row["side"] === "blue" ? "#5A4D5A" : "#2B1818"} duration={getRandomDuration(0.5, 2)} />;
        default:
            return <></>;
    }
}

export default function MapDrawer(props: MapDrawerParams) {
    console.log(props.points)
    let map_url = "../map tiles/0/0/0.png";
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "900px",
                    height: "800px",
                    minWidth: "900px",
                    overflow: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                component="fieldset"
            >
                <MapContainer
                    center={[375, 375]}
                    scrollWheelZoom={false}
                    zoom={0}
                    dragging={false}
                    minZoom={0}
                    maxZoom={0}
                    attributionControl={false}
                    zoomControl={false}
                    doubleClickZoom={false}
                    crs={CRS.Simple}
                    bounds={[
                        [-0, -0],
                        [750, 750],
                    ]}
                >
                    <ImageOverlay
                        url={map_url}
                        bounds={[
                            [-0, -0],
                            [750, 750],
                        ]}
                    />
                    {props.points.map((row) => renderSwitch(row))}
                    {props.points.map((row) => renderRectangle(row))}
                    {props.positions ? props.positions.map((pos) => renderPosition(pos)) : <></>}
                </MapContainer>
            </Box>
        </Grow>
    );
}
