import { Marker, Popup, Rectangle } from "react-leaflet";
import { Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { Icon } from "leaflet";

export default function CampClearedMarker(event: any) {
    return (
        <Marker
            position={[event["position"][1] * 750, event["position"][0] * 750]}
            icon={
                new Icon({
                    iconSize: [40, 40],
                    iconAnchor: [20, 20],
                    iconUrl: "../icons/" + event["monsterType"] + ".png",
                })
            }
            draggable={false}
        >
            <Popup autoPan={false}>
                <Stack direction={"row"}>
                    <Box component="img" sx={{ width: 40, height: 40 }} src={"https://ddragon.leagueoflegends.com/cdn/12.21.1/img/champion/" + event["player"] + ".png"} />
                    <Paper
                        variant="outlined"
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            flexDirection: "column",
                            display: "flex",
                            height: 40,
                        }}
                    >
                        <Typography
                            align="center"
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            {new Date(event["gameTime"] * 1000).toISOString().substring(14, 19)}
                        </Typography>
                    </Paper>
                </Stack>
            </Popup>
        </Marker>
    );
}
