import {Team} from "../../farsight-api";
import Box from "@mui/material/Box";
import React from "react";
import {Tooltip} from "@mui/material"


export default function TeamIcon(props: {team_name: string, sx? :any}){
    return <Tooltip title={props.team_name} followCursor={true}>
        <Box
                component={"img"}
                src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/teamsLogo/${props.team_name}.png`}
                sx={props.sx}
            />
        </Tooltip>
}