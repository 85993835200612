/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    ScrimsData,
    ScrimsDataAggregate,
} from "../models/index";

export interface ScrimsDataAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    drafting_sim_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    having?: object;
    index_on?: string;
    irelia_parser_version?: number;
    metrics?: Array<string>;
    patch?: string;
    session_1?: number;
    session_2?: number;
    team1?: string;
    team2?: string;
}

export interface ScrimsDataFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    drafting_sim_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    patch?: string;
    session_1?: number;
    session_2?: number;
    team1?: string;
    team2?: string;
}

export interface ScrimsDataGetRequest {
    id: string;
}

export interface ScrimsDataListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    drafting_sim_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    ordering?: Array<string>;
    patch?: string;
    session_1?: number;
    session_2?: number;
    team1?: string;
    team2?: string;
}

export interface ScrimsDataVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    drafting_sim_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    patch?: string;
    session_1?: number;
    session_2?: number;
    team1?: string;
    team2?: string;
}

/**
 *
 */
export class ScrimsDataApi extends runtime.BaseAPI {
    /**
     */
    async scrimsDataAggregateRaw(
        requestParameters: ScrimsDataAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimsDataAggregate>>> {
        if (
            requestParameters.groupby === null ||
            requestParameters.groupby === undefined
        ) {
            throw new runtime.RequiredError(
                "groupby",
                "Required parameter requestParameters.groupby was null or undefined when calling scrimsDataAggregate.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling scrimsDataAggregate.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.aggregates) {
            queryParameters["aggregates"] = requestParameters.aggregates;
        }

        if (requestParameters.drafting_sim_predicted_winner !== undefined) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters.drafting_sim_predicted_winner;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.grid_series_id !== undefined) {
            queryParameters["grid_series_id"] =
                requestParameters.grid_series_id;
        }

        if (requestParameters.groupby) {
            queryParameters["groupby"] = requestParameters.groupby;
        }

        if (requestParameters.having !== undefined) {
            queryParameters["having"] = requestParameters.having;
        }

        if (requestParameters.index_on !== undefined) {
            queryParameters["index_on"] = requestParameters.index_on;
        }

        if (requestParameters.irelia_parser_version !== undefined) {
            queryParameters["irelia_parser_version"] =
                requestParameters.irelia_parser_version;
        }

        if (requestParameters.metrics) {
            queryParameters["metrics"] = requestParameters.metrics;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.session_1 !== undefined) {
            queryParameters["session_1"] = requestParameters.session_1;
        }

        if (requestParameters.session_2 !== undefined) {
            queryParameters["session_2"] = requestParameters.session_2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimsData/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: ScrimsDataAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimsDataAggregate>> {
        const response = await this.scrimsDataAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimsDataFieldValuesRaw(
        requestParameters: ScrimsDataFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (
            requestParameters.field === null ||
            requestParameters.field === undefined
        ) {
            throw new runtime.RequiredError(
                "field",
                "Required parameter requestParameters.field was null or undefined when calling scrimsDataFieldValues.",
            );
        }

        if (
            requestParameters.ordering === null ||
            requestParameters.ordering === undefined
        ) {
            throw new runtime.RequiredError(
                "ordering",
                "Required parameter requestParameters.ordering was null or undefined when calling scrimsDataFieldValues.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.drafting_sim_predicted_winner !== undefined) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters.drafting_sim_predicted_winner;
        }

        if (requestParameters.field !== undefined) {
            queryParameters["field"] = requestParameters.field;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.grid_series_id !== undefined) {
            queryParameters["grid_series_id"] =
                requestParameters.grid_series_id;
        }

        if (requestParameters.irelia_parser_version !== undefined) {
            queryParameters["irelia_parser_version"] =
                requestParameters.irelia_parser_version;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.session_1 !== undefined) {
            queryParameters["session_1"] = requestParameters.session_1;
        }

        if (requestParameters.session_2 !== undefined) {
            queryParameters["session_2"] = requestParameters.session_2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimsData/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: ScrimsDataFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.scrimsDataFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimsDataGetRaw(
        requestParameters: ScrimsDataGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScrimsData>> {
        if (
            requestParameters.id === null ||
            requestParameters.id === undefined
        ) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling scrimsDataGet.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimsData/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters.id)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: ScrimsDataGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScrimsData> {
        const response = await this.scrimsDataGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimsDataListRaw(
        requestParameters: ScrimsDataListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimsData>>> {
        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.annotations) {
            queryParameters["annotations"] = requestParameters.annotations;
        }

        if (requestParameters.drafting_sim_predicted_winner !== undefined) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters.drafting_sim_predicted_winner;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.grid_series_id !== undefined) {
            queryParameters["grid_series_id"] =
                requestParameters.grid_series_id;
        }

        if (requestParameters.irelia_parser_version !== undefined) {
            queryParameters["irelia_parser_version"] =
                requestParameters.irelia_parser_version;
        }

        if (requestParameters.ordering) {
            queryParameters["ordering"] = requestParameters.ordering;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.session_1 !== undefined) {
            queryParameters["session_1"] = requestParameters.session_1;
        }

        if (requestParameters.session_2 !== undefined) {
            queryParameters["session_2"] = requestParameters.session_2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimsData/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: ScrimsDataListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimsData>> {
        const response = await this.scrimsDataListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimsDataVariableDistributionRaw(
        requestParameters: ScrimsDataVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (
            requestParameters.bucket_size === null ||
            requestParameters.bucket_size === undefined
        ) {
            throw new runtime.RequiredError(
                "bucket_size",
                "Required parameter requestParameters.bucket_size was null or undefined when calling scrimsDataVariableDistribution.",
            );
        }

        if (
            requestParameters.max === null ||
            requestParameters.max === undefined
        ) {
            throw new runtime.RequiredError(
                "max",
                "Required parameter requestParameters.max was null or undefined when calling scrimsDataVariableDistribution.",
            );
        }

        if (
            requestParameters.metric === null ||
            requestParameters.metric === undefined
        ) {
            throw new runtime.RequiredError(
                "metric",
                "Required parameter requestParameters.metric was null or undefined when calling scrimsDataVariableDistribution.",
            );
        }

        if (
            requestParameters.min === null ||
            requestParameters.min === undefined
        ) {
            throw new runtime.RequiredError(
                "min",
                "Required parameter requestParameters.min was null or undefined when calling scrimsDataVariableDistribution.",
            );
        }

        const queryParameters: any = {};

        if (requestParameters.additional_filters !== undefined) {
            queryParameters["additional_filters"] =
                requestParameters.additional_filters;
        }

        if (requestParameters.bucket_size !== undefined) {
            queryParameters["bucket_size"] = requestParameters.bucket_size;
        }

        if (requestParameters.drafting_sim_predicted_winner !== undefined) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters.drafting_sim_predicted_winner;
        }

        if (requestParameters.game_id !== undefined) {
            queryParameters["game_id"] = requestParameters.game_id;
        }

        if (requestParameters.grid_series_id !== undefined) {
            queryParameters["grid_series_id"] =
                requestParameters.grid_series_id;
        }

        if (requestParameters.irelia_parser_version !== undefined) {
            queryParameters["irelia_parser_version"] =
                requestParameters.irelia_parser_version;
        }

        if (requestParameters.max !== undefined) {
            queryParameters["max"] = requestParameters.max;
        }

        if (requestParameters.metric !== undefined) {
            queryParameters["metric"] = requestParameters.metric;
        }

        if (requestParameters.min !== undefined) {
            queryParameters["min"] = requestParameters.min;
        }

        if (requestParameters.patch !== undefined) {
            queryParameters["patch"] = requestParameters.patch;
        }

        if (requestParameters.session_1 !== undefined) {
            queryParameters["session_1"] = requestParameters.session_1;
        }

        if (requestParameters.session_2 !== undefined) {
            queryParameters["session_2"] = requestParameters.session_2;
        }

        if (requestParameters.team1 !== undefined) {
            queryParameters["team1"] = requestParameters.team1;
        }

        if (requestParameters.team2 !== undefined) {
            queryParameters["team2"] = requestParameters.team2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimsData/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: ScrimsDataVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.scrimsDataVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
