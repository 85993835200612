import React, { useContext, useState } from "react";
import global_context from "../../../../static_vars";
import { Grid, Grow, Stack } from "@mui/material";
import LeaguesSelect from "../../../../components/selectors/Competitive/LeagueSelect";
import PatchSelect from "../../../../components/selectors/PatchSelect";
// import ChampionSelect from "../../../../components/selectors/Shared/ChampionSelect";
import RoleSelect from "../../../../components/selectors/RoleSelect";
import { BlindOrCounter } from "../../../../components/selectors/YesNoSelector";
import ChampionMatchHistory from "../../../../components/datagrids/Competitive/ChampionMatchHistory";
import ChampionEarlyBuildSummary from "../../../../components/datagrids/shared/ChampionEarlyBuildSummary";
import { BotlaneMatchupsGrid } from "../../../../components/datagrids/Competitive/BotlaneMatchups";

export default function BotlaneMatchups() {
    const context = useContext(global_context);
    const { current_patch, current_leagues, current_champion, current_role } = context.state;
    const [state, setState] = useState({
        patch: current_patch.toString(),
        leagues: current_leagues.map((league: any) => league.league_name),
        bottom: "Jinx",
        support: "Thresh",
    });

    const handleLeagueChange = (event: any, newValue: string[]) => {
        setState({ ...state, leagues: newValue });
    };
    const handlePatchChange = (event: any) => {
        setState({ ...state, patch: event.target.value });
        context.state.current_patch = event.target.value;
    };
    const handleBottomChange = (event: any, newValue: string) => {
        setState({ ...state, bottom: newValue });
    };
    const handleSupportChange = (event: any, newValue: string) => {
        setState({ ...state, support: newValue });
    };

    return (
        <Grid
            container
            sx={{
                width: window.innerWidth - 200 + "px",
                ml: "100px",
                height: window.innerHeight - 170 + "px",
            }}
        >
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        mt: 5,
                        mb: 5,
                        height: "50px",
                        justifyContent: "left",
                        width: "100%",
                    }}
                    spacing={2}
                >
                    {/*<LeaguesSelect getValue={state.leagues} onChange={handleLeagueChange}/>*/}
                    {/*<PatchSelectWithNone getValue={state.patch} onChange={handlePatchChange} />*/}
                    {/*<ChampionSelect getValue={state.bottom} onChange={handleBottomChange} label={"ADC"}/>*/}
                    {/*<ChampionSelect getValue={state.support} onChange={handleSupportChange} label={"Support"}/>*/}
                </Stack>
            </Grow>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack direction="row" sx={{ height: "95%", width: "100%" }}>
                    <BotlaneMatchupsGrid patch={state.patch} leagues={state.leagues} bottom={state.bottom} support={state.support} target={"competitive"} />
                </Stack>
            </Grow>
        </Grid>
    );
}
