import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {useContext, useState} from "react";
import global_context from "../../static_vars";
import {targets} from "../../Context";

export default function YesOrNo(props: { value: string; onChange: any; label: string }) {
    return (
        <Box sx={{ width: 100 }}>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select value={props.value} label={props.label} onChange={props.onChange}>
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function BlindOrCounter(props: { value: boolean | null; onChange: any; label: string }) {
    const onChangeWrapper = (event: any) => {
        props.onChange(event.target.value !== undefined ? event.target.value : null);
    };

    return (
        <Box sx={{ width: "150px", zIndex: 0 }}>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select value={props.value} label={props.label} onChange={onChangeWrapper}>
                    <MenuItem value={undefined}>Any</MenuItem>
                    <MenuItem value={0}>Blind Pick</MenuItem>
                    <MenuItem value={1}>Counter Pick</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function LaneSwap(props: { value: boolean | null; onChange: any; label: string }) {
    const onChangeWrapper = (event: any) => {
        props.onChange(event.target.value !== undefined ? event.target.value : null);
    };

    return (
        <Box sx={{ width: "150px", zIndex: 0 }}>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select value={props.value} label={props.label} onChange={onChangeWrapper}>
                    <MenuItem value={undefined}>Any</MenuItem>
                    <MenuItem value={0}>Normal Game</MenuItem>
                    <MenuItem value={1}>Lane Swap Game</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function CompetitiveSoloQ(props: { value: targets; onChange: any }) {
    return (
        <Box sx={{ width: "150px", zIndex: 0 }}>
            <FormControl fullWidth>
                <InputLabel>{"Scope"}</InputLabel>
                <Select value={props.value} label={"Scope"} onChange={(e) => props.onChange(e.target.value)} renderValue={
                    (v) => v === targets.soloq ? 'SoloQ' : 'Competitive'
                }>
                    <MenuItem value={targets.competitive}>Competitive</MenuItem>
                    <MenuItem value={targets.soloq}>SoloQ</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function ScopeSelect(props: { value: string; onChange: any; label: string }) {
    const context = useContext(global_context);
    return (
        <Box sx={{ width: 200, zIndex: 0 }}>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select value={props.value} label={props.label} onChange={props.onChange}>
                    <MenuItem value={"team"}>{context.state.token.team}</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function SideSelect(props: { value: string | null; onChange: any }) {
    const [v, setV] = useState(props.value ? props.value : undefined)
    return (
        <Box sx={{ width: "150px", zIndex: 0 }}>
            <FormControl fullWidth>
                <InputLabel>{"Side"}</InputLabel>
                <Select value={v} label={"Side"}
                        onChange={(event) => {
                            setV(event.target.value);
                            props.onChange(event.target.value ? event.target.value : null)
                        }}>
                    <MenuItem value={undefined}>Any</MenuItem>
                    <MenuItem value={"Blue"}>Blue</MenuItem>
                    <MenuItem value={"Red"}>Red</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
