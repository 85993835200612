import {Box, Fade, Grow, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../../components/tabs_switch/tabs_panel";
import SynergiesMatchups from "../../components/visu_blocks/ChampionMatchups";
import ChampionItems from "../../components/visu_blocks/ChampionItems";
import ChampionRunesSimple from "../../components/visu_blocks/ChampionRunesSimple";
import ChampionRunesFull from "../../components/visu_blocks/ChampionRunesFull";
import React, {useContext, useEffect, useState} from "react";
import WindowContainer from "../../components/layout/WindowContainer";
import {Champion, targets, useReferentialContext} from "../../Context";
import { League } from "../../farsight-api";
import FilterSet from "../../components/selectors/Filterset";
import Stack from "@mui/material/Stack";
import GamesHistory from "../../components/datagrids/shared/GamesHistory";
import VariableDistribution from "../../components/Charts/VariableDistribution";
import RatingsExplanationChart from "../../components/Charts/RatingExplanationChart";
import StatsSummary from "../../components/visu_blocks/StatsSummary";
import global_context from "../../static_vars";
import MetricDistribution from "../../components/Charts/MetricDistribution";


export function ChampionsDetailsCompetitive() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState(context.selectedRole);
    const [picktype, setPicktype] = useState(null as null | boolean);

    console.log(context.selectedRole);
    console.log("WTF Bro?");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                updateContext={true}
                leagues={leagues}
                setLeagues={setLeagues}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
                picktype={picktype}
                setPicktype={setPicktype}
            />
            {champion && role && patches.length > 0 && leagues.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.competitive} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsSoloQ() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [servers, setServers] = useState(["EUW1", "KR"]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.soloq}
                updateContext={true}
                servers={servers}
                setServers={setServers}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 && servers.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.soloq} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsScrims() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.scrims}
                updateContext={true}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.scrims} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsChampionsQueue() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.championsq}
                updateContext={true}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.championsq} /> : <></>}
        </WindowContainer>
    );
}

function ChampionDetails(props: { paramObject: any; target: targets }) {
    const paramObject = props.paramObject;
    const [tab, setTab] = useState(0);

    return (
        <Stack direction={"column"} spacing={2} sx={{ height: "100%" }}>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Tabs value={tab} aria-label="basic tabs example" onChange={(event, newValue) => setTab(newValue)} sx={{ height: "50px" }}>
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Matchups" {...a11yProps(1)} />
                    <Tab label="Synergies" {...a11yProps(2)} />
                    <Tab label="Items" {...a11yProps(3)} />
                    <Tab label="Runes - simple" {...a11yProps(4)} />
                    <Tab label="Runes - full" {...a11yProps(5)} />
                    <Tab label="History" {...a11yProps(6)} />
                    <Tab label="Distributions" {...a11yProps(7)} />
                </Tabs>
            </Grow>

            <TabPanel value={tab} index={0}>
                {props.target !== targets.scrims ? <ChampionOverview paramObject={paramObject} target={props.target}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <SynergiesMatchups paramObject={paramObject} target={props.target} type={"Matchup"} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <SynergiesMatchups paramObject={paramObject} target={props.target} type={"Synergy"} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <ChampionItems paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <ChampionRunesSimple paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <ChampionRunesFull paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <GamesHistory paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={7}>
                <MetricDistribution paramObject={paramObject} target={props.target} />
            </TabPanel>
        </Stack>
    );
}

export default function ChampionOverview(props: {paramObject: any, target: targets}) {
    const champion = props.paramObject.champion;
    const [statsOverview, setStatsOverview] = useState(undefined as any);
    const [nb_skins, setNbSkins] = useState(undefined);
    const [ratings, setRatings] = useState([] as any[]);
    const [championRatings, setChampionRatings] = useState(undefined as any);

    const context = useContext(global_context);
    const { version } = context.state;

    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const ratings_api = useReferentialContext().farsightApi.computes;

    useEffect(() => {

        const request_parameters = {
            ...props.paramObject,
            groupby: 'champion',
            metrics: ["games", "winrate", "kda", "cspm", "gd15", "xpd15", "csd15", "dpm", "gpm",
                'relativeKills', 'relativeDeaths', 'relativeDamages', 'relativeDamageTaken', 'relativeGolds', 'relativeDmgHealed', 'relativeDmgMitigated', 'relativeWardsPlaced', 'relativeWardsKilled', 'relativeLevel', 'relativeCCtime'].toString(),
            aggregates: ['relative_performance__avg','performance__avg','relative_performance__std','performance__std',
                'performance__avg__in_wins', 'performance__std__in_wins',
                'performance__avg__in_losses', 'performance__std__in_losses',
                'relative_performance__avg__in_wins', 'relative_performance__std__in_wins',
                'relative_performance__avg__in_losses', 'relative_performance__std__in_losses',
            ].toString(),
            format: "json",
            ordering: 'champion'
        };
        api.aggregate(request_parameters).then(result => {
                setStatsOverview(result ? result[0] : undefined);
            }
        );
        const ratings_request_parameters = {
            ...props.paramObject,
            target: props.target
        }
        if(championRatings === undefined || props.paramObject.role !== championRatings.role){
            ratings_api.champion_ratings(ratings_request_parameters).then(result => {
                    setRatings(result);
                    // @ts-ignore
                    const cr = result.filter(r => r.champion === props.paramObject.champion)
                    setChampionRatings(cr.length > 0 ? cr[0] : undefined);
                }
            )
        }else{
            const cr = ratings.filter(r => r.champion === props.paramObject.champion)
            setChampionRatings(cr.length > 0 ? cr[0] : undefined);
        }



    }, [props.paramObject.watermark]);

    useEffect(() => {
        fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion/${champion}.json`)
            .then((data) => data.json())
            .then((data) => {
                const r_index = Math.round(Math.random() * (data.data[champion].skins.length - 1));
                setNbSkins(data.data[champion].skins[r_index].num);
            });
    }, [props.paramObject.champion]);

    // console.log(data);
    return champion ? (
        <Fade in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 2000 }}>
            <Stack
                direction={"row"}
                sx={{
                    outline: "1px solid white",
                    borderRadius: 3,
                    height: '90%',
                    justifyContent: "space-evenly",
                    width: "100%",
                    alignItems: "center",
                    padding: 1,
                }}
            >
                <Fade in={champion} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 2000 }}>
                    <Stack
                        direction={"column"}
                        sx={{
                            height: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 5500 }}>
                            <Typography
                                component={"fieldset"}
                                sx={{
                                    width: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                    borderRadius: 3,
                                }}
                            >{`${champion} Overview`}</Typography>
                        </Fade>
                        <Box component={"img"} src={`http://ddragon.leagueoflegends.com/cdn/img/champion/loading/${champion}_${nb_skins}.jpg`} style={{ height: "80%" }} />
                    </Stack>
                </Fade>
                {statsOverview ? (
                    <StatsSummary data={statsOverview} championRatings={championRatings}/>
                ) : (
                    <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 10000 }}>
                        <Box
                            sx={{
                                width: "230px",
                                textJustify: "center",
                                textAlign: "center",
                            }}
                        >{`No ${champion} games for selected preset`}</Box>
                    </Fade>
                )}
                <Fade in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 3000 }}>
                    <Stack
                        direction={"column"}
                        sx={{
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "25%",
                        }}
                    >
                        {/*<Box sx={{ height: "50%", width: "100%" }}>*/}
                        {/*    <ChampionEvolutionPerPatch {...props} year={2023} />*/}
                        {/*</Box>*/}
                        <Box sx={{ height: "50%", width: "100%" }}>
                            {championRatings ? <RatingsExplanationChart championRating={championRatings}/> : <></>}
                        </Box>
                    </Stack>
                </Fade>
                <Fade in={champion} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 4000 }}>
                    <Stack
                        direction={"column"}
                        sx={{
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-around",
                            width: "25%",
                        }}
                    >
                        <Box sx={{ height: "45%", width: "100%" }}>
                            {/*<VariableDistribution paramObject={props.paramObject} target={props.target} bucket_size={props.target !== "soloq" ? 30 : 20} min={-250} max={250} metric={"performance"} label={"Performance Distribution"}*/}
                            {/*                      gaussianParameters={statsOverview ? [*/}
                            {/*                          {mean: statsOverview['performance__avg'], std: statsOverview['performance__std'], label: 'Distribution'},*/}
                            {/*                          {mean: statsOverview['performance__avg__in_wins'], std: statsOverview['performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},*/}
                            {/*                          {mean: statsOverview['performance__avg__in_losses'], std: statsOverview['performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},*/}
                            {/*                      ] : undefined}/>*/}
                        </Box>
                        <Box sx={{ height: "45%", width: "100%" }}>
                            {/*<VariableDistribution*/}
                            {/*    paramObject={props.paramObject}*/}
                            {/*    target={props.target}*/}
                            {/*    bucket_size={props.target !== "soloq" ? 30 : 10}*/}
                            {/*    min={-150} max={150}*/}
                            {/*    metric={"relative_performance"} label={"Relative Performance Distribution"}*/}
                            {/*    gaussianParameters={statsOverview ? [*/}
                            {/*        {mean: statsOverview['relative_performance__avg'], std: statsOverview['relative_performance__std']},*/}
                            {/*        {mean: statsOverview['relative_performance__avg__in_wins'], std: statsOverview['relative_performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},*/}
                            {/*        {mean: statsOverview['relative_performance__avg__in_losses'], std: statsOverview['relative_performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},*/}
                            {/*    ] : undefined}/>*/}
                        </Box>
                    </Stack>
                </Fade>
            </Stack>
        </Fade>
    ) : <></>
}

