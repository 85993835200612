import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import FilterSet from "../../../../components/selectors/Filterset";
import {League} from "../../../../farsight-api";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import {targets, useReferentialContext} from "../../../../Context";
import OverviewDetailed from "../../../../components/selectors/OverviewDetailed";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {Simulate} from "react-dom/test-utils";
import click = Simulate.click;

export default function CompetitiveChampionsRatings() {
    const context = useReferentialContext();
    context.setCurrentTarget(targets.competitive);
    const [detailed, setDetailed] = useState(false);

    const [picktype, setPicktype] = useState(null as null | boolean);
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                updateContext={true}
                leagues={leagues}
                setLeagues={setLeagues}
                patches={patches}
                setPatches={setPatches}
                picktype={picktype}
                setPicktype={setPicktype}
            />
            <OverviewDetailed onChange={setDetailed} value={detailed} />
            {
                paramObject.additional_filters ? (!detailed ? <OverviewTierlists paramObject={paramObject} /> : <DetailedTierlist paramObject={paramObject} target={targets.competitive}/>) : <></>
            }
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const cols = ["champion", "games", "rating", "winrate", "cp_frequency"];

    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "TOP_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "JUNGLE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "MID_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "BOT_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}
function DetailedTierlist(props: { paramObject: any, target: targets }) {
    const api = useReferentialContext().farsightApi.computes;
    const aggregate_api=useReferentialContext().farsightApi.getSummariesApi(targets.competitive);
    const [role, setRole] = useState("BOT_LANE");
    const [metrics, setMetrics] = useState<any[]>(useReferentialContext().metricsReferential.filter(m => m.group ? ['Generic','Modelling'].includes(m.group) : false));
    const [paramObject, setParamObject] = useState<any>(props.paramObject as any);
    const [ratings, setRatings] = useState([] as any[]);
    const [bansStats, setBanStats] = useState([] as any[]);

    const [metricsData, setMetricsData] = useState([] as any[]);
    const [ratingsDone, setRatingsDone] = useState(false);
    const [metricsDone, setMetricsDone] = useState(false);
    const [bansDone, setBansDone] = useState(false);


    const [joinedData, setJoinedData] = useState([] as any[]);
    const [updateObject, setUpdateObject] = useState({} as any);
    const cols = ["champion","rating","performance_with","relative_performance_with","performance_against","relative_performance_against","bans"];

    useEffect(() => {
        setJoinedData([]);
        setUpdateObject(props.paramObject);

        api.champion_ratings({
            ...props.paramObject,
            role: role,
            target: props.target,
        }).then(r => setRatings(r)).then(() => setRatingsDone(true));

        aggregate_api.aggregate({
            ...props.paramObject,
            ...paramObject,
            additional_filters: {...props.paramObject.additional_filters, ...paramObject.additional_filters},
            index_on: 'champion',
            groupby: ['champion'],
            ordering: ['champion'],
            metrics: [metrics.map(m => m.code).toString()],
            role: role,
        }).then(r => setMetricsData(r)).then(() => setMetricsDone(true));

        aggregate_api.aggregate({
            ...props.paramObject,
            ...paramObject,
            additional_filters: {...props.paramObject.additional_filters, ...paramObject.additional_filters},
            index_on: 'ban',
            groupby: ['ban'],
            ordering: ['ban'],
            metrics: [['games'].toString()],
            role: undefined
        }).then(r => setBanStats(r)).then(() => setBansDone(true));

    }, [metrics, props.paramObject.watermark, role])

    async function request(){
        return joinedData;
    }

    useEffect(() => {
        if(metricsDone && ratingsDone && bansDone){
            const joined_data = [];
            for(let i in ratings){
                const rating_row = ratings[i];
                const additional_data = metricsData[rating_row.champion][0]
                const ban_row = bansStats[rating_row.champion];
                const joined_object = {...rating_row, ...additional_data, bans: ban_row ? ban_row[0].games : 0}
                joined_data.push(joined_object);
            }
            setMetricsDone(false);
            setRatingsDone(false);
            setBansDone(false);
            setJoinedData(joined_data);
            const update = {
                ...props.paramObject,
                ...paramObject,
                additional_filters: {...props.paramObject, ...paramObject}};
            update.watermark = JSON.stringify(update);
            setUpdateObject(update);
        }
    }, [ratingsDone, metricsDone, bansDone])

    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "center",
                width: "100%",
                display: "flex",
                alignItems: "top",
            }}
            spacing={3}
        >
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} updateContext={true} role={role} setRole={setRole} metrics={metrics} setMetrics={setMetrics} />
            <TableDisplayGrid
                display_all={false}
                request={() => request()}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={updateObject}
                metrics={metrics}
            />
        </Stack>
    );
}
