import React, { useContext, useEffect, useState } from "react";
import FarsightApi from "../../../ApiWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import global_context from "../../../static_vars";
import Button from "@mui/material/Button";
import {useReferentialContext} from "../../../Context";
import {OffSeasonShortlist} from "../../../farsight-api";

export default function OffseasonShortlistSelect(props: { value: any; onChange: any }) {
    const context = useReferentialContext();
    const [options, setOptions] = useState<OffSeasonShortlist[]>([]);
    const [addShortlist, setAddShortlist] = useState(false);

    useEffect(() => {
        context.farsightApi.offSeasonShortlist.list({legacy:false}).then(data => setOptions(data));
    }, []);

    return (
        <Stack
            direction={"row"}
            sx={{
                alignItems: "left",
                display: "flex",
            }}
            spacing={2}
        >
            <FormControl sx={{ width: "300px" }}>
                <Autocomplete
                    value={props.value}
                    onChange={props.onChange}
                    getOptionLabel={(option: any) => {
                        return option.name;
                    }}
                    options={options}
                    renderInput={(params) => <TextField {...params} label="Shortlist" />}
                    ListboxProps={{ className: "scrollable" }}
                    renderOption={(props, option, { selected }) => {
                        return <li {...props}>{option.name}</li>;
                    }}
                />
            </FormControl>
            <CreateShortlist
                open={addShortlist}
                close={() => {
                    setAddShortlist(false);
                    context.farsightApi.offSeasonShortlist.list({legacy:false}).then((data) => setOptions(data));
                }}
            />
            <Button onClick={() => setAddShortlist(true)} variant={"outlined"}>
                Create shortlist
            </Button>
        </Stack>
    );
}

function CreateShortlist(props: { open: boolean; close: any }) {
    const context = useReferentialContext();
    const [newShortlist, setNewShortlist] = useState({
        name: "New Shortlist",
        description: 'New Shortlist'
    } as any);

    function handleClose() {
        console.log(newShortlist);
        context.farsightApi.offSeasonShortlist.create({
            id: '0',
            off_season_shortlist: newShortlist
        }).then(new_shortlist => {
            props.close(newShortlist);
        });
    }

    return (
        <Stack direction={"column"}>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>{`Create new shortlist`}</DialogTitle>
                <DialogContent sx={{ height: "500px" }}>
                    <Stack direction={"column"} sx={{ padding: "20px", width: "500px", mt: 1 }} spacing={2}>
                        <TextField
                            label="Name"
                            variant="filled"
                            fullWidth
                            value={newShortlist.name}
                            onChange={(event: any) => {
                                setNewShortlist({
                                    ...newShortlist,
                                    name: event.target.value,
                                });
                            }}
                        />
                        <TextField
                            label="Description"
                            variant="filled"
                            fullWidth
                            multiline
                            rows={10}
                            value={newShortlist.description}
                            onChange={(event: any) => {
                                setNewShortlist({
                                    ...newShortlist,
                                    description: event.target.value,
                                });
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close}>Cancel</Button>
                    <Button onClick={handleClose}>Create shortlist</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
